import React from "react";
import { useSelector } from "react-redux";
import { httpsCallable } from "../../../../common/services/firebase";
import Button from "../../../components/button";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const dateOptions = { year: "numeric", month: "long", day: "numeric" };
const cancelSubscription = httpsCallable("cancelSubscriptionFunc");

const CancelSubscriptionCard = () => {
  const user = useSelector((state) => state.user);
  const subscription = useSelector((state) => state.stripe.subscription);

  if (subscription?.cancel_at_period_end && subscription.current_period_end)
    return (
      <InfoCard
        containerClassname={styles.container}
        content={
          <div className={styles.contentContainer}>
            <div>
              <Text variant="description">Subscription expires in:</Text>
              <div className={styles.dateContainer}>
                <Text>
                  {new Date(
                    Number(`${subscription.current_period_end}000`)
                  ).toLocaleDateString("en-US", dateOptions)}
                </Text>
              </div>
            </div>
            {/* <Button variant="outlineTertiary" onClick={onRenew}>Renew</Button> */}
          </div>
        }
      />
    );

  const onCancel = async () => {
    try {
      if (
        window.confirm("Are you sure you want to cancel your subscription?")
      ) {
        await cancelSubscription({
          userId: user.id,
          subscriptionId: subscription.id,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <InfoCard
      containerClassname={styles.container}
      content={
        <div className={styles.contentContainer}>
          <div>
            <Text variant="description">Next billing date:</Text>
            <div className={styles.dateContainer}>
              <Text>
                {new Date(
                  Number(`${subscription.current_period_end}000`)
                ).toLocaleDateString("en-US", dateOptions)}
              </Text>
            </div>
          </div>
          <Button variant="outlineTertiary" onClick={onCancel}>
            Cancel Subscription
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(CancelSubscriptionCard);
