import React from "react";
import "react-dropdown/style.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import AdminPanel from "./Admin/App";
import Dashboard from "./Dashboard/App";
import Loader from "./Dashboard/components/loader";
import Database from "./Database/EntryPoint";
import LandingPage from "./LandingPage/App";
import PrivateAdminRoute from "./common/components/private-admin-route";
import PrivateRoute from "./common/components/private-route";
import store, { persistor } from "./common/redux/store";
import { ADMIN_ROUTES, BASE_ROUTES, DASHBOARD_ROUTES } from "./common/route";

// !!! ATTENTION: THE ORDER OF THE ROUTES IN THIS COMPONENT IS IMPORTANT (DO NOT CHANGE IT) !!!
const App = () => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route path={BASE_ROUTES.demo} exact>
                <Database isDemo />
              </Route>
              <Route path={ADMIN_ROUTES.base}>
                <PrivateAdminRoute>
                  <AdminPanel />
                </PrivateAdminRoute>
              </Route>
              <Route path={DASHBOARD_ROUTES.base}>
                <Loader>
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                </Loader>
              </Route>
              <Route path={BASE_ROUTES.base}>
                <LandingPage />
              </Route>
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default React.memo(App);
