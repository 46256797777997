import { POSITION } from ".";

export const HIGHLIGHT_QUAILITY = "highlightQuality";
export const POSITION_SPECIFIC_SKILLS = "positionSpecificSkills";
export const ENGAGEMENT = "engagement";
export const ATHLETICISM_SHOWN = "athleticismShown";

export const FEEDBACK_OVERALL_KEYS = [HIGHLIGHT_QUAILITY, POSITION_SPECIFIC_SKILLS, ENGAGEMENT, ATHLETICISM_SHOWN]

export const FEEDBACK_MAP = {
  [POSITION.quaterback.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'asf34ghasdgbg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvnsfg5sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmesfsfdgjnmgb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhsfgjmk675',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfghws4rymj3w',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn5sfdgm w2ik57',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcv12sfdgn232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva354hgedsfgnm2fs89',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0asfdghnsfgn2453456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vma98xs9h89sfgn23asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansd899bhavdbnm 389d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8v90hbj8mdfvbhm39scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfb0sdrt22n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ]
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3nf24rtjnwsfg92n',
          title: 'Does QB adequately display the ability to throw short medium and long balls?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Demonstrates ability to throw long, short, and midrange.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately demonstrates ability to throw long, short, and midrange.',
          rates: ["None", null, "Some", null, "High ability in each"],
          active: true,
        },
        {
          key: 'asdvllm1akdsvo12r4etnj0',
          title: 'Does QB Show Arm Strength in Medium and Long balls',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Arm strength',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Arm strength',
          rates: ["None", null, "Some", null, "High"],
          active: true,
        },
        {
          key: '1enmvmkosn0iscxvfn2120e',
          title: 'Does QB show accuracy overall',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Demonstrates accuracy',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrates accuracy',
          rates: ["None", null, "Some", null, "High"],
          active: true,
        },
        {
          key: 'mIVNasdfb20n3g2n2oeifn92',
          title: 'Does QB show plays where he throws receivers open?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Shows ability to throw receivers open',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to throw receivers open',
          rates: ["None", null, "A couple", null, "A lot"],
          active: true,
        },
        {
          key: 'nKLN91sdgn23n82nev92',
          title: 'Does QB show reads or decision making?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Demonstrates decision making',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrates decision making',
          rates: ["None", null, "Some", null, "A lot"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nndcvbn229j02',
          title: 'Does QB show any throw on the run?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to include more throw on the run plays.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrated preference to throw on the run.',
          rates: ["None", null, "A couple", null, "A lot"],
          active: true,
        },
        {
          key: 'al995nn1jrvn2sdfb284n',
          title: 'Does QB show at least one scrambling/QB draw?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Abilities and athleticism on the ground.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Abilities and athleticism on the ground.',
          rates: ["None", null, "One", null, "Multiple"],
          active: true,
        },
        {
          key: 'cm0Nadsfbn2VON10n02nv9',
          title: 'Overall Athleticism shown excluding above',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Must show more athleticism',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", null, "Average", null, "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03in0so1nvdbm2',
          title: 'Does QB Show arm strength in first 3 plays',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more arm strength initially',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows arm strength early',
          rates: ["No", null, "Some", null, "Yes"],
          active: true,
        },
        {
          key: 'vn200finscvv11nf9n',
          title: 'Does QB show accuracy in first 5 plays',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show better accuracy in first 5 plays ',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great accuracy early',
          rates: ["No", null, "Some", null, "Yes"],
          active: true,
        },
        {
          key: '1949bnnsocndsvbm1v20',
          title: 'Does QB show high energy and or leadership?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Should try to show more energy and leadership on the field',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high energy and leadership on the field',
          rates: ["No", null, "One play", null, "Multiple"],
          active: true,
        },
      ]
    },
  ],
  [POSITION.receiverTightend.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'aasdfn12sf34ghg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvndfvhm25sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmesfgjm57mdsfhb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhksfgnm235ym675',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfghj3sdvbn24tw',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn5ikscdbn2w457',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcv1fdvf232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva354hgedfs8wefgm329',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0asdgfh3fdghn3456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vma98xs9h89ghmsft2asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansfdgn2354ymha89d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8dvhbhm356v90hbj89scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfbdfm2gm3w23502n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ],
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3dfgmewf2nf92n',
          title: 'Does R adequately show ability in long, short, and medium, catches?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Demonstrates ability to run long, short, and midrange routes.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately demonstrates ability to run long, short, and midrange routes.',
          rates: ["None", "", "Some", "", "High ability in each"],
          active: true,
        },
        {
          key: 'adfgmndfhgm2sdvllm1akdsvo10',
          title: 'Does R show zone coverage adjustments?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Shows ability to find holes in zone coverage',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to recognize and find holes is zone coverage',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
        {
          key: '1enmvmkosn0i20efdbmrey87e',
          title: 'Does R show man coverage proficiency and moves off the line?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability to make space off the line and beat man coverage',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to make space off the line and beat man coverage',
          rates: ["None", "", "Some", "", "A Lot"],
          active: true,
        },
        {
          key: 'mIVN0n3g2n2oeidfgw35fn92',
          title: 'Does R show proficiency in open field blocking?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough open field blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately demonstrate open field blocking.',
          rates: ["None", "", "A couple", "", "A lot"],
          active: true,
        },
        {
          key: 'nKLN91n82b2357nev92',
          title: 'Does R show Speed and athleticism?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Must show more straight line speed and more polished cuts.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows a high amount of speed and athleticism with good cuts and good moves off the line.',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nn29beryjb256j02',
          title: 'Does R show proficiency in RAC?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Receiver does not show enough RAC',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Receiver shows a high level of RAC abilities.',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
        {
          key: 'al995nn1jrvn2bdrf44c84n',
          title: 'Does R win contested catches?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Receiver does not show ability to win contested catches.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Reciever wins contested catches',
          rates: ["None", null, "A couple", "", "Multiple"],
          active: true,
        },
        {
          key: 'cm0NVON1cw46cjvw46bvu0n02nv9',
          title: 'Overall Athleticism shown excluding above',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more athletic moves in route running and RAC',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", "", "Average", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03in0sbw456nuwno1n',
          title: 'Does R show speed and athleticism in all of first 3 plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to start faster, show more speed and athleticism in first 3 plays.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Starts fast, and grabs attention',
          rates: ["No", "", "Some", "", "Yes"],
          active: true,
        },
        {
          key: 'vn200fin1nb356x2356f9n',
          title: 'Does R show big plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to include more big plays, contested catches, and touchdowns.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Includes lots of big plays.',
          rates: ["No", null, "A couple", "", "Multiple"],
          active: true,
        },
        {
          key: '1949bnnsov356v56cnv20',
          title: 'Does R show high Energy Overall?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to include more energy',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high energy on the field.',
          rates: ["No", null, "Some", "", "Yes"],
          active: true,
        },
      ]
    },
  ],
  [POSITION.runningBack.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'asf3v356vswvw645bvu34ghg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvnftghfjv65e5sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmesve6jv657jne4bfgb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhk6ev56j5bw4b46j75',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfghasdngu9wb45byw4234j3w',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn5wb45ybw455byik57',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcv1etyjbe6cejw56232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva354hzewctqw35yc45qgedfs89',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0asfdgdfyhjxcw45hn3456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vm245yb45a98xs9h89asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansd899bhasdrthc245h89d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8vwrtcvhwb90hbj89scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfyw45by54chw45ch54wb02n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ]
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3nfytjer56xh392n',
          title: 'Does RB show agility and elusiveness: Jukes, Spins, Cuts?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Demonstrates agility and elusiveness',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately demonstrates speed and elusiveness',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
        {
          key: 'asdvllm3c5463c56h1akdsvo10',
          title: 'Does RB show preeciency in block reads and fiding holes?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability to read blockers and find holes',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to read blocks and find holes',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: '1en356cvh356xh356sn0i20e',
          title: 'Does RB show proficiency in pass protection?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability to read blockers and find holes',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to read blockers and find holes',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: 'mI356dh356vg2n2oeifn92',
          title: 'Does RB show proficiency in a variety of play types, Inside and Outside Zone, Pitches, Catches?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough variety in chosen run plays.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows variety in chosen plays',
          rates: ["None", "", "Some variety", "", "High diversity of play choice"],
          active: true,
        },
        {
          key: 'nKLN91n8536h335c62nev92',
          title: 'Does RB show any catches',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Should include some catches if possible.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows ability to make open field catches.',
          rates: ["None", null, "One", "", "Multiple"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nn356vh356h29j02',
          title: 'Does RB show speed and acceleration/Burst',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Running Back must include more plays showing speed and acceleration',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Running Back shows a high level of speed and acceleration',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
        {
          key: 'al995nn1jrwretuev2bvn284n',
          title: 'Does RB show variety in Agility and elusiveness: Jukes, Spins, Cuts?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Running Back should include more plays showing agility and elusive moves ',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Running Back shows a variety of effective and athletic moves.',
          rates: ["1 move", null, "3 moves", "", "5 moves"],
          active: true,
        },
        {
          key: 'cm0NVON10245ybw45ygn02nv9',
          title: 'Overall Athleticism shown Vs others',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more athletic open field moves.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", "", "Average", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03wy45yh45ygin0so1n',
          title: 'Does RB show speed and acceleration in all of first 3 plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to start faster, show more speed and athleticism in first 3 plays.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Starts fast, and grabs attention using speed',
          rates: ["No", "", "Some", "", "Yes"],
          active: true,
        },
        {
          key: 'vn200fin1w45uj7i5evwnf9n',
          title: 'Does RB show Agility and elusiveness: Jukes, Spins, Cuts. In all of first 3 plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Should show your best move in the first 3 plays, make somebody miss.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: '',
          rates: ["No", "", "Some", "", "Yes"],
          active: true,
        },
        {
          key: '1949bnnsobk68nko86cnv20',
          title: 'Does RB show high energy and or big plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to include more big plays and high energy',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high energy and includes big plays',
          rates: ["No", null, "3 playes", "", "Multiple"],
          active: true,
        },
      ]
    },
  ],
  [POSITION.offensiveLineman.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'asf34gnk587jc35hg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvn234t34b5sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmesfbw64un64wgb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhk4n6uw465hxw46675',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfghw4bu656hxcwj3w',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn54w56vuj356hx356jik57',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcv3c6h35x6h3561232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva354hge32v6y35z6y35dfs89',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0asf3456x356ch3566dghn3456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vma98xs3x65h3c565hc9h89asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansd893c567uj3c5xhc357buu9bha89d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8v90hb35cd3563ubb35j89scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfbcd356jh357u5ch35602n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ]
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3nuv356xh3h56ub35f92n',
          title: 'Does OL show any plays that could be holding?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Split plays evenly between run and pass',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately splits plays between run and pass',
          rates: ["Yes", "", "A couple", "", "None"],
          active: true,
        },
        {
          key: 'asdvllm1akdx2365ub356hxd36dsvo10',
          title: 'Does OL show proficiency in Run Blocking?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability in run blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great proficiency in run blocking',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: '1enm3654ub356cyvmkosn0i20e',
          title: 'Does OL show Proficiency in Pass Blocking?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability in pass blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great proficiency in pass protection',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: 'mIVN0n3g356vub356chy3562n2oeifn92',
          title: 'Does OL show any open field blocks?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough open field blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows proficiency in open field blocking',
          rates: ["None", "", "A couple", "", "A lot"],
          active: true,
        },
        {
          key: 'nKLN91n82bu35c356u356cu356nev92',
          title: 'Does OL show any pulling?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough open field blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately shows ability to pull',
          rates: ["None", "", "A couple", "", "A lot"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nn29w54sedfgvsetryj02',
          title: 'Does  OL show at least one extended scrambling play?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show proficiency in an extended scramble situation',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows proficiency in an extended scramble situation',
          rates: ["None", null, "One", "", "Multiple"],
          active: true,
        },
        {
          key: 'al995nn1jrser5btys45n284n',
          title: 'Does OL show any second level blocking?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more second level blocking',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrate second level blocking ability',
          rates: ["None", "", "A couple", "", "A lot"],
          active: true,
        },
        {
          key: 'cm0NVON1bs435yb455vyw40n02nv9',
          title: 'Overall Athleticism shown Vs others',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more athletic open field moves.',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", "", "Average", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03insb45ybvs455yvfs450so1n',
          title: 'Does OL show any finished blocks?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more finished blocks, especially early',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows plenty of finished blocks',
          rates: ["No", "", "3", "", "5"],
          active: true,
        },
        {
          key: 'vn200fin1sb5y4sb4y5ys455ynf9n',
          title: 'Does OL show high energy?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more energy before, during, and after the play',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Needs to show more finished blocks, especially early',
          rates: ["No", "", "Some", "", "Yes"],
          active: true,
        },
      ]
    },
  ],
  [POSITION.defensiveLineman.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'asf34mt87v67kghg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvw435vycyw45sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmesfw45nw45cgb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhw45uertscretyhk675',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfghne57i6etyc2j3w',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn5c213tct2456yyvwr5buik57',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcv566uwe45xy356uh1232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva354hge78o578owvdfs89',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0as2a3vtq34byfdghn3456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vma98xnw45cw45yubs9h89asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansd899bhw4nb56uw4xc5hwva89d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8v90hbj8vwrtunw49scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfbbvw456x46jh02n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ]
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3nw45cwh465ub56ef92n',
          title: 'Does DL show explosiveness?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more explosiveness off the line',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great explosiveness off the line',
          rates: ["No", "", "Most plays", "", "Every play"],
          active: true,
        },
        {
          key: 'asdvllm1wvdrtfghfe56nbakdsvo10',
          title: 'Does DL show good Hand technique?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough hand technique',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great hand technique',
          rates: ["No", "", "Most plays", "", "Every play"],
          active: true,
        },
        {
          key: '1enmne56cve56jnbei20e',
          title: 'Does DL show multiple gap control/penetration plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability in gap control and penetration',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great gap control and penetration',
          rates: ["None", "", "3", "", "5 plus"],
          active: true,
        },
        {
          key: 'mIVN0n3g2betydcfgjhnben2oeifn92',
          title: 'Does DL show multiple pass rush moves?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough variety in pass rush moves',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows variety and proficiency in pass rush moves',
          rates: ["1", "", "3", "", "5 plus"],
          active: true,
        },
        {
          key: 'nKLN91n82e5vyjteryyjr67ernev92',
          title: 'Does DL show play recognition?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'DL needs to show more play recognition if possible',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Adequately shows play recognition',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nn2nmdr56cvs45hb9j02',
          title: 'Does DL show pursuit & not giving up on a play?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show proficiency in an extended scramble situation',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows proficiency in an extended scramble situation',
          rates: ["No", "", "A few plays", "", "Every play"],
          active: true,
        },
        {
          key: 'al995nn1jbsr6unsdr5csrvn284n',
          title: 'Does DL show ability to beat double teams ',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more double team defeats',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrate ability to beat double teams',
          rates: ["No", "", "2", "", "Multiple"],
          active: true,
        },
        {
          key: 'cm0NVON10nnsu45c46j5h02nv9',
          title: 'Overall Athleticism shown Vs others',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more athleticism in open field',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", "", "Average", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03in56eche56jhe50so1n',
          title: 'Does DL show any sacks?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more sacks or pass pressure if possible',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows plenty of sacks and pass pressure ',
          rates: ["No", "", "A couple", null, "3 plus"],
          active: true,
        },
        {
          key: 'asdfgw45ycwqose56bkje5767c vndgiu',
          title: 'Does DL show any tackle for a loss?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more tackle for a loss and control on the LOS',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great control over the LOS and includes many tackles for a loss',
          rates: ["No", "", "A couple", null, "3 plus"],
          active: true,
        },
        {
          key: 'vn200be5w6jc6jnf9n',
          title: 'Does DL play through the whistle with tenacity?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to more tenacity by playing through the whistle',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Plays through the whistle with great tenacity and effort',
          rates: ["No", null, "One play", "", "Yes"],
          active: true,
        },
      ]
    },
  ],
  [POSITION.defensiveBack.key]: [
    {
      key: HIGHLIGHT_QUAILITY,
      title: 'Highlight Quality',
      active: true,
      questions: [
        {
          key: 'asf34xyeneybzghg',
          title: 'Name: Last, First',
          equalTo: 1,
          equalToNotes: 'Include name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'gfsvnene4snz45sm',
          title: 'Height, Weight',
          equalTo: 1,
          equalToNotes: 'Include Height and weight on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'hmese46s44e56s5fgb3',
          title: 'Phone, Email',
          equalTo: 1,
          equalToNotes: 'Include phone and email on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dgfhs5m7s5v sk675',
          title: 'Cumulative GPA, ACT and/or SAT',
          equalTo: 1,
          equalToNotes: 'Include GPA and standardized test scores on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'dfg4576s45v7ws45hj3w',
          title: 'Head Coach Name',
          equalTo: 1,
          equalToNotes: 'Include head coach name on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgn5sv4576ws45wik57',
          title: 'Head Coach Contact',
          equalTo: 1,
          equalToNotes: 'Include Head coach contact on pre-film slides',
          rates: ["Excluded", null, null, null, "Included"],
          active: true,
        },
        {
          key: 'sfgnxcsv457ws4qv1232',
          title: 'Is the above information presented on less than 3 slides',
          equalTo: 1,
          equalToNotes: 'Condence player information to 2 slides of possible',
          rates: ["2 slides", null, "3 slides", null, "4 slides"],
          active: true,
        },
        {
          key: 'gva3541aeba34hgedfs89',
          title: 'Is total film length less than 4 minutes?',
          equalTo: 1,
          equalToNotes: 'Try to make your film less than three and a half minutes. Try to make a commercial for yourself, not a movie',
          rates: ["No", null, null, null, "Yes"],
          active: true,
        },
        {
          key: 'pap0asfdg12352whn3456j',
          title: 'Are all plays from game Film?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Try to only include game film',
          rates: ["Mostly practice film", "", null, null, "All game film"],
          active: true,
        },
        {
          key: 'vma98xs9hqj346qv3489asd',
          title: 'Does each play clearly show the Athlete?',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some of the included plays do not clearly show the athlete',
          rates: ["Cannot see athlete", "", "", null, "Visible at all times"],
          active: true,
        },
        {
          key: 'ansd899baw4n6aw4cha89d',
          title: 'Does Editing Distract from Athlete?',
          equalTo: 1,
          equalToNotes: 'Editing significantly distracts from athlete',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some editing distracts from athlete',
          rates: ["Significantly", "", "Some", null, "Does not distract"],
          active: true,
        },
        {
          key: 'bsc8v90aw345q324hbj89scd',
          title: 'Should the order of the plays be changed in any way?',
          equalTo: 1,
          equalToNotes: 'For effective representation reordering film is required.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some reordering should be considered',
          rates: ["Confusing", "", "Decent", null, "Well laid out"],
          active: true,
        },
        {
          key: 'asfbaw342q6nasefybsd02n',
          title: 'Are there any plays which should be removed?',
          equalTo: 1,
          equalToNotes: 'Many plays should be removed because they do not show the best film possible for the athlete.',
          equalToOrLess: 3,
          equalToOrLessNotes: 'Some plays should be removed because they do not show the best film possible for the athlete',
          rates: ["Many", "", "Some", null, "None"],
          active: true,
        },
      ]
    },
    {
      key: POSITION_SPECIFIC_SKILLS,
      title: 'Position Specific Skills Demonstrated',
      active: true,
      questions: [
        {
          key: 'afm3nghbserv54yqw3f92n',
          title: 'Does DB show speed and agility in all plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more speed and agility',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great speed and agility',
          rates: ["No", "", "Most plays", "", "Every play"],
          active: true,
        },
        {
          key: 'asdvllm1avsae34v52qkdsvo10',
          title: 'Does DB show proficiency in man to man coverage?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough man coverage',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great proficiency in man coverage',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: '1enmvmkosn0a1235va4ya3i20e',
          title: 'Does DB show proficiency in zone coverage?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not adequately show ability in zone coverage',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great ability in zone coverage',
          rates: ["None", "", "Some", "", "High"],
          active: true,
        },
        {
          key: 'mIVNa2v6a344v6y0n3g2n2oeifn92',
          title: 'Does DB show ball skills once the ball is in the air?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show enough change of direction and ball skill',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great ball skills',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
        {
          key: 'nKLN91n8va34ya53vq352nev92',
          title: 'Does DB show Route recognition?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Should show more route recognition if possible',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows great proficiency in route recognition',
          rates: ["None", "", "Some", "", "A lot"],
          active: true,
        },
      ]
    },
    {
      key: ATHLETICISM_SHOWN,
      title: 'Athleticism Shown',
      active: true,
      questions: [
        {
          key: 'al299b9nq346ab3n29j02',
          title: 'Does DB show Versatility in multiple positions?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Does not show great versatility in multiple positions and coverages',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows versatility in multiple positions and coverages',
          rates: [null, "None", null, null, "2 plus"],
          active: true,
        },
        {
          key: 'al995nn1jrvav4ya35bya34n284n',
          title: 'Does DB show open field tackling?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more open field tackling',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Demonstrate great open field tackling technique ',
          rates: ["None", null, "One", "", "Multiple"],
          active: true,
        },
        {
          key: 'cm0NVON10a34ta34b34t6n02nv9',
          title: 'Overall Athleticism shown',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to show more athleticism in open field',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows high athleticism',
          rates: ["Low", "", "Average", "", "High"],
          active: true,
        },
      ]
    },
    {
      key: ENGAGEMENT,
      title: 'Engagement',
      active: true,
      questions: [
        {
          key: 'MO03a34tb344ybin0so1n',
          title: 'Does DB show speed and agility in all of first 5 plays?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more speed and agility in initial plays',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows plenty of speed and agility early',
          rates: ["No", "", "Some", "", "Yes"],
          active: true,
        },
        {
          key: 'asdfgmaiua34xca34tba3osndgiu',
          title: 'Does DB show big plays/Interceptions?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Needs to show more big plays ',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Shows plenty of big plays ',
          rates: ["No", "", "A couple", null, "3 plus"],
          active: true,
        },
        {
          key: 'vn200fin1a32b45a3545yvnf9n',
          title: 'Does DB play through the whistle with tenacity, any plays off?',
          equalToOrLess: 2,
          equalToOrLessNotes: 'Need to more tenacity by playing through the whistle',
          equalToOrGreater: 4,
          equalToOrGreaterNotes: 'Plays through the whistle with great tenacity and effort',
          rates: ["No", null, "Most plays", "", "Every play"],
          active: true,
        },
      ]
    },
  ],
}