import React from "react";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const ExpireCard = ({ date }) => {
  if (!date) return null;
  return (

    <InfoCard
      containerClassname={styles.coachPlanExpireDateContainer}
      content={
        <div>
          <Text variant="description">Subscription expires in:</Text>
          <div className={styles.coachPlanExpireDate}>
            <Text>{new Date(date).toLocaleDateString("en-US", dateOptions)}</Text>
          </div>
        </div>
      }
    />
  )
};

export default React.memo(ExpireCard);
