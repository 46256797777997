import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { COACHES_PLAN } from "../../../../common/constants";
import { httpsCallable } from "../../../../common/services/firebase";
import Text from "../../../components/text";
import CheckoutModal from "../checkout-modal";

const purchaseCoachesPlan = httpsCallable("purchaseCoachesPlanFunc");

const ConfirmCoachPlanModal = ({
  defaultPaymentMethod,
  onClose,
}) => {
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedCoachPlan, setSelectedCoachPlan] = useState(COACHES_PLAN.prices[1].id);

  const onConfirm = async () => {
    if (!selectedCoachPlan) return alert("Select a plan first");
    setLoading(true);
    try {
      const response = await purchaseCoachesPlan({ userId: user.id, priceId: selectedCoachPlan });
      console.log('response', response)
      toast.success("Coach plan purchased");
    } catch (error) {
      if (error.message !== "INTERNAL") {
        toast.error(error.message);
      }
      console.log('error', error);
    }
    setLoading(false);
    onClose();
  }

  return (
    <CheckoutModal
      title="Purchase Coach Plan"
      creditCard={defaultPaymentMethod?.card}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
      hideCoupon
    >
      <Text variant="h3">Would you like to confirm the purchase of the Coach Plan?</Text>
      {/* <div>
        <Text variant="description">You currently have {totalReviewsLeft} review{totalReviewsLeft === 1 ? "" : "s"} left</Text>
      </div> */}
    </CheckoutModal>
  )
};

export default React.memo(ConfirmCoachPlanModal);