export const BASE_ROUTES = {
  base: "/",
  login: "/login",
  signup: "/signup",
  demo: "/demo",
  database: "/database",
  vault: "/vault",
  review: "/review",
  about: "/about",
  plans: "/plans",
  acceptInvite: "/accept-invite",
  coachesPlan: "/coaches-plan",
  testPage: "/test-page",
};

const dashboardBase = "/dashboard";
export const DASHBOARD_ROUTES = {
  base: dashboardBase,
  database: `${dashboardBase}/database`,
  vault: `${dashboardBase}/vault`,
  vaultTopic: `${dashboardBase}/vault/topic/:id`,
  videoDetails: `${dashboardBase}/vault/video/:id`,
  vaultSection: `${dashboardBase}/vault/section`,
  review: `${dashboardBase}/review`,
  reviewDetail: `${dashboardBase}/review/:id`,
  reviewFeedback: `${dashboardBase}/review-feedback/:id`,
  rules: `${dashboardBase}/rules`,
  newsletter: `${dashboardBase}/newsletter`,
  help: `${dashboardBase}/help`,
  plans: `${dashboardBase}/plans`,
  coachPlanManager: `${dashboardBase}/coach-plan-manager`,
  userSettings: `${dashboardBase}/user-settings`,
};

const adminPanelBase = "/admin";
export const ADMIN_ROUTES = {
  base: adminPanelBase,
  database: `${adminPanelBase}/database`,
  vault: `${adminPanelBase}/vault`,
  vaultTags: `${adminPanelBase}/vaultTags`,
  vaultCategories: `${adminPanelBase}/vaultCategories`,
  vaultTopics: `${adminPanelBase}/vaultTopics`,
  newVaultTopic: `${adminPanelBase}/newVaultTopic`,
  editVaultTopic: `${adminPanelBase}/vault/edit-topic/:id`,
  newVideo: `${adminPanelBase}/vault/new-video`,
  editVideo: `${adminPanelBase}/vault/edit-video/:id`,
  review: `${adminPanelBase}/review`,
  reviewDetail: `${adminPanelBase}/review/:id`,
  reviewsStructure: `${adminPanelBase}/reviewsStructure`,
  newsletter: `${adminPanelBase}/newsletter`,
  newNewsletter: `${adminPanelBase}/newNewsletter`,
  editNewsletter: `${adminPanelBase}/editNewsletter/:id`,
  users: `${adminPanelBase}/users`,
};
