import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getTags, getTopic, saveTopic } from "../../../common/services/firebase/vault";
import Button from "../../components/button";
import Input from "../../components/input";
import TagsModal from "../../components/tags-modal";
import Text from "../../components/text";
import styles from './styles.module.css';

const NewVaultTopic = () => {
  const { id: _topicId } = useParams();
  const [docKey, setDocKey] = useState();
  const [title, setTitle] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [description, setDescription] = useState();
  const [vaultTags, setVaultTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadingTopic, setLoadingTopic] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setVaultTags)
      .finally(() => setLoadingTags(false));
  }, []);

  useEffect(() => {
    if (_topicId) {
      setLoadingTopic(true);
      getTopic(_topicId)
        .then(res => {
          setDocKey(res.id);
          setTitle(res.title);
          setShortDescription(res.shortDescription);
          setDescription(res.description);
          setTags(res.tags || []);
        })
        .finally(() => setLoadingTopic(false));
    } else {
      setLoadingTopic(false);
    }
  }, [_topicId]);

  if (loadingTopic || loadingTags) return <Text>Loading...</Text>;

  const onSave = async () => {
    if (!title || !shortDescription) return alert("A topic should have a title and a short description");
    await saveTopic({
      id: docKey,
      title,
      shortDescription,
      description,
      tags,
    });
    history.goBack();
  }

  const onSelectTags = (newTags) => {
    setTags(newTags);
    setShowTagsModal(false);
  }

  const onRemoveTag = (index) => {
    setTags(prev => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  return (
    <div className={styles.container}>
      <Text className={styles.title}>{docKey ? 'Edit' : 'New'} Topic</Text>
      <Input className={styles.input} label="Title" value={title} onChange={evt => setTitle(evt.target.value)} />
      <Input className={styles.input} label="Short Description" value={shortDescription} onChange={evt => setShortDescription(evt.target.value)} />
      <Input className={styles.input} label="Description" value={description} onChange={evt => setDescription(evt.target.value)} textArea />
      <div className={styles.tagInputContainer}>
        <Button onClick={() => setShowTagsModal(true)}>
          Select Tags
        </Button>
      </div>
      {tags.length ? (
        <div className={styles.tagsContainer}>
          {tags.map((tagId, index) => {
            const tag = vaultTags.find(vt => vt.id === tagId);
            if (!tag) return null;
            return (
              <div key={tagId} className={styles.tag}>
                <Text>{tag.label}</Text>
                <div className={styles.removeTagContainer} onClick={() => onRemoveTag(index)}>
                  <Text className={styles.removeTag}>X</Text>
                </div>
              </div>
            )
          })}
        </div>
      ) : null}
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={onSave}>
          Save
        </Button>
      </div>
      <TagsModal
        visible={showTagsModal}
        onClose={() => setShowTagsModal(false)}
        tags={vaultTags}
        selectedTags={tags}
        onSelectTags={onSelectTags}
      />
    </div>
  )
}

export default React.memo(NewVaultTopic);
