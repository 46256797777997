import React from "react";
import Text from "../text";

const Input = ({
  label,
  ...props
}) => {
  return (
    <div>
      {label ? <Text>{label}</Text> : null}
      <input {...props} />
    </div>
  )
}

export default React.memo(Input);
