import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { ICONS_NAME } from "../../../components/icon";
import Text from "../../../components/text";
import WidgetBaseCard from "./base-card";

const DatabaseWidget = () => {
  const history = useHistory();
  const isEmpty = true;

  const onClick = () => {
    history.push(DASHBOARD_ROUTES.database);
  }

  return (
    <WidgetBaseCard
      title="Database"
      content={isEmpty ? (
        <Text variant="description">Explore colleges and find coaches</Text>
      ) : (
        <div>
          <Text variant="description">Recently Viewed</Text>
          <div>
            <Text variant="description">Grove City College</Text>
          </div>
        </div>
      )}
      buttonText={isEmpty ? "Start Searching" : "Go there"}
      icon={ICONS_NAME.pin}
      onClick={onClick}
    />
  );
};

export default React.memo(DatabaseWidget);
