import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { replaceRoute } from "../../../../common/utils/routes";
import Button from "../../../components/button";
import Icon, { ICONS_NAME } from "../../../components/icon";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import { REVIEW_LABEL, REVIEW_STATUS } from "../../../constants";
import styles from "./styles.module.css";

const ReviewsExtraInfo = ({
  reviewsStatus,
  className
}) => {
  const history = useHistory();
  if (!reviewsStatus) return null;
  const statusKeys = Object.keys(reviewsStatus);

  const ReviewCard = ({ data }) => {
    const reviews = reviewsStatus[data];
    const firstReview = reviews[0];
    const onClick = () => {
      if (data === REVIEW_STATUS.DONE) return history.push(replaceRoute(DASHBOARD_ROUTES.reviewFeedback, ['id', firstReview?.id]));
      history.push(replaceRoute(DASHBOARD_ROUTES.reviewDetail, ['id', firstReview?.id]));
    }
    return (
      <InfoCard
        containerClassname={styles.cardContainer}
        className={styles.mainCardContainer}
        titleContent={
          <div className={styles.cardTitleContainer}>
            <Icon name={ICONS_NAME.googleDocs} />
            <Text variant="h3">{reviews.length} {REVIEW_LABEL[data]}</Text>
          </div>
        }
        onClick={onClick}
        extraContent={
          <div className={styles.cardButtonContainer} onClick={onClick}>
            <Text className={data === REVIEW_STATUS.DONE ? styles.viewText : styles.detailsText}>
              {data === REVIEW_STATUS.DONE ? "View Report" : "See Details"}
            </Text>
          </div>
        }
      />
    )
  }

  return (
    <div className={className}>
      <div className={styles.titleContainer}>
        <Text variant="h2">Reviews</Text>
      </div>
      <div className={styles.reviewsListContainer}>
        {statusKeys.map(sk => <ReviewCard key={`review-card-${sk}`} data={sk} />)}
      </div>
      <div className={styles.buttonContainer}>
        <Button iconName={ICONS_NAME.plus} onClick={() => history.push({ pathname: DASHBOARD_ROUTES.review, search: "?new-review=true" })} variant="outlinePrimary" shape="square">New Review</Button>
      </div>
    </div>
  );
};

export default React.memo(ReviewsExtraInfo);
