import { updatePassword } from "firebase/auth";
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { auth, firestore, httpsCallable } from '..';
import { USERS_COLLECTION } from '../constants';

const createStripeUser = httpsCallable('createStripeUserFunc');

const usersCollection = collection(firestore, USERS_COLLECTION);
export const PLACEHOLDER_PASSWORD = "PLACEHOLDER_PASSWORD";

export const getUser = async ({ userId }) => {
  const querySnapshot = await getDoc(doc(firestore, USERS_COLLECTION, userId));
  return querySnapshot.data();
};

export const getUserSnapshot = (userId, cb) => {
  const unsub = onSnapshot(doc(firestore, USERS_COLLECTION, userId), (doc) => {
    cb(doc.data());
  });
  return unsub;
};

export const getUsers = async ({ userTypes, email }) => {
  const queryConstraints = [];
  if (userTypes?.length) {
    queryConstraints.push(where('userType', 'in', userTypes));
  }
  if (email?.length) {
    queryConstraints.push(where('email', '==', email));
  }
  const q = query(usersCollection, ...queryConstraints);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const setupStripeUser = async (userId, email, name) => {
  const { data } = await createStripeUser({ email, name });
  await updateDoc(doc(firestore, USERS_COLLECTION, userId), { stripeId: data.id, _updatedAt: Date.now() });
  return data;
};

export const selectDefaultPaymentMethod = async (userId, pmId) => {
  await updateDoc(doc(firestore, USERS_COLLECTION, userId), { defaultPaymentMethod: pmId, _updatedAt: Date.now() });
};

export const updateUser = async (user) => {
  if (!user?.id) return undefined;
  await updateDoc(doc(firestore, USERS_COLLECTION, user.id), { ...user, _updatedAt: Date.now() });
  if (user.password && user.password !== PLACEHOLDER_PASSWORD) {
    await updatePassword(auth.currentUser, user.password);
  }
};
