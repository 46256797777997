import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button";
import Input from "../../components/input";
import { loadNewsletters } from "../../components/loader/utils";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import NewsletterCard from "./newsletter-card";
import NewsletterPaper from "./newsletter-paper";
import styles from "./styles.module.css";

const TOTAL_PAST_NEWSLETTERS = 10;

const getOrderedNewsletterTags = (newsletters = [], tags = []) => {
  for (let i = 0; i < newsletters.length; i++) {
    const n = newsletters[i];
    n.tags = (n.tags || [])
      .map((tagId) => tags.find((tag) => tag.id === tagId))
      .filter((t) => !!t);
  }
  return newsletters;
};

const Newsletters = () => {
  const { newsletters: reduxNewsletters, tags } = useSelector(
    (state) => state.newsletters
  );
  const loadingNewsletters = useSelector(
    (state) => state.loaders.loadingNewsletters
  );
  const [search, setSearch] = useState("");
  const [selectedNewsletter, setSelectedNewsletter] = useState();

  useEffect(() => {
    loadNewsletters();
  }, []);

  const newsletters = useMemo(() => {
    return getOrderedNewsletterTags(cloneDeep(reduxNewsletters), tags);
  }, [reduxNewsletters, tags]);

  const { lastNewsletter, pastNewsletters, blastFromThePastNewsletter } =
    useMemo(() => {
      if (!newsletters?.length) return {};
      const totalNewsletters = newsletters.length;
      const pastNewsletterTotal = newsletters.length - 2;
      const lastPastNewsletterIndex =
        pastNewsletterTotal > TOTAL_PAST_NEWSLETTERS
          ? TOTAL_PAST_NEWSLETTERS + 1
          : totalNewsletters - 1;
      const newPastNewsletters = newsletters.slice(1, lastPastNewsletterIndex);
      return {
        lastNewsletter: newsletters[0],
        pastNewsletters: newPastNewsletters,
        blastFromThePastNewsletter: newsletters?.[lastPastNewsletterIndex],
      };
    }, [newsletters]);
  const trimSearch = search.trim().toLowerCase();

  const filteredNewslleters = useMemo(() => {
    if (!trimSearch.length) return [];
    return newsletters.filter((n) => {
      const title = n.title.trim().toLowerCase();
      const abreviatedTitle = n.abreviatedTitle.trim().toLowerCase();
      const nTags = n.tags || [];
      return (
        title.includes(trimSearch) ||
        abreviatedTitle.includes(trimSearch) ||
        (nTags.length &&
          !!nTags.find((tag) =>
            tag.label.trim().toLowerCase().includes(trimSearch)
          ))
      );
    });
  }, [newsletters, trimSearch]);

  const renderPastNewsletters = () => {
    if (trimSearch.length) {
      if (filteredNewslleters.length) {
        return filteredNewslleters.map((n) => (
          <NewsletterCard
            key={`newsletter-card-${n.id}`}
            {...n}
            onClick={() => setSelectedNewsletter(n)}
          />
        ));
      }
      return (
        <div className={styles.emptySearchContainer}>
          <Text>No newsletter found</Text>
        </div>
      );
    }
    if (!pastNewsletters) return null;
    return pastNewsletters.map((n) => (
      <NewsletterCard
        key={`newsletter-card-${n.id}`}
        {...n}
        onClick={() => setSelectedNewsletter(n)}
      />
    ));
  };

  return (
    <Screen loading={loadingNewsletters}>
      <PageTitle title="Newsletter" />
      <div className={styles.descriptionContainer}>
        <Text>
          Introducing the Athletes After Weekly Newsletter – your go-to source
          for the latest in college football. Designed for parents, coaches, and
          athletes, our newsletter keeps you updated with essential news, expert
          insights, and exclusive content. Stay connected and informed with the
          Athletes After Weekly Newsletter.{" "}
        </Text>
      </div>
      <div className={styles.lastNewsletterContainer}>
        {lastNewsletter ? <NewsletterPaper {...lastNewsletter} /> : null}
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.searchPastNewslettersHeaderContainer}>
          <Text variant="h2">
            {trimSearch.length ? "Search Results" : "Past Newsletters"}
          </Text>
          <Input
            className={styles.searchInput}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            placeholder="Search past newsletters"
            variant="transparent"
            search="true"
          />
        </div>
        <div className={styles.pastNewsletterListContainer}>
          {renderPastNewsletters()}
        </div>
      </div>
      {selectedNewsletter ? (
        <div className={styles.sectionContainer}>
          <Text variant="h2">Selected newsletter</Text>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => setSelectedNewsletter(undefined)}
              variant="outlinePrimary"
            >
              Clear
            </Button>
          </div>
          <div className={styles.pastNewsletterListContainer}>
            <NewsletterPaper {...selectedNewsletter} />
          </div>
        </div>
      ) : null}
      {!selectedNewsletter && blastFromThePastNewsletter ? (
        <div className={styles.sectionContainer}>
          <Text variant="h2">A Blast From The Past</Text>
          <div className={styles.pastNewsletterListContainer}>
            <NewsletterPaper {...blastFromThePastNewsletter} />
          </div>
        </div>
      ) : null}
    </Screen>
  );
};

export default React.memo(Newsletters);
