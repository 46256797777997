import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNLIMITED_REVIEWS, USER_PLANS } from "../../../common/constants";
import { finishInitialLoading } from "../../../common/redux/reducers/loaders";
import { setReviewsLeft, setTotalReviewsLeft } from "../../../common/redux/reducers/reviews";
import { clearStripeData, setStripeData } from "../../../common/redux/reducers/stripe";
import { setUser } from "../../../common/redux/reducers/user";
import { httpsCallable } from "../../../common/services/firebase";
import { getUserSnapshot } from "../../../common/services/firebase/users";
import { logout } from "../../../common/utils/auth";
import { getStartAndEndOfMonth } from "../../../common/utils/time";
import Loading from "../loading";
import Text from "../text";
import styles from "./styles.module.css";
import { loadNewsletters, loadReviews, loadVault, loadVideoInProgress } from "./utils";

const getStripeUserInfo = httpsCallable("getStripeUserInfoFunc");

const getReviewsLeft = (userPlan, reviews = []) => {
  const plan = USER_PLANS[userPlan?.plan];
  if (!plan) return 0;
  const [startOfMonth, endOfMonth] = getStartAndEndOfMonth();
  return plan.reviews === UNLIMITED_REVIEWS ? UNLIMITED_REVIEWS : (plan.reviews - reviews.filter(r => r._createdAt >= startOfMonth && r._createdAt <= endOfMonth).length);
};

const Loader = ({ children }) => {
  const user = useSelector(state => state.user);
  const stripeData = useSelector(state => state.stripe);
  const reviews = useSelector(state => state.reviews.reviews);
  const reviewsLeft = useSelector(state => state.reviews.reviewsLeft);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const onFinishInitialLoading = () => {
    setIsLoading(false);
    dispatch(finishInitialLoading());
  }

  const loadStripe = async (userId) => {
    const { data } = await getStripeUserInfo(userId);
    if (data) {
      dispatch(setStripeData({
        subscription: data.subscription,
        userPlan: data.userPlan,
      }));
    } else {
      dispatch(clearStripeData());
    }
  };

  useEffect(() => {
    let unsub;
    if (user?.id) {
      unsub = getUserSnapshot(user.id, async newUser => {
        dispatch(setUser(newUser));
        await Promise.all([
          loadStripe(newUser.id),
          loadVault(),
          loadNewsletters(),
          loadVideoInProgress(newUser.id),
          loadReviews(newUser.id),
        ]).catch(err => {
          logout();
        }).finally(() => onFinishInitialLoading());
      });
    } else {
      onFinishInitialLoading();
    }
    return () => unsub?.();
  }, [user?.id, user?.stripeId, user?.coachPlan, user?._updatedAt]);

  useEffect(() => {
    dispatch(setReviewsLeft(getReviewsLeft(stripeData?.userPlan, reviews) || 0));
  }, [reviews, stripeData]);

  useEffect(() => {
    dispatch(setTotalReviewsLeft(reviewsLeft === 'unlimitedReviews' ? reviewsLeft : reviewsLeft + (user?.extraReviews || 0)));
  }, [reviews, reviewsLeft, stripeData, user?.extraReviews]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <div>
          <Loading size={70} />
        </div>
        <div>
          <Text>Loading user info</Text>
        </div>
      </div>
    )
  }

  return children;
};

export default React.memo(Loader);
