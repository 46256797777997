import React, { useState } from "react";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import {
  DIVISION1_CONTENT,
  DIVISION2_CONTENT,
  DIVISION3_CONTENT,
  NAIA_CONTENT,
} from "../../constants";
import styles from "./styles.module.css";
import TabContent from "./tab-content";
import classNames from "classnames";

const TABS = [
  { id: "division1", title: "Division I", content: DIVISION1_CONTENT },
  { id: "division2", title: "Division II", content: DIVISION2_CONTENT },
  { id: "division3", title: "Division III", content: DIVISION3_CONTENT },
  { id: "naia", title: "NAIA", content: NAIA_CONTENT },
  { id: "juco", title: "JUCO", content: {} },
];

const Rules = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const content = TABS[selectedTab].content;

  return (
    <Screen>
      <PageTitle title="Recruiting rules & Regulations" />
      <div className={styles.tabsContainer}>
        {TABS.map((tab, index) => {
          const isSelected = selectedTab === index;
          return (
            <div key={tab.id} onClick={() => setSelectedTab(index)}>
              <Text
                variant={isSelected ? "regular" : "description"}
                className={classNames(
                  styles.tabTitle,
                  isSelected && styles.tabSelected
                )}
              >
                {tab.title}
              </Text>
            </div>
          );
        })}
      </div>
      <div className={styles.infoContainer}>
        <Text variant="h3">Just so you know:</Text>
        <div>
          <Text>
            While it's always helpful to be familiar with the NCAA recruiting
            rules and calendar, it's really up to the college coaches to comply
            with the rules. As an athlete, you can email, call or DM a coach at
            any time. It's the coach's responsibility to check the rules before
            they respond.
          </Text>
        </div>
      </div>
      <div className={styles.tabContent}>
        <TabContent content={content} />
      </div>
    </Screen>
  );
};

export default React.memo(Rules);
