import { cloneDeep } from "lodash";

export const getCollectionTags = (collection = [], tags = []) => {
  const newCollection = cloneDeep(collection);
  for (let i = 0; i < newCollection.length; i++) {
    const c = newCollection[i];
    c.tags = (c.tags || []).map(tagId => {
      const tag = tags.find(tag => tag.id === tagId);
      if (tag) return {
        id: tag.id,
        label: tag.label,
        icon: tag.icon,
      }
      return undefined;
    }).filter(t => !!t);
  }
  return newCollection;
}

export const getVideoTags = (videoTags, vaultTags) => {
  if (!videoTags?.length || !vaultTags?.length) return [];
  return videoTags.map(videoTag => vaultTags.find(vaultTag => vaultTag.id === videoTag));
}