import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../icon";
import Text from "../../text";
import styles from "./styles.module.css";

const MenuItem = ({
  title,
  openMenu,
  iconName,
  isSelected,
  route,
  onAfterClick,
  isMobile,
}) => {
  const history = useHistory();
  const onClick = () => {
    history.push(route);
    onAfterClick?.();
  };

  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   setIsMobile(window.innerWidth < "768");
  // }, []);

  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < "768");
  //     console.log(isMobile);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div onClick={onClick} className={styles.buttonContainer}>
      <div
        className={cn(styles.container, {
          [styles.closedMenu]: !openMenu,
          [styles.selectedMenu]: isSelected,
          [styles.mobileMenu]: isMobile,
        })}
      >
        {iconName ? <Icon name={iconName} alt="Icon" size={22} /> : null}
        {openMenu || isMobile ? <Text>{title}</Text> : null}
      </div>
    </div>
  );
};

export default React.memo(MenuItem);
