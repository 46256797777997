import React, { useMemo } from "react";
import styles from "./styles.module.css";

const Loading = ({ size = 20 }) => {
  const borderWidth = useMemo(() => {
    const newBorderWidth = size / 10;
    if (newBorderWidth < 1) return 1;
    if (newBorderWidth > 7) return 7;
    return newBorderWidth;
  }, [size]);
  return (
    <span
      className={styles.loader}
      style={{ width: size, height: size, borderWidth }}
    ></span>
  );
};

export default React.memo(Loading);
