import { collection, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { firestore, httpsCallable } from "..";
import { ACCPET_INVITE_LINK, EMAIL_TEMPLATE, INVITE_FROM, INVITE_STATUS, PLANS_PAGE_LINK, SUPPORT_EMAIL, USER_TYPE } from "../../../constants";
import { signUp } from "../auth";
import { INVITES_COLLECTION, USERS_COLLECTION } from "../constants";
import { getUsers } from "../users";

const invitesCollection = collection(firestore, INVITES_COLLECTION);
const usersCollection = collection(firestore, USERS_COLLECTION);

const cancelSubscription = httpsCallable("cancelSubscriptionFunc")
const getStripeUserInfo = httpsCallable("getStripeUserInfoFunc");
const sendEmail = httpsCallable("sendEmailFunc");

export const getInvite = async (inviteId) => {
  if (!inviteId) return undefined;
  const querySnapshot = await getDoc(doc(invitesCollection, inviteId));
  return querySnapshot?.data();
};

export const getUsersInCoachPlan = async (coachPlan) => {
  if (!coachPlan) return [];
  const q = query(invitesCollection, where('from', '==', INVITE_FROM.COACH_PLAN), where('coachPlan', '==', coachPlan));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const inviteUserToCoachPlan = async (email, coachId, coachPlan, usersInPlan = [], coachName) => {
  const lastOccurrence = usersInPlan.filter(u => u.email === email).sort((a, b) => b._createdAt - a._createdAt)?.[0];
  if (lastOccurrence) {
    if (lastOccurrence.status !== INVITE_STATUS.REMOVED) throw new Error("User already invited");
    if (lastOccurrence.status === INVITE_STATUS.REMOVED) {
      const givenDateInMs = lastOccurrence._createdAt;
      const nowInMs = Date.now();
      const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;
      const thirtyDaysAgoInMs = nowInMs - millisecondsIn30Days;
      const is30DaysBeforeToday = givenDateInMs <= thirtyDaysAgoInMs;
      if (!is30DaysBeforeToday) throw new Error("This user was already in the last 30 days.")
    }
  }
  const userSnapshot = await getUsers({ email });
  const user = userSnapshot?.[0];
  const newInvite = {
    email,
    coachId,
    coachPlan,
    from: INVITE_FROM.COACH_PLAN,
    status: INVITE_STATUS.SENT,
    _createdAt: Date.now(),
  }
  if (user) {
    newInvite.userId = user.id;
    const { data: userStripeInfo } = await getStripeUserInfo(user.id);
    if (!!userStripeInfo?.userPlan && !window.confirm("User already has an active plan. Do you still want to invite them?")) throw new Error("User not invited");;
  }
  const docRef = doc(invitesCollection);
  newInvite.id = docRef.id;
  await setDoc(docRef, newInvite);
  try {
    sendEmail({
      template: EMAIL_TEMPLATE.WELCOME_COACH,
      userEmails: [email],
      variables: {
        acceptInviteLink: `${ACCPET_INVITE_LINK}${docRef.id}`,
        coachName,
        supportEmail: SUPPORT_EMAIL,
      },
    });
  } catch (error) {
    console.log('error', error)
  }
};

export const removeUserInvite = async (inviteId, userId, coachName) => {
  const docRef = doc(invitesCollection, inviteId);
  const inviteSnapshot = await getDoc(docRef);
  const invite = inviteSnapshot.data();
  await updateDoc(docRef, { status: INVITE_STATUS.REMOVED });
  if (userId) {
    const userDocRef = doc(usersCollection, userId);
    await updateDoc(userDocRef, { coachPlan: deleteField(), _updatedAt: Date.now() });
  }
  try {
    if (invite) {
      await sendEmail({
        template: EMAIL_TEMPLATE.REMOVE_USER_COACH,
        userEmails: [invite.email],
        variables: {
          coachName,
          supportEmail: SUPPORT_EMAIL,
          planRemovalDate: new Date().toLocaleDateString("en-US"),
          plansPageLink: PLANS_PAGE_LINK,
        },
      });
    }
  } catch (error) {
    console.log('error', error)
  }
};

export const refuseCoachPlan = async (inviteId) => {
  if (!inviteId) throw new Error("Missing invite id");
  const inviteDocRef = doc(invitesCollection, inviteId);
  await updateDoc(inviteDocRef, { status: INVITE_STATUS.DECLINED, userResponseDate: Date.now() });
};

export const acceptCoachPlan = async (inviteId, coachPlan, user, subscription) => {
  if (!inviteId) throw new Error("Missing invite id");
  if (!user) throw new Error("Missing user");
  if (!user.id) {
    await signUp({
      ...user,
      userType: USER_TYPE.COMPETITOR,
      extraFields: {
        coachPlan
      }
    });
  } else {
    if (subscription) {
      try {
        await cancelSubscription({ userId: user.id, subscriptionId: subscription.id });
      } catch (error) {
        console.log('error', error);
      }
    }
    const userDocRef = doc(usersCollection, user.id);
    await updateDoc(userDocRef, { coachPlan: coachPlan, _updatedAt: Date.now() });
  }
  const inviteDocRef = doc(invitesCollection, inviteId);
  await updateDoc(inviteDocRef, { status: INVITE_STATUS.ACCEPTED, userResponseDate: Date.now() });
};
