import _ from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../common/route";
import Button from "../../components/button";
import ContinueWatching from "../../components/continue-watching";
import { ICONS_NAME } from "../../components/icon";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import { getVideoInProgress } from "../../utils/vault";
import ReviewsExtraInfo from "./reviews-extra-info";
import styles from "./styles.module.css";
import DatabaseWidget from "./widgets/database";
import FilmReviewsWidget from "./widgets/film-reviews";
import VaultWidget from "./widgets/vault-widget";

const Home = () => {
  const user = useSelector(state => state.user);
  const videos = useSelector(state => state.vault.videos);
  const reviews = useSelector(state => state.reviews.reviews);
  const videosInProgress = useSelector(state => state.videosInProgress.videos);
  const history = useHistory();
  const videoInProgress = useMemo(() => {
    return getVideoInProgress(videos, videosInProgress);
  }, [videosInProgress, videos]);
  const reviewsStatus = useMemo(() => {
    if (!reviews?.length) return;
    return _.groupBy(reviews, r => r.status);
  }, [reviews]);

  return (
    <Screen>
      <PageTitle title={`Welcome back, ${user.firstName}`} />
      <div className={styles.widgetsContainer}>
        <DatabaseWidget />
        <VaultWidget videoInProgress={videoInProgress} />
        <FilmReviewsWidget reviewsStatus={reviewsStatus} />
      </div>
      <div className={styles.buttonsContainer}>
        <Button iconName={ICONS_NAME.userProfile} onClick={() => history.push(DASHBOARD_ROUTES.userSettings)} variant="outlinePrimary" shape="square">Account</Button>
        <Button iconName={ICONS_NAME.creditCard} onClick={() => history.push(DASHBOARD_ROUTES.plans)} variant="outlinePrimary" shape="square">Plans & Payments</Button>
      </div>
      <ContinueWatching video={videoInProgress} className={styles.sectionContainer} />
      <ReviewsExtraInfo reviewsStatus={reviewsStatus} className={styles.sectionContainer} />
    </Screen>
  )
}

export default React.memo(Home);
