import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import { saveAllDoc, saveDivisionDoc, saveSearchDoc, uploadImage } from '../../../common/services/firebase/database';
import Button from "../../components/button";
import Text from "../../components/text";
import FileStatus from './file-status';
import styles from './styles.module.css';
import { getColumns, getDocuments, getRows } from './utils';

const ACCEPTED_FILE_TYPES = ['xlsx', 'png'];
const DEFAULT_FILE_ERROR = 'Error. Try again or check if it is a valid file';

const Database = () => {
  const [filesStatus, setFilesStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const addError = (fileName, errorMessage) => {
    setFilesStatus(prev => [...prev, { name: fileName, message: errorMessage, error: true, ready: true }]);
  };

  const triggerLoading = (index, isLoading = true, isDone = false) => {
    setFilesStatus(prev => {
      const newState = [...prev];
      newState[index].loading = isLoading;
      newState[index].done = isDone;
      return newState;
    })
  };

  const onDrop = async (files) => {
    setDone(false);
    setFilesStatus([]);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file?.name && _.some(ACCEPTED_FILE_TYPES, type => _.endsWith(file.name, type))) {
        const reader = new FileReader();
        reader.onabort = () => addError(file.name, DEFAULT_FILE_ERROR);
        reader.onerror = () => addError(file.name, DEFAULT_FILE_ERROR);
        reader.onload = (e) => {
          const data = e.target.result;
          if (file.name.endsWith('.png')) {
            setFilesStatus(prev => [...prev, { name: file.name, data, ready: true }]);
          } else {
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const data0 = rawData[0];
            const data1 = rawData[1];
            const columns = getColumns(data0, data1);
            const rows = getRows(rawData);
            const documents = getDocuments(columns, rows);
            if (documents.length > 0) {
              setFilesStatus(prev => [...prev, { name: file.name, documents, ready: false }]);
            }
          }
        }
        reader.readAsArrayBuffer(file);
      } else {
        addError(file.name, 'File type not supported');
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onUpload = async () => {
    setLoading(true);
    try {
      await Promise.all(filesStatus.map((file, index) => new Promise(async (resolve) => {
        triggerLoading(index);
        if (file.name.endsWith('.png')) {
          uploadImage(file.name.toLowerCase(), file.data)
            .then((result) => console.log('result DONE', file.name), (error) => addError(file.name, DEFAULT_FILE_ERROR))
            .catch((err) => addError(file.name, DEFAULT_FILE_ERROR))
            .finally(() => {
              setFilesStatus(prev => {
                const newState = [...prev];
                newState[index].done = true;
                return newState;
              })
              triggerLoading(index, false, true);
              resolve();
            });
        } else {
          const documents = file?.documents || [];
          for (let i = 0; i < documents.length; i++) {
            const doc = documents[i];
            doc.division = file.division;
            console.log('doc.name', doc.name);
            await saveAllDoc(doc);
            await saveDivisionDoc(doc, file.division);
            await saveSearchDoc(doc);
          }
          triggerLoading(index, false, true);
          resolve();
        }
      })));
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false);
    setDone(true);
  }

  const uploadIsDisabled = useMemo(() => {
    if (!filesStatus.length || done || loading) return true;
    return _.some(filesStatus, file => !file.ready);
  }, [filesStatus, done, loading]);

  const onChangeDivision = (value, index) => {
    setFilesStatus(prev => {
      const newState = [...prev];
      newState[index].division = value;
      newState[index].ready = !!value;
      return newState;
    })
  }

  return (
    <div className={styles.container}>
      <Text>
        <h1>
          Upload your XLSX file or the school images to the database.
        </h1>
      </Text>
      <div {...getRootProps()} className={`${styles.dragContainer} ${isDragActive && styles.hoverDragContainer}`}>
        <input {...getInputProps()} />
        {!isDragActive && <Text>Drag 'n' drop some files here, or click to select them</Text>}
        {isDragActive && <Text>Release your files here</Text>}
      </div>
      {loading && <Text><h3>Uploading... This action might take a couple minutes. Please do not leave this page</h3></Text>}
      {filesStatus?.length > 0 && (
        <div className={styles.uploadAreaContainer}>
          <div className={styles.fileStatusListContainer}>
            <Text><h1>{loading ? 'Uploading Files...' : 'Files Selected'}</h1></Text>
            {filesStatus.map((file, index) => {
              return (
                <div key={`file-status-${index}`} className={styles.rowContainer}>
                  <div className={styles.fileName}><Text>{file.name}</Text></div>
                  <div className={styles.fileStatusContainer}>
                    <FileStatus file={file} index={index} onChangeDivision={onChangeDivision} />
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <Button disabled={uploadIsDisabled} onClick={onUpload} className={`${styles.button} ${uploadIsDisabled && styles.buttonDisabled}`}>Upload</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Database);
