import { createSlice } from '@reduxjs/toolkit';

export const loadersSlice = createSlice({
  name: 'loaders',
  initialState: {
    loadingUser: true,
    loadingUserStripe: true,
    loadingVault: true,
    loadingVideosInProgress: true,
    loadingReviews: true,
    loadingNewsletters: true,
  },
  reducers: {
    setLoaders: (state, action) => {
      const loaders = action.payload || {};
      const loadersKeys = Object.keys(loaders);
      for (let i = 0; i < loadersKeys.length; i++) {
        const key = loadersKeys[i];
        const value = loaders[key];
        state[key] = value;
      }
    },
    startInitialLoading: (state) => {
      const loaders = state;
      const loadersKeys = Object.keys(loaders);
      for (let i = 0; i < loadersKeys.length; i++) {
        const key = loadersKeys[i];
        state[key] = true;
      }
    },
    finishInitialLoading: (state) => {
      const loaders = state;
      const loadersKeys = Object.keys(loaders);
      for (let i = 0; i < loadersKeys.length; i++) {
        const key = loadersKeys[i];
        state[key] = false;
      }
    },
  },
});

export const { setLoaders, finishInitialLoading, startInitialLoading } = loadersSlice.actions;

export default loadersSlice.reducer;
