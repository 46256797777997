import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { BASE_ROUTES } from "../../route";
import { userHasAdminPanelPermission } from "../../utils/permission";

const PrivateAdminRoute = ({
  children,
  redirect = true,
  allowedUserTypes,
  // permission
}) => {
  const user = useSelector(state => state.user);

  if (userHasAdminPanelPermission(user, allowedUserTypes)) {
    return children;
  }

  if (redirect) {
    return <Redirect to={BASE_ROUTES.login} />;
  }

  return (
    <div>
      You are not authorized to view this page.
    </div>
  )
};

export default React.memo(PrivateAdminRoute);
