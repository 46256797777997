import React, { useMemo, useState } from "react";
import styles from "./styles.module.css";
import cn from "classnames";
import { motion, useMotionValue } from "framer-motion";
import arrow from "../../assets/icons/arrow2.svg"

const DRAG_BUFFER = 10;

const Carousel = ({ startingCard, cardsList }) => {

  const [dragging, setDragging] = useState(false);
  const [cardIndex, setCardIndex] = useState(startingCard ? startingCard - 1 : 0);

  const dragX = useMotionValue(0);

  const onDragStart = () => {
    setDragging(true);
  };
  const onDragEnd = () => {
    setDragging(false);

    const x = dragX.get();
    if (x <= -DRAG_BUFFER && cardIndex < cardsList.length - 1) {
      setCardIndex((pv) => pv + 1);
    } else if (x >= DRAG_BUFFER && cardIndex > 0) {
      setCardIndex((pv) => pv - 1);
    }
  };



  return (
    <div className={styles.carousel} >
      <div className={styles.carouselContainer} >
        <motion.div
          className={styles.cardContainer}
          drag="x"
          dragConstraints={{
            left: 0,
            right: 0
          }}
          style={{
            x: dragX,
          }}
          animate={{
            translateX: `-${cardIndex * 100}%`,
            // translateX: `- ${cardIndex * 100}% - ${cardIndex * 2}em `,
            // translateX: `-${cardIndex * (window.innerWidth - 49)}px`,
          }}
          transition={{
            //duration: .3,
            type: "spring",
            mass: 2.5,
            stiffness: 500,
            damping: 55,
          }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >

          {/* CARDS */}
          <>
            {cardsList.map((cardSrc, idx) => {
              return (
                <motion.div
                  key={idx}
                  // OLD, for setting photos to bg image:
                  // style={{
                  //   backgroundImage: `url(${cardSrc})`,
                  //   backgroundSize: "cover",
                  //   backgroundPosition: "center",
                  // }}

                  // OTHER animation options for the card
                  // animate={{
                  //   scale: cardIndex === idx ? 1 : 0.9,
                  // }}
                  // transition={{
                  //   ease: "linear"
                  // }}
                  className={cn(styles.card, { [styles.currentCard]: idx === cardIndex })}
                >{cardSrc}</motion.div>
              );
            })}
          </>
        </motion.div>

      </div>
      <div className={styles.navContainer}>


        {/* LEFT BUTTON */}
        <img
          src={arrow}
          className={styles.leftButton}
          onClick={() => {
            if (cardIndex !== 0) {
              setCardIndex(cardIndex - 1)
            } else {
              setCardIndex(cardsList.length - 1)
            }
          }}>
        </img>


        {/* DOTS */}
        <div
          className={styles.dotsContainer}>
          {cardsList.map((card, idx) => {
            return <div
              key={idx}
              onClick={() => setCardIndex(idx)}
              className={cn(styles.dots, { [styles.currentDot]: idx === cardIndex })}
            />
          })}
        </div>


        {/* RIGHT BUTTON */}
        <img
          src={arrow}
          className={styles.rightButton}
          onClick={() => {
            if (cardIndex !== cardsList.length - 1) {
              setCardIndex(cardIndex + 1)
            } else {
              setCardIndex(0)
            }
          }}>
        </img>

      </div>
    </div>
  );
};





export default React.memo(Carousel);
