import cn from "classnames";
import GoogleMapReact from "google-maps-react-markers";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import onClickOutside from "../common/hooks/onClickOutside";
import { BASE_ROUTES } from "../common/route";
import {
  getDivisionSchools,
  getSchoolsByName,
} from "../common/services/firebase/database";
import Close from "../Dashboard/assets/icons/close.svg";
import Logo from "../Dashboard/assets/images/full-logo.png";
import Text from "../Dashboard/components/text";
import Filters from "./components/filters";
import MobileFilters from "./components/mobile-filters";
import SchoolDetails from "./components/school-details";
import { useFilter } from "./context/filter";
import styles from "./styles.module.css";
import { getContainerClassName, getTextClassName } from "./utils/dark-mode";
import { getDistanceFromLatLon } from "./utils/distance";
import { getSchoolImage } from "./utils/images";
import { fixString } from "./utils/string";

const DEFAULT_ZOOM = 6;
const DEFAULT_LAT_LNG = {
  lat: 39.10871196539853,
  lng: -94.57353893161195,
};
const COMPONENT_SIZE = {
  6: 30,
  7: 40,
  8: 50,
  9: 60,
  10: 70,
  11: 80,
};

const MAP_DIFFERENCE = {
  6: 8,
  7: 5,
  8: 2,
  9: 1,
  10: 0.5,
  11: 0.25,
  12: 0.125,
  13: 0.06,
  14: 0.03,
  15: 0.015,
  16: 0.0075,
  17: 0.00375,
  18: 0.001875,
  19: 0.0009375,
  20: 0.00046875,
  21: 0.000234375,
  22: 0.0001171875,
};

const getMinZoom = (screenWidth) => {
  if (screenWidth < 1200) return 5;
  return 6;
};

const getComponentSize = (zoomLevel) => {
  if (zoomLevel < 6) return 30;
  return COMPONENT_SIZE[zoomLevel] || 80;
};

const SchoolComponent = ({
  schoolName,
  schoolAddress,
  onClick,
  onMouseEnter,
  onMouseLeave,
  zoom,
  isSelected,
  isMobile,
  isDark,
}) => {
  const componentSize = getComponentSize(zoom);
  const componentSizeStr = `${componentSize}px`;
  const canShowSchoolDetail = isMobile && zoom >= 9;

  return (
    <div>
      <div className={styles.schoolOutterContainer}>
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          className={`${styles.schoolContainer} ${isSelected ? styles.selectedSchoolContainer : ""
            }`}
          style={{ width: componentSizeStr, height: componentSizeStr }}
        >
          <img
            src={getSchoolImage(schoolName, isDark)}
            style={{ height: componentSizeStr, width: componentSizeStr }}
            alt={schoolName}
          />
        </div>
        {canShowSchoolDetail && (
          <div
            className={
              isSelected
                ? styles.schoolInfoContainerSelected
                : styles.schoolInfoContainer
            }
          >
            <div className={styles.schoolNameText}>{schoolName}</div>
            <div className={styles.schoolAddressText}>{schoolAddress}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyLocation = () => (
  <img
    src={require("./assets/icons/marker.png")}
    width={20}
    height={30}
    alt="my_location"
  />
);

const turnSchoolsIntoArray = (schools = {}) => {
  const keys = Object.keys(schools);
  const newSchools = [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    newSchools.push({
      ...schools[key],
      name: key,
    });
  }
  return newSchools;
};

const turnSearchSchoolsIntoArray = (schools = {}) => {
  const keys = Object.keys(schools);
  const newSchools = [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const school = schools[key];
    newSchools.push({
      searchName: school.name,
      name: key,
    });
  }
  return newSchools;
};

export default function SimpleMap({ isDemo }) {
  const [schools, setSchools] = useState(turnSchoolsIntoArray());
  const [loading, setLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [hoveringSchool, setHoveringSchool] = useState();
  const [schoolToShow, setSchoolToShow] = useState();
  const [googleMap, setGoogleMap] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [directionsService, setDirectionsService] = useState();
  const [directionsDisplay, setDirectionsDisplay] = useState();
  const [mapCenter, setMapCenter] = useState(DEFAULT_LAT_LNG);
  const [mapZoom, setMapZoom] = useState(DEFAULT_ZOOM);
  const {
    selectedSchool,
    setSelectedSchool,
    selectedDistance,
    selectedTemperature,
    selectedState,
    myLocation,
    setMyLocation,
    schoolDirection,
    selectedStatus,
    selectedDivision,
    addToPreviousSchool,
    setSchoolRoute,
    setSearchSchools,
    isDark,
  } = useFilter();
  const { navBarOpen } = useSelector((state) => state.appState);
  const isMobile = screenWidth < 1000;
  const history = useHistory();

  const [startupOpen, setStartupOpen] = useState(true);
  const startup = useRef(null);
  onClickOutside(startup, () => setStartupOpen(false));

  const filteredSchools = useMemo(() => {
    let newFilteredSchools = schools.filter((s) => {
      if (s.extraSchool) return false;
      if (selectedDistance && myLocation) {
        const distanceFromUser = getDistanceFromLatLon(
          s.lat,
          s.lng,
          myLocation.lat,
          myLocation.lng
        );
        if (distanceFromUser > selectedDistance) return false;
      }
      if (selectedTemperature && s.temp !== selectedTemperature) return false;
      if (selectedStatus && s.publicPrivate !== selectedStatus) return false;
      if (
        selectedState &&
        fixString(s.stateLoc) !== fixString(selectedState.value)
      )
        return false;
      return true;
    });
    if (schoolToShow) {
      const schoolToShowIndex = newFilteredSchools.findIndex(
        (s) => s.name === schoolToShow.name && !s.extraSchool
      );
      if (schoolToShowIndex < 0) {
        newFilteredSchools.push({
          ...schoolToShow,
          extraSchool: true,
        });
      }
    }
    return newFilteredSchools;
  }, [
    schools,
    selectedDistance,
    myLocation,
    selectedTemperature,
    selectedStatus,
    schoolToShow,
    selectedState,
  ]);

  const mapId = useMemo(() => {
    const search = window.location.search;
    const newMapId = search?.split("=")[1];
    return newMapId;
  }, []);

  const loader = useCallback(async () => {
    setLoading(true);
    try {
      if (selectedDivision?.length) {
        const divisionSchools = await getDivisionSchools(selectedDivision.toUpperCase());
        const newSearchSchools = await getSchoolsByName();
        setSchools(turnSchoolsIntoArray(divisionSchools));
        setSearchSchools(turnSearchSchoolsIntoArray(newSearchSchools));
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }, [selectedDivision]);

  useEffect(() => {
    loader();
  }, [loader]);

  const centerOnMap = (newCenter, newZoom) => {
    if (googleMap) {
      googleMap.setCenter(newCenter);
      googleMap.setZoom(newZoom);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position.coords.latitude && position.coords.longitude) {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setMapCenter({ lat, lng });
        setMyLocation({ lat, lng });
        setMapZoom(10);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      setSchoolToShow(selectedSchool);
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (directionsService && directionsDisplay && googleMap) {
      if (schoolDirection && myLocation) {
        const markers = [
          myLocation,
          { lat: schoolDirection[0], lng: schoolDirection[1] },
        ];
        const request = {
          origin: markers[0],
          destination: markers[1],
          optimizeWaypoints: true,
          travelMode: "DRIVING",
        };
        directionsService.route(request, (result, status) => {
          if (status === "OK") {
            const route = result?.routes?.[0]?.legs?.[0];
            if (route) {
              setSchoolRoute({
                distance: route.distance,
                duration: route.duration,
                address: route.end_address,
              });
              directionsDisplay.setMap(googleMap);
              directionsDisplay.setDirections(result);
            }
          }
        });
      } else {
        directionsDisplay.setMap(null);
      }
    }
  }, [
    myLocation,
    schoolDirection,
    googleMap,
    directionsDisplay,
    directionsService,
    setSchoolRoute,
  ]);

  useEffect(() => {
    const handleScreenResize = _.debounce(
      (evt) => {
        setScreenWidth(evt.target.innerWidth);
      },
      [200]
    );
    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    setMapLoading(true);
    setTimeout(() => {
      setMapLoading(false);
    }, 100);
  }, [isDark]);

  const onClick = (school) => {
    addToPreviousSchool(school);
    if (isMobile) {
      setSchoolToShow(undefined);
    }
    setTimeout(() => {
      setSchoolToShow(school);
    }, 100);
  };

  const onClose = () => {
    setSelectedSchool(undefined);
    setSchoolToShow(undefined);
  };

  const onCenterLocation = (lat, lng) => {
    if (!isMobile && googleMap) {
      const newCenter = { lat, lng: lng - MAP_DIFFERENCE[mapZoom] };
      setMapCenter(newCenter);
      centerOnMap(newCenter, mapZoom);
    }
  };

  const handleApiLoaded = ({ map, maps }) => {
    setGoogleMap(map);
    setDirectionsService(new maps.DirectionsService());
    setDirectionsDisplay(
      new maps.DirectionsRenderer({
        suppressMarkers: true,
      })
    );
  };

  const onChange = ({ zoom: newZoom, center }) => {
    setMapCenter({ lat: center[1], lng: center[0] });
    setMapZoom(newZoom);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.closedNavBarContainer]: !navBarOpen,
        [styles.demoContainer]: isDemo,
      })}
    >
      {isDemo && (
        <>
          {startupOpen ? (
            <div className={styles.demoStartupContainer}>
              <div ref={startup} className={styles.demoStartup}>
                <img
                  className={styles.startupCloseIcon}
                  src={Close}
                  width={15}
                  height={15}
                  onClick={() => setStartupOpen(false)}
                />
                <img src={Logo} width={140} />
                <Text style={{ marginBottom: "5px" }} variant="h2">
                  Welcome to the Database!
                </Text>
                <Text style={{ marginBottom: "-5px" }}>
                  This is a Demo, so some feautures are limited.
                </Text>
                <Text>Click on a school to get started!</Text>
              </div>
            </div>
          ) : null}

          <div
            onClick={() => history.push(BASE_ROUTES.plans)}
            className={styles.demoVersionContainer}
          >
            Demo version.
            <div className={styles.getAccessBtn}>Get Access</div>
          </div>
        </>
      )}
      <Filters isDemo={isDemo} />
      <MobileFilters isDemo={isDemo} />
      <SchoolDetails
        isDemo={isDemo}
        schoolName={schoolToShow?.name}
        onCenterLocation={onCenterLocation}
        onClose={onClose}
      />
      {hoveringSchool ? (
        <div
          onMouseLeave={() => setHoveringSchool(undefined)}
          onMouseEnter={() => setHoveringSchool(hoveringSchool)}
          className={getContainerClassName(styles.hoveringContainer, isDark)}
        >
          <div
            className={getTextClassName(
              styles.hoveringContainerSchoolName,
              isDark
            )}
          >
            {hoveringSchool.name}
          </div>
          <div
            className={getTextClassName(
              styles.hoveringContainerSchoolAddress,
              isDark
            )}
          >
            {hoveringSchool.address || "Address"}
          </div>
        </div>
      ) : null}
      {!mapLoading && (
        <GoogleMapReact
          apiKey={process.env.REACT_APP_GOOGLE_MAPS}
          defaultCenter={mapCenter || DEFAULT_LAT_LNG}
          defaultZoom={mapZoom || DEFAULT_ZOOM}
          onGoogleApiLoaded={handleApiLoaded}
          options={{
            gestureHandling: "greedy",
            zoomControl: true,
            fullscreenControl: false,
            minZoom: getMinZoom(screenWidth),
            mapId: mapId || (isDark ? "234e8904a95e29c9" : "b721a114d685e380"),
            streetViewControl: false,
            mapTypeControl: false,
          }}
          onChange={onChange}
        >
          {myLocation ? (
            <MyLocation lat={myLocation.lat} lng={myLocation.lng} />
          ) : null}
          {filteredSchools.map((school) => (
            <SchoolComponent
              key={school.name}
              lat={school.lat}
              lng={school.lng}
              schoolName={school.name}
              schoolAddress={school.address}
              onClick={() => onClick(school)}
              onMouseEnter={() => setHoveringSchool(school)}
              onMouseLeave={() => setHoveringSchool(undefined)}
              zoom={mapZoom}
              isSelected={schoolToShow?.name === school.name}
              isMobile={isMobile}
              isDark={isDark}
            />
          ))}
        </GoogleMapReact>
      )}
    </div>
  );
}
