import cn from "classnames";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import Logo from "../../../assets/images/full-logo.png";
import CommonMenus from "../common-menus";
import styles from "./styles.module.css";
import Text from "../../../../common/components/text";

const MobileMenu = ({ scrolling }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const route = location.pathname;
  const ref = useRef(null);
  onClickOutside(ref, () => setOpen(false));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= "768");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= "768") {
        setOpen(false);
      }
      setIsMobile(window.innerWidth <= "768");
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={cn(styles.headerContainer, {
          [styles.headerContainerOpenMenu]: open,
          [styles.scrolled]: scrolling,
          [styles.database]: route === DASHBOARD_ROUTES.database,
        })}
      >
        <img src={Logo} alt="Logo" className={styles.logo} />
        <div className={styles.menuButtonContainer}>
          <div
            id="menuBtn"
            onClick={() => setOpen((prev) => !prev)}
            className={styles.menuButton}
          >
            <div className={styles.menuButtonContent}>
              <Text>MENU</Text>
              <div
                id={styles.icon}
                className={open ? [styles.opened] : [styles.closed]}
              >
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open ? (
        <div className={styles.menuContainer}>
          <CommonMenus
            open
            onAfterClick={() => setOpen(false)}
            isMobile={isMobile}
          />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(MobileMenu);
