import React from "react";
import { getImagePreviewUrl } from "../../../../common/utils/storage";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const NewsletterPaper = ({
  id,
  title,
  intro,
  description,
  image,
  _createdAt,
  body,
}) => {
  const imageUrl = getImagePreviewUrl("newsletter", image);
  return (
    <div className={styles.container}>
      <Text className={styles.date} variant="description">{new Date(_createdAt).toLocaleDateString()}</Text>
      <div className={styles.content}>
        <Text className={styles.title}>{title}</Text>
        <div>
          <Text className={styles.description}>{intro}</Text>
        </div>
        {(imageUrl || description) ? (
          <div className={styles.imageDescriptionContainer}>
            {imageUrl ? <img src={imageUrl} alt="thumbnail" className={styles.image} /> : null}
            {description ? <Text className={styles.description}>{description}</Text> : null}
          </div>
        ) : null}
        {body?.length ? (
          <div>
            {body.map((b, index) => {
              return (
                <div className={styles.bodyContainer} key={`newsletter-${id}-body-${index}`}>
                  <Text className={styles.bodyTitle}>{b.title}</Text>
                  <div>
                    <Text className={styles.description}>{b.description}</Text>
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
};

export default React.memo(NewsletterPaper);
