import React, { useMemo } from "react";
import CoachIcon from "../../assets/icons/coach.svg";
import MapIcon from "../../assets/icons/map.svg";
import NicheIcon from "../../assets/icons/niche.svg";
import SaveIcon from "../../assets/icons/save.svg";
import CampIcon from "../../assets/icons/camp.svg";
import NcaaIcon from "../../assets/icons/ncaa.svg";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading3 from "../.heading3";

const IconCard = ({ title, iconName }) => {
  const Icon = useMemo(() => {
    if (iconName === "coach") return CoachIcon;
    if (iconName === "camp") return CampIcon;
    if (iconName === "map") return MapIcon;
    if (iconName === "niche") return NicheIcon;
    if (iconName === "save") return SaveIcon;
    if (iconName === "division") return NcaaIcon;
    return null;
  }, [iconName]);

  return (
    <div className={styles.container}>
      <img src={Icon} alt="icon" className={styles.icon} />
      <div className={styles.descriptionContainer}>
        <Heading3 >{title}</Heading3>
      </div>
    </div>
  );
};

export default React.memo(IconCard);
