import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  navBarOpen: true,
};

export const appState = createSlice({
  name: 'appState',
  initialState: INITIAL_STATE,
  reducers: {
    setAppState: (state, action) => {
      state = {
        ...state,
        ...(action.payload || {})
      }
      return state
    },
  },
});

export const { setAppState } = appState.actions;

export default appState.reducer;
