import cssStyles from "./styles.module.css";

export const getClassName = (styles, className, isDark) => {
  return `${styles[className]} ${isDark && styles[`${className}Dark`]}`;
};

export const getTextClassName = (style, isDark) => {
  return `${style} ${isDark ? cssStyles.textDark : cssStyles.text}`;
};

export const getContainerClassName = (style, isDark, hover) => {
  return `${style} ${isDark ? cssStyles.containerDark : cssStyles.container} ${
    hover === "no-hover" ? null : isDark ? cssStyles.hoverDark : cssStyles.hover
  }`;
};

export const getSecondaryContainerClassName = (style, isDark) => {
  return `${style} ${
    isDark ? cssStyles.containerDarkSecondary : cssStyles.containerSecondary
  }`;
};
