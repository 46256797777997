import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { firestore, httpsCallable } from '..';
import { EMAIL_TEMPLATE, SUPPORT_EMAIL } from "../../../constants";
import { REVIEWS_COLLECTION, REVIEWS_STRUCTURE_COLLECTION, USERS_COLLECTION } from '../constants';

const sendEmailReview = httpsCallable("sendEmailFunc");

const usersCollection = collection(firestore, USERS_COLLECTION);
const reviewsCollection = collection(firestore, REVIEWS_COLLECTION);
const reviewsStructureCollection = collection(firestore, REVIEWS_STRUCTURE_COLLECTION);

export const getReview = async ({ reviewId }) => {
  const querySnapshot = await getDoc(doc(reviewsCollection, reviewId));
  return querySnapshot.data();
};

export const getUserReviews = async ({ userId, reviewerId, status, endDate, startDate, lastUpdatedAt }) => {
  const queryConstraints = [];
  if (userId) {
    queryConstraints.push(where("userId", '==', userId));
  }
  if (reviewerId) {
    queryConstraints.push(where("reviewerId", '==', reviewerId));
  }
  if (status) {
    const operation = typeof status === 'string' ? '==' : 'in';
    queryConstraints.push(where("status", operation, status));
  }
  if (startDate) {
    queryConstraints.push(where("_createdAt", ">=", startDate))
  }
  if (endDate) {
    queryConstraints.push(where("_createdAt", "<=", endDate))
  }
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(reviewsCollection, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getUserReviewsSnapshopt = (userId, cb) => {
  const unsub = onSnapshot(query(reviewsCollection, where("userId", '==', userId)), (snapshot) => {
    cb(snapshot.docs.map(doc => doc.data()));
  });
  return unsub;
};

export const getReviewsStructure = async ({ lastUpdatedAt }) => {
  const queryConstraints = [];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(reviewsStructureCollection, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const saveReviewsStructure = async (sections, position, isTechnical, docName) => {
  const docRef = doc(reviewsStructureCollection, docName);
  await setDoc(docRef, { sections, position, isTechnical, _updatedAt: Date.now() });
};

export const changeReview = async (reviewId, changedReview) => {
  if (!reviewId) throw new Error('Review not found');
  const review = await getReview({ reviewId });
  if (!review) return;
  const docRef = doc(reviewsCollection, reviewId);
  const newReview = { ...review, ...changedReview, _updatedAt: Date.now() };
  await updateDoc(docRef, newReview);
  return newReview;
};

export const submitReviewFeedback = async ({ reviewId, feedback }) => {
  for (const key in feedback) {
    if (feedback[key]?.note === undefined) {
      delete feedback[key].note;
    }
  }
  const docRef = doc(reviewsCollection, reviewId);
  const newReview = { feedback, _updatedAt: Date.now() };
  await updateDoc(docRef, newReview);
  return newReview;
};

export const createReview = async ({ title, userId, status, videoType, position, link, reviewsLeft }) => {
  const dateNow = Date.now();
  const newReview = {
    title,
    userId,
    status,
    videoType,
    position,
    link,
    reviewerId: 'undefined',
    _createdAt: dateNow,
    _updatedAt: dateNow,
  }
  const docRef = doc(reviewsCollection);
  newReview.id = docRef.id;
  await setDoc(docRef, newReview);
  const userDocRef = doc(usersCollection, userId);
  const userSnapshot = await getDoc(userDocRef);
  const user = userSnapshot.data();
  if (!reviewsLeft && user.extraReviews) {
    await updateDoc(userDocRef, { extraReviews: user.extraReviews - 1, _updatedAt: dateNow });
  }
  try {
    await sendEmailReview({
      template: EMAIL_TEMPLATE.FILM_REVIEW_SUBMISSION,
      userEmails: [user.email],
      variables: {
        userFirstName: user.firstName,
        supportEmail: SUPPORT_EMAIL,
      },
    });
  } catch (error) {
    console.log('error', error)
  }
  return newReview;
};