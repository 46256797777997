import React from "react";
import Screen from "../../components/screen";
import Text from "../../components/text";

const Help = () => {
  return (
    <Screen>
      <Text>Help</Text>
    </Screen>
  )
};

export default React.memo(Help);
