import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
  subscription: undefined,
  userPlan: undefined,
};

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState: INITIAL_STATE,
  reducers: {
    setStripeData: (state, action) => {
      return action.payload;
    },
    clearStripeData: (state, action) => {
      return cloneDeep(INITIAL_STATE);
    },
  },
});

export const { setStripeData, clearStripeData } = stripeSlice.actions;

export default stripeSlice.reducer;
