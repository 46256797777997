import { createSlice } from '@reduxjs/toolkit';

export const newslettersSlice = createSlice({
  name: 'newsletter',
  initialState: {
    lastNewslettersUpdate: undefined,
    lastTagsUpdate: undefined,
    newsletters: [],
    tags: [],
  },
  reducers: {
    setNewsletters: (state, action) => {
      const oldNewsletters = [...state.newsletters];
      const newsletters = action.payload || [];
      if (newsletters.length) {
        for (let i = 0; i < newsletters.length; i++) {
          const newsletter = newsletters[i];
          const oldNewsletterIndex = oldNewsletters.findIndex(n => n.id === newsletter.id);
          if (oldNewsletterIndex !== -1) {
            oldNewsletters.splice(oldNewsletterIndex, 1);
          }
        }
        state.newsletters = [...newsletters.filter(n => !n._deleted), ...oldNewsletters];
        const lastUpdatedAt = newsletters[0]._updatedAt;
        state.lastNewslettersUpdate = lastUpdatedAt;
      }
    },
    setNewslettersTags: (state, action) => {
      const oldTags = [...state.tags];
      const tags = action.payload || [];
      if (tags.length) {
        for (let i = 0; i < tags.length; i++) {
          const tag = tags[i];
          const oldTagIndex = oldTags.findIndex(t => t.id === tag.id);
          if (oldTagIndex !== -1) {
            oldTags.splice(oldTagIndex, 1);
          }
        }
        state.tags = [...tags.filter(t => !t._deleted), ...oldTags];
        const lastUpdatedAt = tags[0]._updatedAt;
        state.lastTagsUpdate = lastUpdatedAt;
      }
    },
  },
});

export const { setNewsletters, setNewslettersTags } = newslettersSlice.actions;

export default newslettersSlice.reducer;
