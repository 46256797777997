import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  PLANS_ORDER,
  PLANS_PERMISSIONS,
  USER_PLANS,
} from "../../../../common/constants";
import Button from "../../../components/button";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const PlanCard = ({
  title,
  current,
  planKey,
  onSelectSubscription,
  hideButton,
  hidePrice,
  showMonthly = true,
}) => {
  const userPlan = useSelector((state) => state.stripe.userPlan);
  const plan = useMemo(() => {
    if (current && userPlan) {
      return USER_PLANS[userPlan.plan];
    }
    if (planKey) {
      return USER_PLANS[planKey];
    }
  }, [userPlan, planKey, current]);

  if (!plan) return null;

  const onSelect = () => {
    onSelectSubscription(planKey);
  };

  const renderButton = () => {
    if (hideButton) return null;
    if (!userPlan)
      return (
        <Button variant="outlineTertiary" onClick={onSelect}>
          Select plan
        </Button>
      );
    if (current && userPlan.plan === plan.key) return null;
    const planOrder = PLANS_ORDER.findIndex((poKey) => poKey === plan.key);
    const currentPlanOrder = PLANS_ORDER.findIndex(
      (poKey) => poKey === userPlan.plan
    );
    if (planOrder > currentPlanOrder)
      return (
        <Button variant="danger" onClick={onSelect}>
          Upgrade
        </Button>
      );
    return (
      <Button variant="outlineTertiary" onClick={onSelect}>
        Downgrade
      </Button>
    );
  };

  const renderPrice = () => {
    if (hidePrice) return null;
    let price = plan.prices[showMonthly ? "monthly" : "yearly"][0].price;
    let timeStamp = showMonthly ? "mo" : "year";
    if (current && userPlan?.item?.price) {
      const planPrice = userPlan.item.price;
      const recurring = planPrice.recurring;
      price = planPrice.unit_amount / 100;
      timeStamp = {
        month: "mo",
        day: "day",
        year: "year",
      }[recurring.interval];
    }
    return (
      <Text variant="description">
        ${price} /{timeStamp}
      </Text>
    );
  };

  return (
    <InfoCard
      containerClassname={styles.card}
      titleContent={title ?? plan.label}
      content={
        <div className={styles.contentContainer}>
          <Text variant="description">Included in plan:</Text>
          {Object.keys(plan.permissions).map((permissionKey) => {
            const permission = PLANS_PERMISSIONS[permissionKey];
            if (!permission) return null;
            return <Text key={permissionKey}>{permission.label}</Text>;
          })}
          <div className={styles.buttonContainer}>
            {renderPrice()}
            {renderButton()}
          </div>
        </div>
      }
    />
  );
};

export default React.memo(PlanCard);
