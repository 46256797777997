import React from "react";
import { useSelector } from "react-redux";
import { userIsAdmin } from '../../../common/utils/permission';
import AdminReview from "./admin";
import ReviewerReview from "./reviewer";

const Review = () => {
  const user = useSelector(state => state.user);
  if (userIsAdmin(user)) return <AdminReview />;
  return <ReviewerReview />;
}

export default React.memo(Review);
