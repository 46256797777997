import React from "react";
import { getImagePreviewUrl } from "../../../../common/utils/storage";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const NewsletterCard = ({
  abreviatedTitle,
  image,
  _createdAt,
  onClick,
}) => {
  const imageUrl = getImagePreviewUrl("newsletter", image);
  return (
    <div className={styles.container} onClick={onClick}>
      {imageUrl ? <img src={imageUrl} alt="preview" className={styles.image} /> : null}
      <div>
        <Text variant="h3">{abreviatedTitle}</Text>
      </div>
      <div>
        <Text variant="description">{new Date(_createdAt).toLocaleDateString()}</Text>
      </div>
    </div>
  )
};

export default React.memo(NewsletterCard);
