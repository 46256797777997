import cn from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { THUMBNAIL_URL } from "../../../common/constants";
import { setVaultPlaylist } from "../../../common/redux/reducers/vault-playlist";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { replaceRoute } from "../../../common/utils/routes";
import { secondsToHms } from "../../../common/utils/time";
import Icon, { ICONS_NAME } from "../icon";
import Text from "../text";
import styles from "./styles.module.css";

const VideoCard = ({
  video,
  videosInProgress,
  videosFromSection,
  highlightVideosIds,
}) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const thumbNailImage = `${THUMBNAIL_URL.replace("{videoId}", video.videoId)}`;
  const progress = videosInProgress?.[video?.id]?.currentTime;
  const isInProgress = !!progress && progress > 0;
  const shouldDecreaseOpacity =
    !!highlightVideosIds?.length && !highlightVideosIds.includes(video.id);

  const onClick = () => {
    if (videosFromSection?.length)
      dispatch(setVaultPlaylist(videosFromSection));
    history.push(replaceRoute(DASHBOARD_ROUTES.videoDetails, ["id", video.id]));
  };

  const onToggleExpand = (evt) => {
    evt.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.lessOpacity]: shouldDecreaseOpacity,
      })}
      onClick={onClick}
    >
      <div className={styles.imageTitleContainer}>
        <img
          src={thumbNailImage}
          alt="thumbnail"
          className={cn(styles.image, { [styles.expandedImage]: expanded })}
        />
        <div className={styles.cardContent}>
          <div className={styles.cardContentText}>
            <Text variant="h3">{video.title}</Text>
            <Text variant="description">{video.description}</Text>
          </div>
          {expanded && video.tags?.length ? (
            <div className={styles.tagsContainer}>
              {video.tags.map((tag) => (
                <div className={styles.tagContainer}>
                  <Icon name={tag.icon} alt="icon" />
                  <Text
                    key={`video-${video.id}-tag-${tag.id}`}
                    className={styles.tag}
                  >
                    {tag.label}
                  </Text>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      {videosInProgress ? (
        <div className={styles.rightContentContainer}>
          <div className={styles.playContent}>
            <div className={styles.watchTimeContainer}>
              <Text variant="description">
                {isInProgress ? "Continue" : "Watch time"}:
              </Text>
              <Text variant="description">
                {isInProgress
                  ? `${secondsToHms(video.duration - progress)} left`
                  : secondsToHms(video.duration)}
              </Text>
            </div>
            <div className={styles.playIconContainer} onClick={onClick}>
              <Icon name={ICONS_NAME.playButton} alt="PlayIcon" size={40} />
            </div>
          </div>
          <div className={styles.expandIconContainer} onClick={onToggleExpand}>
            <Icon
              name={ICONS_NAME.arrow}
              className={expanded ? styles.arrowOpen : styles.arrowClosed}
              size={18}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(VideoCard);
