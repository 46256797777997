import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { getCategories, getTags, getVideo, getVideos, saveVideo } from "../../../common/services/firebase/vault";
import Button from '../../components/button';
import Input from '../../components/input';
import TagsModal from "../../components/tags-modal";
import Text from "../../components/text";
import CategoriesModal from './categories-modal';
import styles from './styles.module.css';

const NewVideo = () => {
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [showNewTagsModal, setShowNewTagsModal] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [fullInterview, setFullInterview] = useState(false);
  const [fullInterviewVideo, setFullInterviewVideo] = useState();
  const [shorts, setShorts] = useState(false);
  const [docKey, setDocKey] = useState('');
  const [title, setTitle] = useState('');
  const [videoId, setVideoId] = useState('');
  const [description, setDescription] = useState('');
  // const [extraLink, setExtraLink] = useState('');
  // const [extraLinkButtonText, setExtraLinkButtonText] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [vaultTags, setVaultTags] = useState([]);
  const [fullInterviewVideos, setFullInterviewVideos] = useState([]);
  const [duration, setDuration] = useState();
  const { id: _videoId } = useParams();
  const history = useHistory();
  const buttonDisabled = !title || !videoId || !duration;
  const categoryLabel = useMemo(() => {
    if (!category || !categories?.length) return '';
    return categories.find(c => c.id === category)?.name || "";
  }, [categories, category]);

  const loadCategories = (doLoading) => {
    if (doLoading) setLoadingCategories(true);
    getCategories({ showDeleted: false })
      .then(setCategories)
      .finally(() => setLoadingCategories(false));
  };

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setVaultTags)
      .finally(() => setLoadingTags(false));
  }, []);

  useEffect(() => {
    loadCategories(true);
  }, []);

  useEffect(() => {
    setLoadingVideos(true);
    getVideos({})
      .then(result => {
        if (result?.length) {
          setFullInterviewVideos(
            result
              .filter(v => v.id !== _videoId)
              .map(v => ({
                label: v.title,
                value: v.id
              }))
          )
        }
      })
      .finally(() => setLoadingVideos(false));
  }, [_videoId]);

  useEffect(() => {
    if (_videoId) {
      setLoadingVideo(true);
      getVideo({ videoId: _videoId })
        .then(res => {
          setDocKey(res.id);
          setTitle(res.title);
          setVideoId(res.videoId);
          setDescription(res.description);
          // setExtraLink(res.extraLink);
          // setExtraLinkButtonText(res.extraLinkButtonText);
          setCategory(res.category);
          setTags(res.tags || []);
          setDuration(res.duration);
          setFeatured(!!res.featured);
          setShorts(!!res.shorts);
          setFullInterview(res.fullInterview);
          setFullInterviewVideo(res.fullInterviewVideo);
        })
        .finally(() => setLoadingVideo(false));
    } else {
      setLoadingVideo(false);
    }
  }, [_videoId]);

  const onVideoReady = (event) => {
    setDuration(undefined);
    const duration = event.target.getDuration();
    if (!duration) {
      return alert('Invalid Video');
    }
    setDuration(duration);
  }

  const onSave = async () => {
    await saveVideo({
      id: docKey,
      videoId,
      title,
      description,
      // extraLink,
      // extraLinkButtonText,
      category,
      tags,
      duration,
      featured,
      shorts,
      fullInterview: fullInterview || !!fullInterviewVideo?.value,
      fullInterviewVideo: fullInterviewVideo?.value,
    });
    history.goBack();
  }

  const onRemoveTag = (index) => {
    setTags(prev => {
      prev.splice(index, 1);
      return [...prev];
    })
  };

  const onSelectCategory = (cat) => {
    setCategory(cat.id);
    setShowNewCategoryModal(false);
  };

  const onSelectTags = (newTags) => {
    setTags(newTags);
    setShowNewTagsModal(false);
  }

  const toggleShorts = () => {
    setShorts(prev => {
      const newPrev = !prev;
      if (!newPrev) setFullInterviewVideo(undefined);
      return newPrev;
    })
  }

  if (loadingVideo || loadingTags || loadingCategories || loadingVideos) return <Text>Loading...</Text>;

  return (
    <div className={styles.container}>
      <Text className={styles.title}>{docKey ? 'Edit' : 'New'} Video</Text>
      <Input className={styles.input} label="Title" value={title} onChange={evt => setTitle(evt.target.value)} />
      <Input className={styles.input} label="Video Id" value={videoId} onChange={evt => setVideoId(evt.target.value)} />
      <Input className={styles.input} label="Description" value={description} onChange={evt => setDescription(evt.target.value)} textArea />
      {/* <Input className={styles.input} label="Extra Link" value={extraLink} onChange={evt => setExtraLink(evt.target.value)} />
      <Input className={styles.input} label="Extra Link Button Text" value={extraLinkButtonText} onChange={evt => setExtraLinkButtonText(evt.target.value)} /> */}
      <div className={styles.categoryInputContainer}>
        <Input className={styles.input} label="Category (if not in Feature, Shorts or Full Interviews)" value={categoryLabel} disabled />
        <Button onClick={() => setShowNewCategoryModal(true)}>
          Select Category
        </Button>
      </div>
      <div className={styles.tagInputContainer}>
        <Button onClick={() => setShowNewTagsModal(true)}>
          Select Tags
        </Button>
      </div>
      {tags.length ? (
        <div className={styles.tagsContainer}>
          {tags.map((tagId, index) => {
            const tag = vaultTags.find(vt => vt.id === tagId);
            if (!tag) return null;
            return (
              <div key={tagId} className={styles.tag}>
                <Text>{tag.label}</Text>
                <div className={styles.removeTagContainer} onClick={() => onRemoveTag(index)}>
                  <Text className={styles.removeTag}>X</Text>
                </div>
              </div>
            )
          })}
        </div>
      ) : null}
      <div className={styles.checkBoxContainer}>
        <input type="checkbox" checked={featured} onChange={() => setFeatured(prev => !prev)} />
        <Text>Featured</Text>
      </div>
      {/* <div className={styles.checkBoxContainer}>
        <input type="checkbox" checked={fullInterview || !!fullInterviewVideo?.value} onChange={() => setFullInterview(prev => !prev)} />
        <Text>Full Interview</Text>
      </div>
      <div className={styles.checkBoxContainer}>
        <input type="checkbox" checked={shorts} onChange={toggleShorts} />
        <Text>Shorts</Text>
      </div> */}
      {/* {shorts ? (
        <Dropdown
          className={styles.input}
          options={fullInterviewVideos}
          placeholder="Select the full interview video..."
          value={fullInterviewVideo}
          isClearable
          onChange={option => setFullInterviewVideo(option)}
        />
      ) : null} */}
      {videoId?.length ? (
        <div className={styles.youtubeContainer}>
          <YouTube videoId={videoId} opts={{ playerVars: { start: 0 } }} onReady={onVideoReady} />
        </div>
      ) : null}
      <div className={styles.buttonContainer}>
        <Button variant="outline" className={styles.button} disabled={buttonDisabled} onClick={onSave}>
          Save
        </Button>
      </div>
      <CategoriesModal
        visible={showNewCategoryModal}
        onClose={() => setShowNewCategoryModal(false)}
        categories={categories}
        onSelectCategory={onSelectCategory}
      />
      <TagsModal
        visible={showNewTagsModal}
        onClose={() => setShowNewTagsModal(false)}
        tags={vaultTags}
        selectedTags={tags}
        onSelectTags={onSelectTags}
      />
    </div>
  )
}

export default React.memo(NewVideo);
