import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { collection, doc, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable as httpsCallableFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase();
export const auth = getAuth();
export const firestore = getFirestore();
export const functions = getFunctions();
export const storage = getStorage(app);
export const httpsCallable = (functionName) => httpsCallableFunctions(functions, functionName);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);

const testCollection = collection(firestore, "TEST");
export const generateFirebaseKey = () => doc(testCollection).id;
