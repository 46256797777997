import { createSlice } from '@reduxjs/toolkit';

export const vaultPlaylistSlice = createSlice({
  name: 'vault-playlist',
  initialState: [],
  reducers: {
    setVaultPlaylist: (state, action) => {
      return action.payload || [];
    },
    clearVaultPlaylist: () => {
      return null;
    },
  },
});

export const { setVaultPlaylist, clearVaultPlaylist } = vaultPlaylistSlice.actions;

export default vaultPlaylistSlice.reducer;
