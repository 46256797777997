import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useGoBack = (cb) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        cb?.();
      }
    });

    return () => {
      unlisten();
    };
  }, [cb, history]);

  return null;
};
