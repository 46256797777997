import React, { useEffect, useRef, useState } from "react";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import Filter from "../../../assets/icons/filter.svg";
import FilterWhite from "../../../assets/icons/filter-white.svg";
import { useFilter } from "../../../context/filter";
import {
  getContainerClassName,
  getTextClassName,
} from "../../../utils/dark-mode";
import Popup from "../../popup";
import FilterSection from "../filter-section";
import Distance from "./distance";
import Division from "./division";
import State from "./state";
import Status from "./status";
import styles from "./styles.module.css";
import Temperature from "./temperature";

export const SchoolFilterPopup = () => {
  const [filtersOpened, setFiltersOpened] = useState({ division: true });

  const onToggleFilterSection = (filterName) => {
    setFiltersOpened((prev) => {
      if (prev[filterName]) delete prev[filterName];
      else prev[filterName] = true;
      return { ...prev };
    });
  };
  return (
    <>
      <FilterSection
        title="Division"
        isOpen={filtersOpened.division}
        onToggle={() => onToggleFilterSection("division")}
      >
        <Division />
      </FilterSection>
      <FilterSection
        title="Distance"
        isOpen={filtersOpened.distance}
        onToggle={() => onToggleFilterSection("distance")}
      >
        <Distance />
      </FilterSection>
      <FilterSection
        title="Temperature"
        isOpen={filtersOpened.temperature}
        onToggle={() => onToggleFilterSection("temperature")}
      >
        <Temperature />
      </FilterSection>
      <FilterSection
        title="Status"
        isOpen={filtersOpened.status}
        onToggle={() => onToggleFilterSection("status")}
      >
        <Status />
      </FilterSection>
      <FilterSection
        title="State"
        isOpen={filtersOpened.state}
        onToggle={() => onToggleFilterSection("state")}
        isState
      >
        <State />
      </FilterSection>
    </>
  );
};

const SchoolFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedDivision, showFilters, setShowFilters, isDark } = useFilter();
  const ref = useRef(null);
  onClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen) {
      setShowFilters(true);
    }
  }, [isOpen]);

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const renderFilter = () => {
    if (isOpen || !showFilters) return null;
    return (
      <span className={getTextClassName(styles.filteringText, isDark)}>
        Filtering:{" "}
        <span className={getTextClassName(styles.filteringContentText, isDark)}>
          {selectedDivision}
        </span>
      </span>
    );
  };

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={getContainerClassName(styles.contentContainer, isDark)}
        onClick={toggleOpen}
      >
        <div className={getTextClassName(styles.filterText, isDark)}>
          {isOpen ? "Close" : "Filter"}
          {renderFilter()}
        </div>
        {isOpen ? (
          <img
            src={isDark ? Filter : FilterWhite}
            width={20}
            height={13}
            alt="Up"
          />
        ) : (
          <img
            src={isDark ? Filter : FilterWhite}
            width={20}
            height={13}
            alt="filter"
          />
        )}
      </div>
      {isOpen && (
        <Popup
          className={styles.popupContainer}
          expandOnBottom
          onClose={() => setIsOpen(false)}
        >
          <SchoolFilterPopup />
        </Popup>
      )}
    </div>
  );
};

export default React.memo(SchoolFilter);
