import React, { useEffect, useState } from "react";
import { getSchool } from "../../../common/services/firebase/database";
import CloseIcon from "../../assets/icons/filter-arrow-left-white.svg";
import OpenIcon from "../../assets/icons/filter-arrow-right-white.svg";
import HandleIcon from "../../assets/icons/handle.svg";
import { useFilter } from "../../context/filter";
import mobileStyles from "./mobile-styles.module.css";
import SchoolContent from "./school-content";
import styles from "./styles.module.css";
import classNames from "classnames";

const SchoolDetails = ({ schoolName, onCenterLocation, onClose, isDemo }) => {
  const [loading, setLoading] = useState(false);
  const [canShowCloseIcon, setCanShowCloseIcon] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(!!schoolName?.length);
  const [school, setSchool] = useState();
  const [halfOpenCloseAnimation, setHalfOpenCloseAnimation] = useState();
  const { schoolDirection } = useFilter();
  const isOpen = !!schoolName?.length;

  const onToggleInternalClose = () => {
    setIsOpenMobile((prev) => {
      if (prev) return false;
      if (isOpen) return true;
      return prev;
    });
  };

  useEffect(() => {
    if (schoolName?.length) {
      setHalfOpenCloseAnimation("halfCloseAnimation");
      setCanShowCloseIcon(false);
      setTimeout(() => {
        setHalfOpenCloseAnimation("halfOpenAnimation");
        setTimeout(() => {
          setCanShowCloseIcon(true);
        }, 600);
      }, 300);
    }
    return () => {
      setCanShowCloseIcon(false);
      setHalfOpenCloseAnimation(false);
    };
  }, [schoolName]);

  useEffect(() => {
    if (schoolName?.length) {
      setIsOpenMobile(true);
      setLoading(true);
      getSchool(schoolName)
        .then((result) => {
          setSchool(result);
          onCenterLocation(result.lat, result.lng);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolName]);

  useEffect(() => {
    if (schoolDirection && isOpen) {
      setIsOpenMobile(false);
    }
  }, [schoolDirection, isOpen]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={`${styles.contentContainer} ${
            isOpen ? styles.slideOpen : styles.slideClose
          } ${styles[halfOpenCloseAnimation]}`}
        >
          <SchoolContent isDemo={isDemo} school={school} loading={loading} />
          <div
            className={classNames(
              styles.closeButton2,
              isOpen ? styles.closeButton2Transform : null
            )}
            onClick={onClose}
          >
            <img
              className={classNames(
                styles.closeButton2Img,
                isOpen ? styles.closeButton2ImgTransform : null
              )}
              src={isOpen ? CloseIcon : OpenIcon}
              width={22}
              height={40}
              alt="close"
            />
          </div>
        </div>
        {/* {isOpen && canShowCloseIcon && (
          <div className={styles.closeButton} onClick={onClose}>
            <img src={CloseIcon} width={22} height={40} alt="close" />
          </div>
        )} */}
      </div>
      <div className={mobileStyles.container}>
        <div
          className={`${mobileStyles.contentContainer} ${
            isOpenMobile ? mobileStyles.slideOpen : mobileStyles.slideClose
          }`}
        >
          <div
            onClick={onToggleInternalClose}
            className={mobileStyles.handleContainer}
          >
            <img src={HandleIcon} alt="handle-icon" height={30} width={40} />
          </div>
          <div className={mobileStyles.content}>
            <SchoolContent school={school} loading={loading} isDemo={isDemo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SchoolDetails);
