import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { VictoryLabel, VictoryPie } from "victory";
import Text from "../../components/text";
import { POSITION, VIDEO_TYPE } from "../../constants";
import { ATHLETICISM_SHOWN, ENGAGEMENT, HIGHLIGHT_QUAILITY, POSITION_SPECIFIC_SKILLS } from '../../constants/feedback';
import styles from './styles.module.css';

const getScoreFeedback = (q, score) => {
  let notes = "";
  let isPositive;
  if (q.equalTo !== undefined && score === q.equalTo) {
    notes = q.equalToNotes;
    isPositive = false;
  } else if (q.equalToOrLess !== undefined && score <= q.equalToOrLess) {
    notes = q.equalToOrLessNotes;
    isPositive = false;
  } else if (q.equalToOrGreater !== undefined && score <= q.equalToOrGreater) {
    notes = q.equalToOrGreaterNotes;
    isPositive = true;
  }
  return { notes, isPositive };
}

const calcFeedbackSectionScore = (feedbackSection, feedback) => {
  const section = {
    key: feedbackSection.key,
    overallScore: 0,
    questions: {},
  };
  const questions = feedbackSection.questions || [];
  const blueQuestion = questions.find(q => q.blueQuestion && q.active);
  const blueQuestionScore = feedback[blueQuestion?.key]?.value;
  for (let i = 0; i < questions.length; i++) {
    const q = questions[i];
    if (q.active) {
      const key = q.key;
      let score = feedback[q.key]?.value;
      const feedbackNote = feedback[q.key]?.note;
      if (blueQuestion && blueQuestionScore === 0) {
        const questionScore = { key, score: 1 };
        section.overallScore = section.overallScore + 1;
        section.questions[key] = questionScore;
      } else {
        if (score !== undefined) {
          score += 1;
          const questionScore = { key, score, feedbackNote, ...getScoreFeedback(q, score) };
          section.overallScore = section.overallScore + score;
          section.questions[key] = questionScore;
        }
      }
    }
  }
  return section;
}

const LabelValue = ({ label, value }) => {
  return (
    <div>
      <span className={styles.label}>{label}: </span>
      <span>{value ?? "-"}</span>
    </div>
  )
}

const FeedbackSection = ({ feedbackMap, scores, value }) => {
  const section = feedbackMap.find(s => s.key === value);
  const sectionScores = Object.values(scores.sections[value].questions);
  const positiveScores = sectionScores.filter(ss => ss.isPositive && ss.notes);
  const negativeScores = sectionScores.filter(ss => !ss.isPositive && ss.notes);
  if (!positiveScores.length && !negativeScores.length) return null;
  return (
    <div>
      <div className={styles.sectionTitleContainer}>
        <span className={styles.sectionTitle}>{section.title}</span>
      </div>
      {positiveScores.length ? (
        <div>
          <span className={styles.subTitle}>Included:</span>
          <ul className={styles.notesContainer}>
            {positiveScores.map(sc => (
              <li>
                <span className={styles.note}>{sc.notes}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {negativeScores.length ? (
        <div>
          <span className={styles.subTitle}>Missing or Needs work:</span>
          <ul className={styles.notesContainer}>
            {negativeScores.map(sc => (
              <li>
                <span className={styles.note}>{sc.notes}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
};

const ReviewFeedback = () => {
  const user = useSelector(state => state.user);
  const { reviews, reviewsStructure } = useSelector(state => state.reviews);
  const { id: _reviewId } = useParams();

  const {
    review,
    feedbackMap,
  } = useMemo(() => {
    const review = (reviews || []).find(r => r.id === _reviewId);
    const feedbackMap = reviewsStructure.find(r => r.position === review.position && (review.videoType === VIDEO_TYPE.technicalFilm.key ? r.isTechnical : true))?.sections;
    return { review, feedbackMap };
  }, [_reviewId, reviews, reviewsStructure]);

  const scores = useMemo(() => {
    const rFeedback = review?.feedback;
    if (!feedbackMap || !rFeedback) return undefined;
    const newScore = {
      overallScore: 0,
      sections: {},
    };
    for (let i = 0; i < feedbackMap.length; i++) {
      const feedbackSection = feedbackMap[i];
      if (feedbackSection.active) {
        const score = calcFeedbackSectionScore(feedbackSection, rFeedback);
        newScore.overallScore = newScore.overallScore + score.overallScore;
        newScore.sections[score.key] = score;
      }
    }
    return newScore;
  }, [review, feedbackMap]);

  const [leftSideSections, rightSideSections, allSections] = useMemo(() => {
    const totalSections = feedbackMap?.length;
    if (!totalSections) return [[], [], []];
    const sectionsTitles = feedbackMap.map(section => section.key);
    const totalLeftSide = Math.floor(totalSections / 2) + 1;
    return [sectionsTitles.filter((a, index) => index < totalLeftSide), sectionsTitles.filter((a, index) => index >= totalLeftSide), sectionsTitles];
  }, [feedbackMap]);

  if (!scores || !feedbackMap || !review) return <Text>Review not found...</Text>;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <span className={styles.userNameTitle}>{user.firstName}'s Film Review</span>
        <div className={styles.topInfoContainer}>
          <div className={styles.auditInfoContainer}>
            <span className={styles.title}>Audit info</span>
            <LabelValue label="Name" value={`${user.firstName} ${user.lastName}`} />
            <LabelValue label="Username" value={user.username} />
            <LabelValue label="Position" value={POSITION[review.position].label} />
            {/* <LabelValue label="Year" value="Junior" /> */}
            <LabelValue label="Type" value={VIDEO_TYPE[review.videoType].label} />
          </div>
          <div className={styles.resultsOverviewContainer}>
            <div className={styles.title}>Results Overview</div>
            <div className={styles.resultsOverviewInnerContainer}>
              <div className={styles.chatContainer}>
                <svg viewBox="0 0 400 400">
                  <VictoryPie
                    standalone={false}
                    width={400}
                    height={400}
                    innerRadius={({ index }) => {
                      if (index === 0) return 130;
                      return 145;
                    }}
                    colorScale={["red", "#AAA"]}
                    data={[
                      { x: " ", y: scores.overallScore, data: "test" },
                      { x: " ", y: 100 - scores.overallScore },
                    ]}
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    style={{ fontSize: 100 }}
                    x={200} y={200}
                    text={scores.overallScore}
                  />
                </svg>
                <span className={styles.overallScore}>Overall Score</span>
              </div>
              <div>
                <div><span style={{ color: "red", marginRight: 10 }}>{scores.sections[HIGHLIGHT_QUAILITY]?.overallScore || "-"}</span>Highlight Quality</div>
                <div><span style={{ color: "red", marginRight: 10 }}>{scores.sections[POSITION_SPECIFIC_SKILLS]?.overallScore || "-"}</span>Position-Specific Skills</div>
              </div>
              <div>
                <div><span style={{ color: "red", marginRight: 10 }}>{scores.sections[ENGAGEMENT]?.overallScore || "-"}</span>Engagement</div>
                <div><span style={{ color: "red", marginRight: 10 }}>{scores.sections[ATHLETICISM_SHOWN]?.overallScore || "-"}</span>Athleticism shown</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.leftSideContainer}>
            <span className={styles.title}>Your Review</span>
            <div className={styles.sectionTitleContainer}>
              <span className={styles.sectionTitle}>General Notes</span>
            </div>
            <div>
              <span>By interviewing college coaches and athletes we have compiled the key components that must be included in your film.</span>
            </div>
            <div>
              {leftSideSections.map(fok => {
                return <FeedbackSection key={`feedback-section-${fok}`} value={fok} feedbackMap={feedbackMap} scores={scores} />
              })}
            </div>
          </div>
          <div className={styles.rightSideContainer}>
            {rightSideSections.map(fok => {
              return <FeedbackSection key={`feedback-section-${fok}`} value={fok} feedbackMap={feedbackMap} scores={scores} />
            })}
            <div className={styles.titleContainer}>
              <span className={styles.title}>How to improve</span>
            </div>
            <div>
              <span>This report analyzes your film and indentifies areas for improvement. By incorporating these key elements, you can create a stronger film that showcases your full potential. Let's dive into the specific actions you can take to improve your overall score.</span>
            </div>
            <ul className={styles.howToImproveListContainer}>
              {allSections.map(fok => {
                const feedbackNotes = Object.values(scores.sections[fok].questions);
                return feedbackNotes.map(fb => {
                  if (!fb.feedbackNote?.length) return null;
                  return <li key={fb.key}>{fb.feedbackNote}</li>
                })
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

export default React.memo(ReviewFeedback);
