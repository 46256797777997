import React from "react";
import Text from "../../../components/text";
import ContentRenderer from "../content-renderer";
import styles from './styles.module.css';

const BasicRulesContent = ({ content }) => {
  return (
    <div>
      <Text className={styles.title} variant="h2">Basic Rules</Text>
      <div className={styles.content}>
        {content.map((c, index) => <ContentRenderer key={index} content={c} />)}
      </div>
    </div>
  )
}

export default React.memo(BasicRulesContent);
