import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "../../../../common/redux/reducers/app-state";
import Icon, { ICONS_NAME } from "../../icon";
import styles from './styles.module.css';

const ToggleOpen = () => {
  const { navBarOpen: open } = useSelector(state => state.appState);
  const dispatch = useDispatch();
  const toggleOpen = () => {
    dispatch(setAppState({
      navBarOpen: !open
    }))
  }
  return (
    <div className={styles.buttonContainer} onClick={toggleOpen}>
      <Icon name={ICONS_NAME.arrow} size={14} alt="arrow" className={open ? styles.open : styles.closed} />
    </div>
  )
};

export default React.memo(ToggleOpen);
