import React from "react";
import Button from "../../../components/button";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const CategoryCard = ({
  name,
  onClick,
  onDelete,
}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <Text>{name}</Text>
      <Button onClick={onDelete}>
        Delete
      </Button>
    </div>
  )
};

export default React.memo(CategoryCard);
