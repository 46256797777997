import React from "react";
import BaseText from '../../../common/components/text';
import Styles from "./styles.module.css"

const Text = (props) => {
  return (
    <div className={Styles.container}><BaseText {...props} /></div>
  );
};

export default React.memo(Text);