import { clearReviews } from "../redux/reducers/reviews";
import { clearStripeData } from "../redux/reducers/stripe";
import { clearUser } from "../redux/reducers/user";
import { clearVaultPlaylist } from "../redux/reducers/vault-playlist";
import { clearVaultSection } from "../redux/reducers/vault-section";
import { clearVideosInProgress } from "../redux/reducers/videos-in-progress";
import store from "../redux/store";

export const logout = () => {
  store.dispatch(clearUser(null));
  store.dispatch(clearReviews());
  store.dispatch(clearVaultSection());
  store.dispatch(clearVaultPlaylist());
  store.dispatch(clearVideosInProgress());
  store.dispatch(clearStripeData());
}

export const isValidEmail = (email) => {
  if (!email?.length) return false;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}