import cn from 'classnames';
import React from "react";
import Text from "../text";
import styles from './styles.module.css';

const CheckBox = ({
  children,
  className,
  inputClassName,
  labelClassName,
  ...props
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <input className={cn(inputClassName)} type="checkbox" {...props} />
      <Text className={cn(styles.label, labelClassName)}>{children}</Text>
    </div>
  )
}

export default React.memo(CheckBox);
