import React, { useState } from "react";
import { useFilter } from "../../context/filter";
import {
  getClassName,
  getContainerClassName,
  getSecondaryContainerClassName,
  getTextClassName,
} from "../../utils/dark-mode";
import styles from "./styles.module.css";

const ExpandButton = ({ isExpanded, isDark, onClick }) => (
  <div
    className={getTextClassName(styles.expandButton, isDark)}
    onClick={onClick}
  >
    {isExpanded ? "Shrink" : "Expand"}
  </div>
);

const FilterButton = ({ onClick, isDark }) => (
  <div
    className={getTextClassName(styles.expandButton, isDark)}
    onClick={onClick}
  >
    Filter
  </div>
);

const Popup = ({
  children,
  className,
  expandOnTop,
  expandOnBottom,
  onClose,
  hideCloseButton,
  onOpenFilter,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isDark } = useFilter();

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return (
    <div
      className={`${getContainerClassName(
        styles.container,
        isDark,
        "no-hover"
      )} ${isExpanded ? styles.expandedContainer : ""} ${className}`}
    >
      <div
        className={getSecondaryContainerClassName(
          styles.header,
          isDark,
          "no-hover"
        )}
      >
        <div>
          {expandOnTop && (
            <ExpandButton onClick={toggleExpand} isDark={isDark} />
          )}
        </div>
        <div>
          {!hideCloseButton && (
            <div
              className={getTextClassName(styles.closeButton, isDark)}
              onClick={onClose}
            >
              X
            </div>
          )}
        </div>
      </div>
      <div
        className={`${styles.contentContainer} ${
          isExpanded ? styles.expandedContentContainer : ""
        }`}
      >
        {children}
      </div>
      <div
        className={getSecondaryContainerClassName(
          styles.footer,
          isDark,
          "no-hover"
        )}
      >
        {(!onOpenFilter || !expandOnBottom) && <div />}
        {expandOnBottom && (
          <ExpandButton onClick={toggleExpand} isDark={isDark} />
        )}
        {onOpenFilter && (
          <FilterButton onClick={onOpenFilter} isDark={isDark} />
        )}
      </div>
    </div>
  );
};

export default React.memo(Popup);
