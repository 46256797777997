import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FILM_REVIEW_PRODUCT, USER_PLANS } from "../../../common/constants";
import { setRedirectData } from "../../../common/redux/reducers/app-redirect";
import { BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import { httpsCallable } from "../../../common/services/firebase";
import { useQuery } from "../../../common/utils/routes";
import Loading from "../../../Dashboard/components/loading";
import check from "../../assets/icons/check.svg";
import {
  default as CloseIcon,
  default as x,
} from "../../assets/icons/close.svg";
import Heading1 from "../../components/.heading1";
import Heading2 from "../../components/.heading2";
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from "../../components/button";
import Carousel from "../../components/carousel";
import Styles from "../plans/styles.module.css";
import pageStyles from "../styles.module.css";

const getPromotionCode = httpsCallable("getPromotionCodeFunc");

const calcDiscount = (value = 0, discountCoupon) => {
  const coupon = discountCoupon?.coupon;
  let newValue = value;
  if (coupon.amount_off) newValue = value - coupon.amount_off / 100;
  if (coupon.percent_off) newValue = value - value * (coupon.percent_off / 100);
  return newValue.toFixed(2);
};

const calcMonthly = (price) => {
  return (price / 12).toFixed(2);
};

const PromotionCodeInput = ({ loading, value, onChange }) => {
  return (
    <input
      id="discountInput"
      placeholder="Discount Code"
      className={Styles.discountInput}
      value={value}
      onChange={onChange}
    />
  );
};
const Plans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMonthlyPrice, setIsMonthlyPrice] = useState(true);
  const [promotionCode, setPromotionCode] = useState("");
  const [discountCoupon, setDiscountCoupon] = useState();
  const [loadingCoupon, setLoadingCoupon] = useState(false);

  const query = useQuery();
  let selectedPlan = query.get("plan");
  if (query.get("plan") === null) {
    selectedPlan = USER_PLANS.ATHLETE.key;
  }

  let startingCard = 2;
  if (selectedPlan === USER_PLANS.SCHOLAR.key) {
    startingCard = 1;
  }
  if (selectedPlan === USER_PLANS.COMPETITOR.key) {
    startingCard = 3;
  }

  useEffect(() => {
    const planColumns = document.getElementsByClassName(Styles.plansColumn);
    try {
      const selectedPlanElement = document.getElementById(selectedPlan);

      selectedPlanElement.classList.add(Styles.preSelected);

      for (let col of planColumns) {
        col.addEventListener("mouseenter", () => {
          selectedPlanElement.classList.remove(Styles.preSelected);
        });
        col.addEventListener("mouseleave", () => {
          selectedPlanElement.classList.add(Styles.preSelected);
        });
      }
    } catch (e) {
      console.log("Error preselecting plan. Check inputted plan id");
    }
  }, []);

  const onSelectPlan = (planUserSelected) => {
    dispatch(
      setRedirectData({
        route: DASHBOARD_ROUTES.plans,
        data: planUserSelected,
      })
    );
    history.push(BASE_ROUTES.login);
  };

  const onBuyFilmReview = () => {
    dispatch(
      setRedirectData({
        route: DASHBOARD_ROUTES.plans,
        data: FILM_REVIEW_PRODUCT.key,
      })
    );
    history.push(BASE_ROUTES.login);
  };

  const onSearchPromotionCode = _.debounce(async (value) => {
    try {
      if (!value.length) return setDiscountCoupon(undefined);
      setLoadingCoupon(true);
      const resp = await getPromotionCode(value);
      if (resp.data) {
        setDiscountCoupon(resp.data);
      }
    } catch (error) {
      setDiscountCoupon(undefined);
      console.log("error", error);
    }
    setLoadingCoupon(false);
  }, 750);

  const onPromotionCodeChange = (event) => {
    const { value } = event.target;
    setPromotionCode(value);
    onSearchPromotionCode(value);
  };

  const checkPrice = (prices) => {
    if (prices) {
      return prices[isMonthlyPrice ? "monthly" : "yearly"][0].price;
    }
    return "";
  };

  const getPlanCards = (onClick, discountCoupon, checkPrice) => [
    <div className={Styles.carouselPlansColumn}>
      <div className={Styles.columnTitle}>
        <Heading1 variant="plans">{USER_PLANS.SCHOLAR.label}</Heading1>
        <Text
          style={
            discountCoupon
              ? { color: "#707070", textDecoration: "line-through" }
              : {}
          }
        >
          ${checkPrice(USER_PLANS.SCHOLAR.prices)}{" "}
          <span style={{ color: "#707070" }}>
            {isMonthlyPrice ? "/mo" : "/year"}
          </span>
        </Text>
        {discountCoupon ? (
          <Text>
            $
            {calcDiscount(
              checkPrice(USER_PLANS.SCHOLAR.prices),
              discountCoupon
            )}
            <span style={{ color: "#b50c0c" }}>
              {" "}
              {isMonthlyPrice ? "/mo" : "/year"}
            </span>
          </Text>
        ) : null}
        {!isMonthlyPrice ? (
          <Text
            style={{
              marginTop: "-6px",
              color: "var(--darkText)",
            }}
          >
            $
            {calcMonthly(
              discountCoupon
                ? checkPrice(USER_PLANS.SCHOLAR.prices, discountCoupon)
                : checkPrice(USER_PLANS.SCHOLAR.prices)
            )}
            <span> /mo value</span>
          </Text>
        ) : null}
      </div>
      <div className={Styles.carouselChecklist}>
        <div>
          <img src={check} />
          <Text>Full Database Access</Text>
        </div>
        <div>
          <img src={check} />
          <Text>Weekly Newsletter</Text>
        </div>
        <div style={{ opacity: "20%" }}>
          <img src={x} />
          <Text>Film Reviews</Text>
        </div>
        <div style={{ opacity: "20%" }}>
          <img src={x} />
          <Text>Vault Access</Text>
        </div>
      </div>
      <div className={Styles.plansButton}>
        <Button
          className={Styles.carouselSelectButton}
          onClick={() => onClick(USER_PLANS.SCHOLAR.key)}
        >
          Select
        </Button>
      </div>
    </div>,
    <div className={Styles.carouselPlansColumn}>
      <div className={Styles.columnTitle}>
        <Heading1 variant="plans">{USER_PLANS.ATHLETE.label}</Heading1>
        <Text
          style={
            discountCoupon
              ? { color: "#707070", textDecoration: "line-through" }
              : {}
          }
        >
          ${checkPrice(USER_PLANS.ATHLETE.prices)}{" "}
          <span style={{ color: "#707070" }}>
            {isMonthlyPrice ? "/mo" : "/year"}
          </span>
        </Text>
        {discountCoupon ? (
          <Text>
            $
            {calcDiscount(
              checkPrice(USER_PLANS.ATHLETE.prices),
              discountCoupon
            )}
            <span style={{ color: "#b50c0c" }}>
              {" "}
              {isMonthlyPrice ? "/mo" : "/year"}
            </span>
          </Text>
        ) : null}
        {!isMonthlyPrice ? (
          <Text
            style={{
              marginTop: "-6px",
              color: "var(--darkText)",
            }}
          >
            $
            {calcMonthly(
              discountCoupon
                ? checkPrice(USER_PLANS.ATHLETE.prices, discountCoupon)
                : checkPrice(USER_PLANS.ATHLETE.prices)
            )}
            <span> /mo value</span>
          </Text>
        ) : null}
      </div>
      <div className={Styles.carouselChecklist}>
        <div>
          <img src={check} />
          <Text>Full Database Access</Text>
        </div>
        <div>
          <img src={check} />
          <Text>Weekly Newsletter</Text>
        </div>
        <div>
          <img src={check} />
          <Text>3 Film Reviews</Text>
        </div>
        <div style={{ opacity: "20%" }}>
          <img src={x} />
          <Text>Vault Access</Text>
        </div>
      </div>
      <div className={Styles.plansButton}>
        <Button
          className={Styles.carouselSelectButton}
          onClick={() => onClick(USER_PLANS.ATHLETE.key)}
        >
          Select
        </Button>
      </div>
    </div>,
    <div className={Styles.carouselPlansColumn}>
      <div className={Styles.columnTitle}>
        <Heading1 variant="plans">{USER_PLANS.COMPETITOR.label}</Heading1>
        <Text
          style={
            discountCoupon
              ? { color: "#707070", textDecoration: "line-through" }
              : {}
          }
        >
          ${checkPrice(USER_PLANS.COMPETITOR.prices)}{" "}
          <span style={{ color: "#707070" }}>
            {isMonthlyPrice ? "/mo" : "/year"}
          </span>
        </Text>
        {discountCoupon ? (
          <Text>
            $
            {calcDiscount(
              checkPrice(USER_PLANS.COMPETITOR.prices),
              discountCoupon
            )}
            <span style={{ color: "#b50c0c" }}>
              {" "}
              {isMonthlyPrice ? "/mo" : "/year"}
            </span>
          </Text>
        ) : null}
        {!isMonthlyPrice ? (
          <Text
            style={{
              marginTop: "-6px",
              color: "var(--darkText)",
            }}
          >
            $
            {calcMonthly(
              discountCoupon
                ? checkPrice(USER_PLANS.COMPETITOR.prices, discountCoupon)
                : checkPrice(USER_PLANS.COMPETITOR.prices)
            )}
            <span> /mo value</span>
          </Text>
        ) : null}
      </div>
      <div className={Styles.carouselChecklist}>
        <div>
          <img src={check} />
          <Text>Full Database Access</Text>
        </div>
        <div>
          <img src={check} />
          <Text>Weekly Newsletter</Text>
        </div>
        <div>
          <img src={check} />
          <Text>Unlimited Film Reviews</Text>
        </div>
        <div>
          <img src={check} />
          <Text>Full Vault Access</Text>
        </div>
      </div>
      <div className={Styles.plansButton}>
        <Button
          className={Styles.carouselSelectButton}
          onClick={() => onClick(USER_PLANS.COMPETITOR.key)}
        >
          Select
        </Button>
      </div>
    </div>,
  ];

  return (
    <div className={pageStyles.centerPage}>
      <div style={{ marginBottom: "40px" }}>
        <Title>Plans & Pricing</Title>
      </div>

      <div className={Styles.priceToggleContainerHolder}>
        <label className={Styles.priceToggleContainer}>
          <input
            className={Styles.priceToggleInput}
            type="checkbox"
            onClick={() => setIsMonthlyPrice(isMonthlyPrice ? false : true)}
          />

          <span className={Styles.priceToggleSlider} />

          <div className={Styles.priceToggleText}>
            <Text>Monthly</Text>
          </div>
          <div
            className={classNames(
              Styles.priceToggleText,
              Styles.yearlyPriceToggleText
            )}
          >
            <Text>Yearly</Text>
          </div>
        </label>
      </div>

      <div className={Styles.plansCarousel}>
        <div className={Styles.discountInputContainer}>
          <PromotionCodeInput
            loading={loadingCoupon}
            value={promotionCode}
            onChange={onPromotionCodeChange}
          />
          {loadingCoupon ? (
            <div className={Styles.loadingContainer}>
              <Loading />
            </div>
          ) : null}
          <div
            className={classNames(
              Styles.discountCancelButton,
              promotionCode ? Styles.discountCancelButtonActive : null
            )}
            onClick={() => {
              setPromotionCode("");
              onSearchPromotionCode("");
            }}
          >
            <img src={CloseIcon} width={10} height={10} />
          </div>
        </div>
        <Carousel
          startingCard={startingCard}
          cardsList={getPlanCards(onSelectPlan, discountCoupon, checkPrice)}
        />
      </div>

      <div className={Styles.plansContainer}>
        <div className={Styles.featuresContainer}>
          <div className={Styles.featuresTitle}>
            <Heading2 variant="plansDark">Features</Heading2>
          </div>

          <div className={Styles.discountInputContainer}>
            <PromotionCodeInput
              loading={loadingCoupon}
              value={promotionCode}
              onChange={onPromotionCodeChange}
            />
            {loadingCoupon ? (
              <div className={Styles.loadingContainer}>
                <Loading />
              </div>
            ) : null}
            <div
              className={classNames(
                Styles.discountCancelButton,
                promotionCode ? Styles.discountCancelButtonActive : null
              )}
              onClick={() => {
                setPromotionCode("");
                onSearchPromotionCode("");
              }}
            >
              <img src={CloseIcon} width={10} height={10} />
            </div>
          </div>

          <div
            className={classNames(
              Styles.featuresList,
              discountCoupon && !isMonthlyPrice
                ? Styles.featuresListExtraMargin
                : null
            )}
          >
            <div
              onClick={() => history.push(BASE_ROUTES.database)}
              className={Styles.tooltip}
            >
              <Text>Full Database Access</Text>
              <Text className={Styles.tooltiptext}>
                Full access to our extensive database including information on
                coaches, camps, and more for every college in the nation.
              </Text>
            </div>

            <div className={Styles.tooltip}>
              <Text>Weekly Newsletter</Text>
              <Text className={Styles.tooltiptext}>
                Our weekly updates and recruitment tips.
              </Text>
            </div>

            <div
              onClick={() => history.push(BASE_ROUTES.review)}
              className={Styles.tooltip}
            >
              <Text>3 Film Reviews</Text>
              <Text className={Styles.tooltiptext}>
                Three free reviews applied to your account, then one-time
                reviews are availible for purchase as needed.
              </Text>
            </div>

            <div
              onClick={() => history.push(BASE_ROUTES.review)}
              className={Styles.tooltip}
            >
              <Text>∞ Film Reviews</Text>
              <Text className={Styles.tooltiptext}>
                Unlimited reviews for constant improvement to your film, while
                your account is active.
              </Text>
            </div>

            <div
              onClick={() => history.push(BASE_ROUTES.vault)}
              className={Styles.tooltip}
            >
              <Text>Full Vault Access</Text>
              <Text className={Styles.tooltiptext}>
                Access to our complete video library, including How-To videos,
                Full-Length Interviews, and Shorts
              </Text>
            </div>
          </div>
        </div>

        <div className={Styles.plansColumnsContainer}>
          <div
            id={USER_PLANS.SCHOLAR.key}
            className={Styles.plansColumn}
            onClick={() => onSelectPlan(USER_PLANS.SCHOLAR.key)}
          >
            <div className={Styles.columnTitle}>
              <Heading2 variant="plans">{USER_PLANS.SCHOLAR.label}</Heading2>
              <div
                className={classNames(
                  Styles.priceContainer,
                  discountCoupon && !isMonthlyPrice
                    ? Styles.priceContainerExtraMargin
                    : null
                )}
              >
                <Text
                  style={
                    discountCoupon
                      ? {
                          fontSize: "14px",
                          color: "#707070",
                          textDecoration: "line-through",
                        }
                      : {}
                  }
                >
                  ${checkPrice(USER_PLANS.SCHOLAR.prices)}{" "}
                  <span style={{ color: "#707070" }}>
                    {isMonthlyPrice ? "/mo" : "/year"}
                  </span>
                </Text>
                <Text>
                  {discountCoupon ? (
                    <>
                      $
                      {calcDiscount(
                        checkPrice(USER_PLANS.SCHOLAR.prices),
                        discountCoupon
                      )}
                      <span style={{ color: "#b50c0c" }}>
                        {" "}
                        {isMonthlyPrice ? "/mo" : "/year"}
                      </span>
                    </>
                  ) : null}
                </Text>
                {!isMonthlyPrice ? (
                  <Text
                    style={{
                      color: "var(--darkText)",
                      fontSize: "14px",
                    }}
                  >
                    $
                    {calcMonthly(
                      discountCoupon
                        ? checkPrice(USER_PLANS.SCHOLAR.prices, discountCoupon)
                        : checkPrice(USER_PLANS.SCHOLAR.prices)
                    )}
                    <span> /mo value</span>
                  </Text>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                Styles.checklist,
                discountCoupon && !isMonthlyPrice
                  ? Styles.checklistExtraMargin
                  : null
              )}
            >
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Full Database Access!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Our Weekly Newsletter!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img style={{ opacity: "20%" }} src={x} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Does not include any film reviews.
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img style={{ opacity: "20%" }} src={x} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Does not include any film reviews.
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img style={{ opacity: "20%" }} src={x} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Does not include Vault access.
                </Text>
              </div>
            </div>
            <div className={Styles.plansButton}>
              <Button
                className={Styles.selectButton}
                onClick={() => onSelectPlan(USER_PLANS.SCHOLAR.key)}
              >
                Select
              </Button>
            </div>
          </div>

          <div
            id={USER_PLANS.ATHLETE.key}
            className={Styles.plansColumn}
            onClick={() => onSelectPlan(USER_PLANS.ATHLETE.key)}
          >
            <div className={Styles.columnTitle}>
              <Heading2 variant="plans">{USER_PLANS.ATHLETE.label}</Heading2>
              <div
                className={classNames(
                  Styles.priceContainer,
                  discountCoupon && !isMonthlyPrice
                    ? Styles.priceContainerExtraMargin
                    : null
                )}
              >
                <Text
                  style={
                    discountCoupon
                      ? {
                          fontSize: "14px",
                          color: "#707070",
                          textDecoration: "line-through",
                        }
                      : {}
                  }
                >
                  ${checkPrice(USER_PLANS.ATHLETE.prices)}{" "}
                  <span style={{ color: "#707070" }}>
                    {isMonthlyPrice ? "/mo" : "/year"}
                  </span>
                </Text>
                <Text>
                  {discountCoupon ? (
                    <>
                      $
                      {calcDiscount(
                        checkPrice(USER_PLANS.ATHLETE.prices),
                        discountCoupon
                      )}
                      <span style={{ color: "#b50c0c" }}>
                        {" "}
                        {isMonthlyPrice ? "/mo" : "/year"}
                      </span>
                    </>
                  ) : null}
                </Text>
                {!isMonthlyPrice ? (
                  <Text
                    style={{
                      color: "var(--darkText)",
                      fontSize: "14px",
                    }}
                  >
                    $
                    {calcMonthly(
                      discountCoupon
                        ? checkPrice(USER_PLANS.ATHLETE.prices, discountCoupon)
                        : checkPrice(USER_PLANS.ATHLETE.prices)
                    )}
                    <span> /mo value</span>
                  </Text>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                Styles.checklist,
                discountCoupon && !isMonthlyPrice
                  ? Styles.checklistExtraMargin
                  : null
              )}
            >
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Full Database Access!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Our Weekly Newsletter!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Includes 3 Film Reviews!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img style={{ opacity: "20%" }} src={x} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Inlcudes 3 Reviews (not unlimited).
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img style={{ opacity: "20%" }} src={x} />
                <Text className={Styles.checklistTooltiptextRight}>
                  Does not include Vault access.
                </Text>
              </div>
            </div>
            <div className={Styles.plansButton}>
              <Button
                className={Styles.selectButton}
                onClick={() => onSelectPlan(USER_PLANS.ATHLETE.key)}
              >
                Select
              </Button>
            </div>
          </div>

          <div
            id={USER_PLANS.COMPETITOR.key}
            className={Styles.plansColumn}
            onClick={() => onSelectPlan(USER_PLANS.COMPETITOR.key)}
          >
            <div className={Styles.columnTitle}>
              <Heading2 variant="plans">{USER_PLANS.COMPETITOR.label}</Heading2>
              <div
                className={classNames(
                  Styles.priceContainer,
                  discountCoupon && !isMonthlyPrice
                    ? Styles.priceContainerExtraMargin
                    : null
                )}
              >
                <Text
                  style={
                    discountCoupon
                      ? {
                          fontSize: "14px",
                          color: "#707070",
                          textDecoration: "line-through",
                        }
                      : {}
                  }
                >
                  ${checkPrice(USER_PLANS.COMPETITOR.prices)}{" "}
                  <span style={{ color: "#707070" }}>
                    {isMonthlyPrice ? "/mo" : "/year"}
                  </span>
                </Text>
                <Text>
                  {discountCoupon ? (
                    <>
                      $
                      {calcDiscount(
                        checkPrice(USER_PLANS.COMPETITOR.prices),
                        discountCoupon
                      )}
                      <span style={{ color: "#b50c0c" }}>
                        {" "}
                        {isMonthlyPrice ? "/mo" : "/year"}
                      </span>
                    </>
                  ) : null}
                </Text>
                {!isMonthlyPrice ? (
                  <Text
                    style={{
                      color: "var(--darkText)",
                      fontSize: "14px",
                    }}
                  >
                    $
                    {calcMonthly(
                      discountCoupon
                        ? checkPrice(
                            USER_PLANS.COMPETITOR.prices,
                            discountCoupon
                          )
                        : checkPrice(USER_PLANS.COMPETITOR.prices)
                    )}
                    <span> /mo value</span>
                  </Text>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                Styles.checklist,
                discountCoupon && !isMonthlyPrice
                  ? Styles.checklistExtraMargin
                  : null
              )}
            >
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextLeft}>
                  Full Database Access!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextLeft}>
                  Our Weekly Newsletter!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextLeft}>
                  Inlcudes unlimited Film Reviews!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextLeft}>
                  Inlcudes unlimited Film Reviews!
                </Text>
              </div>
              <div className={Styles.checklistTooltip}>
                <img src={check} />
                <Text className={Styles.checklistTooltiptextLeft}>
                  Full Vault Access!
                </Text>
              </div>
            </div>
            <div className={Styles.plansButton}>
              <Button
                className={Styles.selectButton}
                onClick={() => onSelectPlan(USER_PLANS.COMPETITOR.key)}
              >
                Select
              </Button>
            </div>
          </div>
        </div>

        <div
          className={Styles.coachesContainer}
          onClick={() => history.push(BASE_ROUTES.coachesPlan)}
        >
          <div className={Styles.columnTitle}>
            <Heading2 variant="plans">Coaches Plan</Heading2>
          </div>
          <div className={Styles.coachesText}>
            <Text style={{ color: "#707070" }}>
              <span style={{ fontWeight: "500", color: "var(--athleteRed)" }}>
                A plan for teams.
              </span>
              <br />
              <br />
              Get many full-access accounts for your team for a fraction of the
              cost.
            </Text>
          </div>
          <div className={Styles.coachesButton}>
            <Button className={Styles.coachesSelectButton}>See More</Button>
          </div>
        </div>
      </div>

      <div
        className={Styles.coachesContainerUnder}
        onClick={() => history.push(BASE_ROUTES.coachesPlan)}
      >
        <div className={Styles.coachesUnderText}>
          {/* <Heading2 variant="plans">The Coaches Plan</Heading2> */}
          <Title>The Coaches Plan</Title>
          <Text style={{ color: "#707070" }}>
            <span style={{ fontWeight: "500", color: "var(--athleteRed)" }}>
              A plan for teams.
            </span>
            <br />
            Get many full-access accounts for your team for a fraction of the
            cost.
          </Text>
        </div>
        <div>
          <Button className={Styles.coachesSelectButtonUnder}>See More</Button>
        </div>
      </div>

      <div className={Styles.dealContainer}>
        <div className={pageStyles.pageTitleContainer}>
          <Title>Special Film Review Deal</Title>
        </div>
        <div className={Styles.dealContentContainer}>
          {/* <div className={Styles.textContainer}> */}
          <Text>
            In celebration of our launch, try just one Film Review for{" "}
            <span style={{ fontWeight: "700" }}>
              ${FILM_REVIEW_PRODUCT.price}
            </span>{" "}
            to see how you can improve.
          </Text>
          {/* </div> */}
          <div className={Styles.buttonContainer}>
            <Button
              onClick={() => history.push(BASE_ROUTES.review)}
              variant="darkOutline"
            >
              Learn More
            </Button>
            <Button
              style={{ paddingLeft: "34px", paddingRight: "34px" }}
              onClick={onBuyFilmReview}
            >
              Buy 1 Film Review
            </Button>
          </div>
        </div>
      </div>

      <div className={pageStyles.smallSectionContainer}>
        <div className={Styles.createFree}>
          <Title>Create Free Account</Title>
          {/* <Text>Get signed in, choose a plan later.</Text> */}
          <Text>
            Get signed in to check out Rules & Regulations as well as past
            newsletters!
          </Text>
          <div className={pageStyles.getStarted}>
            <Button
              onClick={() => history.push(BASE_ROUTES.signup)}
              style={{ paddingLeft: "50px", paddingRight: "50px" }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>

      <div className={pageStyles.redGradient} />
      <div className={pageStyles.topCenterRedGradient} />
    </div>
  );
};

export default React.memo(Plans);
