import cn from 'classnames';
import React from "react";
import styles from './styles.module.css';

const Text = ({
  className,
  ...props
}) => {
  return (
    <span className={cn(styles.container, className)} {...props} />
  );
};

export default React.memo(Text);