import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
  lastVideosInProgressUpdate: undefined,
  videos: {},
}

export const videosInProgressSlice = createSlice({
  name: 'videos-in-progress',
  initialState: INITIAL_STATE,
  reducers: {
    setVideosInProgress: (state, action) => {
      const newVideosInProgress = action.payload;
      if (newVideosInProgress) {
        state.videos = newVideosInProgress;
        state.lastVideosInProgressUpdate = newVideosInProgress._updatedAt;
      }
    },
    clearVideosInProgress: () => {
      return cloneDeep(INITIAL_STATE);
    },
  },
});

export const { setVideosInProgress, clearVideosInProgress } = videosInProgressSlice.actions;

export default videosInProgressSlice.reducer;
