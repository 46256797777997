const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
const MAIL_GUN_API_KEY = process.env.REACT_APP_MAIL_GUN_API_KEY;
const MAIL_GUN_DOMAIN = process.env.REACT_APP_MAIL_GUN_DOMAIN;

const UNLIMITED_REVIEWS = "unlimitedReviews";

const INVITE_FROM = {
  COACH_PLAN: "COACH_PLAN",
};

const INVITE_STATUS = {
  SENT: "SENT",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  REMOVED: "REMOVED",
};

const INVITE_STATUS_LABEL = {
  [INVITE_STATUS.SENT]: "Sent",
  [INVITE_STATUS.ACCEPTED]: "Accepted",
  [INVITE_STATUS.DECLINED]: "Declined",
  [INVITE_STATUS.REMOVED]: "Removed",
};

const PLANS_PERMISSIONS = {
  newsletter: {
    key: "newsletter",
    label: "Weekly Newsletter",
  },
  database: {
    key: "database",
    label: "Full Database Access",
  },
  limitedHudlAudit: {
    key: "limitedHudlAudit",
    label: "3 Film Reviews",
  },
  vault: {
    key: "vault",
    label: "Full Vault Access",
  },
  unlimitedHudlAudit: {
    key: "unlimitedHudlAudit",
    label: "Unlimited Film Reviews",
  },
};

const FILM_REVIEW_PRODUCT = {
  key: "FILM_REVIEW_PRODUCT",
  label: "Film Review",
  stripeId: process.env.REACT_APP_STRIPE_FILM_REVIEW_PRICE_ID,
  price: 12.99,
};

const COACHES_PLAN = {
  key: "COACHES_PLAN",
  label: "Coaches",
  productdId: process.env.REACT_APP_COACH_PLAN_PRODUCT_ID,
  prices: [
    {
      id: process.env.REACT_APP_COACH_PLAN_PRICE_1,
      price: 525,
      totalAccounts: 15,
    },
    {
      id: process.env.REACT_APP_COACH_PLAN_PRICE_2,
      price: 750,
      totalAccounts: 25,
    },
  ],
};

const USER_PLANS = {
  SCHOLAR: {
    key: "SCHOLAR",
    label: "Scholar",
    prices: {
      // the first price will always be the one active (the one that show up at the landing page)
      monthly: [
        {
          id: process.env.REACT_APP_SCHOLAR_MONTHLY_PRICE_1,
          price: 28.99,
        },
      ],
      // the first price will always be the one active (the one that show up at the landing page)
      yearly: [
        {
          id: process.env.REACT_APP_SCHOLAR_YEARLY_PRICE_1,
          price: 160.99,
        },
      ],
    },
    reviews: 0,
    permissions: {
      [PLANS_PERMISSIONS.database.key]: true,
      [PLANS_PERMISSIONS.newsletter.key]: true,
    },
  },
  ATHLETE: {
    key: "ATHLETE",
    label: "Athlete",
    prices: {
      // the first price will always be the one active (the one that show up at the landing page)
      monthly: [
        {
          id: process.env.REACT_APP_ATHLETE_MONTHLY_PRICE_1,
          price: 43.99,
        },
      ],
      // the first price will always be the one active (the one that show up at the landing page)
      yearly: [
        {
          id: process.env.REACT_APP_ATHLETE_YEARLY_PRICE_1,
          price: 199.99,
        },
      ],
    },
    reviews: 3,
    permissions: {
      [PLANS_PERMISSIONS.database.key]: true,
      [PLANS_PERMISSIONS.newsletter.key]: true,
      [PLANS_PERMISSIONS.limitedHudlAudit.key]: true,
    },
  },
  COMPETITOR: {
    key: "COMPETITOR",
    label: "Competitor",
    prices: {
      // the first price will always be the one active (the one that show up at the landing page)
      monthly: [
        {
          id: process.env.REACT_APP_COMPETITOR_MONTHLY_PRICE_1,
          price: 53.99,
        },
      ],
      // the first price will always be the one active (the one that show up at the landing page)
      yearly: [
        {
          id: process.env.REACT_APP_COMPETITOR_YEARLY_PRICE_1,
          price: 348.99,
        },
      ],
    },
    reviews: UNLIMITED_REVIEWS,
    permissions: {
      [PLANS_PERMISSIONS.database.key]: true,
      [PLANS_PERMISSIONS.newsletter.key]: true,
      [PLANS_PERMISSIONS.vault.key]: true,
      [PLANS_PERMISSIONS.unlimitedHudlAudit.key]: true,
    },
  },
};

const PLANS_ORDER = [
  USER_PLANS.SCHOLAR.key,
  USER_PLANS.ATHLETE.key,
  USER_PLANS.COMPETITOR.key,
];

const VAULT_PERMISSION = [USER_PLANS.COMPETITOR.key];

const DATABASE_PERMISSION = [USER_PLANS.COMPETITOR.key, USER_PLANS.ATHLETE.key, USER_PLANS.SCHOLAR.key];

const USER_TYPE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  REVIEWER: "REVIEWER",
  NEWSLETTER_WRITER: "NEWSLETTER_WRITER",
  FREE: "FREE",
  SCHOLAR: USER_PLANS.SCHOLAR.key,
  ATHLETE: USER_PLANS.ATHLETE.key,
  COMPETITOR: USER_PLANS.COMPETITOR.key,
  COACH: "COACH",
};

const USER_TYPE_LABEL = {
  [USER_TYPE.SUPER_ADMIN]: "Super Admin",
  [USER_TYPE.ADMIN]: "Admin",
  [USER_TYPE.REVIEWER]: "Reviewer",
  [USER_TYPE.NEWSLETTER_WRITER]: "Newsletter Writter",
  [USER_TYPE.FREE]: "Free User",
  [USER_TYPE.SCHOLAR]: USER_PLANS.SCHOLAR.label,
  [USER_TYPE.ATHLETE]: USER_PLANS.ATHLETE.label,
  [USER_TYPE.COMPETITOR]: USER_PLANS.COMPETITOR.label,
  [USER_TYPE.COACH]: "Coach",
};

const USERS_CAN_BE_CHANGED = {
  [USER_TYPE.REVIEWER]: true,
  [USER_TYPE.NEWSLETTER_WRITER]: true,
  [USER_TYPE.FREE]: true,
};

const ADMIN_PANEL_USER_PERMISSION = [
  USER_TYPE.ADMIN,
  USER_TYPE.REVIEWER,
  USER_TYPE.NEWSLETTER_WRITER,
  USER_TYPE.SUPER_ADMIN,
];

const USER_REVIEW_PERMISSION = [
  USER_TYPE.ADMIN,
  USER_TYPE.REVIEWER,
  USER_TYPE.SUPER_ADMIN,
];

const USER_NEWSLETTER_PERMISSION = [
  USER_TYPE.ADMIN,
  USER_TYPE.NEWSLETTER_WRITER,
  USER_TYPE.SUPER_ADMIN,
];

const ADMIN_USERS = [USER_TYPE.ADMIN, USER_TYPE.SUPER_ADMIN];

const THUMBNAIL_URL = "http://img.youtube.com/vi/{videoId}/0.jpg";

const EMAIL_TEMPLATE = {
  CANCEL_ACCOUNT: {
    template: "cancelled account",
    subject: "Sorry to see you go",
  },
  FILM_REVIEW_SUBMISSION: {
    template: "film_review_submission",
    subject: "Film Review Submitted",
  },
  SUBSCRIPTION_RENEWAL_ATHLETE: {
    template: "subscription renewal - athlete",
    subject: "Subscription Renewed",
  },
  SUBSCRIPTION_RENEWAL_COMPETITOR: {
    template: "subscription renewal - competitor",
    subject: "Subscription Renewed",
  },
  SUBSCRIPTION_RENEWAL_SCHOLAR: {
    template: "subscription renewal - scholar",
    subject: "Subscription Renewed",
  },
  UPGRATE_TO_ATHLETE: {
    template: "upgrade to athlete",
    subject: "Subscription upgrade",
  },
  UPGRATE_TO_COMPETITOR: {
    template: "upgrade to competitor",
    subject: "Subscription upgrade",
  },
  WELCOME_ATHLETE: {
    template: "welcome to athletes after - athlete",
    subject: "Welcome to Athletes After",
  },
  WELCOME_COMPETITOR: {
    template: "welcome to athletes after - competitor",
    subject: "Welcome to Athletes After",
  },
  WELCOME_SCHOLAR: {
    template: "welcome to athletes after - scholar",
    subject: "Welcome to Athletes After",
  },
  WELCOME_FREE: {
    template: "welcome to the athletes after cumunity",
    subject: "Welcome to Athletes After",
  },
  WELCOME_COACH: {
    template: "athlete invite from coach",
    subject: "Welcome to Athletes After",
  },
  REMOVE_USER_COACH: {
    template: "athlete removed from coaches plan",
    subject: "Sorry to see you go",
  },
  WELCOME_COACH_15: {
    template: "coaches plan welcome - 15 users",
    subject: "Good to see you coach",
  },
  WELCOME_COACH_25: {
    template: "coaches plan - 25 users",
    subject: "Good to see you coach",
  },
};

const ACCPET_INVITE_LINK =
  "https://athletes-after-database-v2.web.app/accept-invite?token=";

const SUPPORT_EMAIL = "maxmuncy00@gmail.com";

const LOGIN_LINK = "https://athletes-after-database-v2.web.app/login";

const NEWSLETTERS_LINK =
  "https://athletes-after-database-v2.web.app/dashboard/newsletters";

const PLANS_PAGE_LINK = "https://athletes-after-database-v2.web.app/plans";

const COACH_MANAGER_LINK =
  "https://athletes-after-database-v2.web.app/dashboard/coach-plan-manager";

module.exports = {
  STRIPE_KEY,
  STRIPE_SECRET_KEY,
  MAIL_GUN_API_KEY,
  MAIL_GUN_DOMAIN,
  UNLIMITED_REVIEWS,
  INVITE_FROM,
  INVITE_STATUS,
  PLANS_PERMISSIONS,
  FILM_REVIEW_PRODUCT,
  COACHES_PLAN,
  USER_PLANS,
  PLANS_ORDER,
  VAULT_PERMISSION,
  DATABASE_PERMISSION,
  USER_TYPE,
  USER_TYPE_LABEL,
  USERS_CAN_BE_CHANGED,
  ADMIN_PANEL_USER_PERMISSION,
  USER_REVIEW_PERMISSION,
  USER_NEWSLETTER_PERMISSION,
  ADMIN_USERS,
  THUMBNAIL_URL,
  INVITE_STATUS_LABEL,
  EMAIL_TEMPLATE,
  SUPPORT_EMAIL,
  LOGIN_LINK,
  NEWSLETTERS_LINK,
  ACCPET_INVITE_LINK,
  PLANS_PAGE_LINK,
  COACH_MANAGER_LINK,
};
