import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
  lastReviewsUpdate: undefined,
  lastReviewsStructureUpdate: undefined,
  reviews: [],
  reviewsStructure: [],
  reviewsLeft: 0,
  totalReviewsLeft: undefined,
}

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: INITIAL_STATE,
  reducers: {
    setReviews: (state, action) => {
      const oldReviews = [...state.reviews];
      const reviews = action.payload || [];
      if (reviews.length) {
        for (let i = 0; i < reviews.length; i++) {
          const review = reviews[i];
          const oldReviewsIndex = oldReviews.findIndex(r => r.id === review.id);
          if (oldReviewsIndex !== -1) {
            oldReviews.splice(oldReviewsIndex, 1);
          }
        }
        state.reviews = [...reviews.filter(r => !r._deleted), ...oldReviews];
        const lastUpdatedAt = reviews[0]._updatedAt;
        state.lastReviewsUpdate = lastUpdatedAt;
      }
    },
    setReviewsStructure: (state, action) => {
      const oldReviewsStructure = [...state.reviewsStructure];
      const reviewsStructure = action.payload || [];
      if (reviewsStructure.length) {
        for (let i = 0; i < reviewsStructure.length; i++) {
          const reviewStructure = reviewsStructure[i];
          const oldReviewsStructureIndex = oldReviewsStructure.findIndex(r => r.id === reviewStructure.id);
          if (oldReviewsStructureIndex !== -1) {
            oldReviewsStructure.splice(oldReviewsStructureIndex, 1);
          }
        }
        state.reviewsStructure = [...reviewsStructure.filter(rs => !rs._deleted), ...oldReviewsStructure];
        const lastUpdatedAt = reviewsStructure[0]._updatedAt;
        state.lastReviewsStructureUpdate = lastUpdatedAt;
      }
    },
    setReviewsLeft: (state, action) => {
      state.reviewsLeft = action.payload;
    },
    setTotalReviewsLeft: (state, action) => {
      state.totalReviewsLeft = action.payload;
    },
    clearReviews: () => cloneDeep(INITIAL_STATE),
  },
});

export const { setReviews, setReviewsStructure, clearReviews, setReviewsLeft, setTotalReviewsLeft } = reviewsSlice.actions;

export default reviewsSlice.reducer;
