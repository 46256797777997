import { createSlice } from '@reduxjs/toolkit';

export const vaultSectionSlice = createSlice({
  name: 'vault-section',
  initialState: [],
  reducers: {
    setVaultSection: (state, action) => {
      return [action.payload, ...state];
    },
    removeLastVaultSection: (state) => {
      state.splice(0, 1);
      return state;
    },
    clearVaultSection: () => {
      return [];
    },
  },
});

export const { setVaultSection, removeLastVaultSection, clearVaultSection } = vaultSectionSlice.actions;

export default vaultSectionSlice.reducer;
