import React from "react";
import Select from "react-select";
import { STATES } from '../../../../constants';
import { useFilter } from '../../../../context/filter';
import styles from './styles.module.css';

const Status = () => {
  const { selectedState, setSelectedState } = useFilter();

  return (
      <Select
        classNamePrefix="select"
        isClearable
        isSearchable
        options={STATES}
        value={selectedState}
        onChange={setSelectedState}
        className={styles.container}
      />
  );
};

export default React.memo(Status);
