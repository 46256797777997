import React from "react";
import BaseText from "../../../common/components/text";
import Styles from "./styles.module.css";
import Text from "../.text";

const fs = "60px";

const Headline = ({ fontsize, ...props }) => {
  return <div className={Styles.container}><BaseText style={{ fontSize: fontsize }} className={Styles.text} {...props} /></div>;

};

export default React.memo(Headline);
