import cn from "classnames";
import React, { useMemo } from "react";
import BaseButton from "../../../common/components/button";
import Styles from "./styles.module.css";

const Button = ({ className, variant, style, disabled, onClick, ...props }) => {
  const variantStyle = useMemo(() => {
    if (variant === "outline") return Styles.outline;
    if (variant === "white") return Styles.white;
    if (variant === "form") return Styles.form;
    if (variant === "formSmall") return Styles.formSmall;
    if (variant === "action") return Styles.action;
    if (variant === "actionWhite") return Styles.actionWhite;
    if (variant === "darkOutline") return Styles.darkOutline;
    if (variant === "actionSelected") return Styles.action;
  }, [variant]);
  const textVariantStyle = useMemo(() => {
    if (variant === "white" || variant === "actionWhite" || variant === "form" || variant === "formSmall") return Styles.blackText;
    if (variant === "darkOutline") return Styles.darkText;
    if (variant === "actionSelected") return Styles.redText;
  }, [variant]);
  return (
    <BaseButton
      className={cn(Styles.default, variantStyle, className)}
      contentClassname={textVariantStyle}
      style={style}
      onClick={disabled ? null : onClick}
      {...props}
    />
  );
};

export default React.memo(Button);
