import React, { useEffect, useState } from "react";
import { saveCategory } from "../../../../common/services/firebase/vault";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Modal from "../../../components/modal";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const NewCategoryModal = ({
  category,
  onClose,
  onRefresh,
}) => {
  const [name, setLabel] = useState(category?.name || "");
  const [saving, setSaving] = useState(false);
  const isNewCategory = !category?.id;

  useEffect(() => {
    setLabel(category?.name || "");
  }, [category]);

  const onSave = async () => {
    if (!name?.trim().length) return alert("Category needs a name")
    setSaving(true);
    await saveCategory(category?.id, name);
    setSaving(false);
    onRefresh();
    onClose();
  }

  return (
    <Modal visible={!!category} onClose={onClose}>
      <div className={styles.container}>
        <Text className={styles.title}>{isNewCategory ? "New" : "Edit"} Category</Text>
        <Input value={name} onChange={evt => setLabel(evt.target.value)} name="Name" className={styles.input} />
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={onSave} variant="outline" disabled={saving}>Save</Button>
        </div>
      </div>
    </Modal>
  )
};

export default React.memo(NewCategoryModal);
