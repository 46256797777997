import { cloneDeep } from "lodash";
import React, { useMemo, useState } from "react";
import Input from "../../../components/input";
import PageTitle from "../../../components/page-title";
import Text from "../../../components/text";
import VideoCard from "../../../components/video-card";
import { getCollectionTags } from "../../../utils/tags";
import styles from "./styles.module.css";

const SearchPage = ({ videos: videosNoTags, videosInProgress, tags, onClose }) => {
  const [search, setSearch] = useState("");
  const trimSearch = search.trim().toLowerCase();

  const videos = useMemo(() => {
    return getCollectionTags(cloneDeep(videosNoTags), tags);
  }, [videosNoTags, tags]);

  const filteredVideos = useMemo(() => {
    return videos.filter(video => {
      if (!trimSearch.length) return video;
      const title = video.title.trim().toLowerCase();
      const vTags = video.tags;
      return title.includes(trimSearch) || (vTags.length && !!vTags.find(tag => tag.label.trim().toLowerCase().includes(trimSearch)));
    });
  }, [videos, trimSearch]);

  return (
    <div className={styles.container}>
      <PageTitle
        title="The Vault"
        ExtraComponent={
          <div className={styles.extraComponentContainer}>
            <Input
              value={search}
              onChange={evt => setSearch(evt.target.value)}
              placeholder="Search The Vault"
              autoFocus
              variant="transparent"
            />
            <div onClick={onClose} className={styles.closeContainer}>
              <Text>X</Text>
            </div>
          </div>
        }
      />
      <div>
        {filteredVideos.map(video => <VideoCard video={video} videosInProgress={videosInProgress} videosFromSection={videos} />)}
      </div>
    </div>
  )
};

export default React.memo(SearchPage);
