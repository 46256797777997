import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
  route: undefined,
  data: undefined,
};

export const appRedirect = createSlice({
  name: 'app-redirect',
  initialState: INITIAL_STATE,
  reducers: {
    setRedirectData: (state, action) => {
      return action.payload;
    },
    clearRedirectData: (state, action) => {
      return cloneDeep(INITIAL_STATE);
    },
  },
});

export const { setRedirectData, clearRedirectData } = appRedirect.actions;

export default appRedirect.reducer;
