import React from "react";
import { useFilter } from "../../../../context/filter";
import { getClassName, getTextClassName } from "../../../../utils/dark-mode";
import styles from "./styles.module.css";
import CloseIcon from "../../../../assets/icons/close.svg";
import CloseIconLight from "../../../../assets/icons/close-light.svg";

const STATUS = [
  { label: "Public", value: "Public" },
  { label: "Private", value: "Private" },
];

const Status = () => {
  const { selectedStatus, setSelectedStatus, isDark } = useFilter();

  return (
    <div>
      {STATUS.map((d) => {
        const isSelected = selectedStatus === d.value;
        return (
          <div
            key={d.value}
            className={`${getTextClassName(styles.label, isDark)} ${
              isSelected ? getClassName(styles, "selectedLabel", isDark) : ""
            }`}
            onClick={() => setSelectedStatus(isSelected ? undefined : d.value)}
          >
            {d.label}
            {isSelected ? (
              <img src={isDark ? CloseIconLight : CloseIcon} width={10} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Status);
