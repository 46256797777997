import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, firestore } from '..';
import { USERS_COLLECTION } from '../constants';
import { setupStripeUser } from "../users";

export const signUp = async ({ email, password, firstName, lastName, userType, extraFields = {} }) => {
  const response = await createUserWithEmailAndPassword(auth, email, password);
  const userId = response.user.uid;
  const user = {
    id: userId,
    email,
    firstName,
    lastName,
    userType,
    _createdAt: Date.now(),
    ...extraFields
  };
  await setDoc(doc(firestore, USERS_COLLECTION, userId), user);
  await setupStripeUser(userId, email, `${firstName} ${lastName}`)
  return user;
};

export const login = async (email, password) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  const userId = response.user.uid;
  const docRef = doc(firestore, USERS_COLLECTION, userId);
  const docSnap = await getDoc(docRef);
  return docSnap.data()
};
