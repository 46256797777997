import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import appRedirectSlice from './reducers/app-redirect';
import appStateSlice from './reducers/app-state';
import loadersSlice from './reducers/loaders';
import newslettersSlice from './reducers/newsletters';
import reviewsSlice from './reducers/reviews';
import stripeSlice from './reducers/stripe';
import userSlice from './reducers/user';
import vaultSlice from './reducers/vault';
import vaultPlaylistSlice from './reducers/vault-playlist';
import vaultSectionSlice from './reducers/vault-section';
import videosInProgressSlice from './reducers/videos-in-progress';


const reducer = combineReducers({
  user: userSlice,
  loaders: loadersSlice,
  stripe: stripeSlice,
  vault: vaultSlice,
  newsletters: newslettersSlice,
  videosInProgress: videosInProgressSlice,
  reviews: reviewsSlice,
  vaultSection: vaultSectionSlice,
  vaultPlaylist: vaultPlaylistSlice,
  appRedirect: appRedirectSlice,
  appState: appStateSlice
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({ reducer: persistedReducer });

export const persistor = persistStore(store);

export default store;
