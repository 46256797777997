import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { POSITION, REVIEW_LABEL, REVIEW_STATUS, VIDEO_TYPE } from '../../../Dashboard/constants';
import { DASHBOARD_ROUTES } from "../../../common/route";
import { replaceRoute } from "../../../common/utils/routes";
import Button from "../../components/button";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import styles from './styles.module.css';

const ReviewDetail = () => {
  const { reviews } = useSelector(state => state.reviews);
  const { id: _reviewId } = useParams();
  const history = useHistory();
  
  const review = useMemo(() => {
    return (reviews || []).find(r => r.id === _reviewId);
  }, [_reviewId, reviews]);

  if (!review) return <Text>Review not found...</Text>;

  return (
    <Screen>
      <PageTitle title={ `${review.title} - ${REVIEW_LABEL[review.status]}`} />
      <Text>Date: {new Date(review._createdAt).toLocaleDateString()} - {new Date(review._createdAt).toLocaleTimeString()}</Text>
      <div className={styles.infoContainer}>
        <div>
          <Text variant="h2">Review Info</Text>
        </div>
        <div className={styles.valueContainer}>
          <Text>Video Type: {VIDEO_TYPE[review.videoType].label}</Text>
        </div>
        <div className={styles.valueContainer}>
          <Text>Position: {POSITION[review.position].label}</Text>
        </div>
        <div className={styles.valueContainer}>
          <Text>Link: {review.link}</Text>
        </div>
      </div>
      {review.status === REVIEW_STATUS.DONE ? (
        <Button variant="outlinePrimary" className={styles.buttonContainer} onClick={() => history.push(replaceRoute(DASHBOARD_ROUTES.reviewFeedback, ['id', review.id]))}>Check Feedback</Button>
      ) : null}
    </Screen>
  )
};

export default React.memo(ReviewDetail);
