import React from "react";
import Text from "../text";
import styles from "./styles.module.css";

const PageTitle = ({
  title,
  ExtraComponent
}) => {
  return (
    <div className={styles.container}>
      <Text className={styles.title} variant="title">{title}</Text>
      {ExtraComponent ? ExtraComponent : null}
    </div>
  )
};

export default React.memo(PageTitle);
