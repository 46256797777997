import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { REVIEW_STATUS } from '../../../../Dashboard/constants';
import { getUserReviews } from '../../../../common/services/firebase/reviews';
import Text from "../../../components/text";
import ReviewListItem from "../review-list-item";
import styles from './styles.module.css';

const TABS = [
  { id: 'myReviews', label: 'My Reviews' },
  { id: 'reviewsAvailable', label: 'Reviews Available' },
];

const ReviewerReview = () => {
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TABS[0].id);
  const user = useSelector(state => state.user);
  const loading = loadingReviews;

  const loadReviews = useCallback(() => {
    setLoadingReviews(true);
    const filters = {
      reviewerId: user.id
    };
    if (selectedTab === TABS[1].id) {
      filters.reviewerId = 'undefined';
      filters.status = REVIEW_STATUS.SUBMITTED;
    }
    getUserReviews(filters).then(response => {
      setReviews(response);
    }).finally(() => {
      setLoadingReviews(false);
    });
  }, [user, selectedTab]);

  useEffect(() => {
    loadReviews();
  }, [loadReviews]);


  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {TABS.map(tab => {
          const isSelected = tab.id === selectedTab;
          return (
            <div key={tab.id} onClick={() => setSelectedTab(tab.id)} className={styles.tab}>
              <Text className={isSelected ? styles.tabSelected : ''}>{tab.label}</Text>
            </div>
          )
        })}
      </div>
      {loading ? <Text>Loading...</Text> : (
        <div>
          {reviews.sort((a, b) => b._createdAt - a._createdAt).map(review => {
            return (
              <ReviewListItem key={review.id} {...review} />
            )
          })}
        </div>
      )}
    </div>
  )
};

export default React.memo(ReviewerReview);
