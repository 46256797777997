import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { UNLIMITED_REVIEWS } from "../../../common/constants";
import Text from "../text";
import styles from "./styles.module.css";

const ReviewsLeft = ({
  outline,
  getMoreClick,
  className
}) => {
  const { reviewsLeft, totalReviewsLeft } = useSelector(state => state.reviews);
  if (reviewsLeft === -1) return null;
  if (reviewsLeft === UNLIMITED_REVIEWS) return null;

  return (
    <div className={cn(styles.container, className, {
      [styles.outline]: outline
    })}>
      <div>
        <Text>{totalReviewsLeft} Review{totalReviewsLeft === 1 ? "" : "s"} Left</Text>
      </div>
      {getMoreClick ? (
        <div className={styles.getMoreContainer} onClick={getMoreClick}>
          <Text className={styles.getMore}>Get More</Text>
        </div>
      ) : null}
    </div>
  )
};

export default React.memo(ReviewsLeft);