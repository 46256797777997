import cn from "classnames";
import React, { useEffect, useState } from "react";
import Icon, { ICONS_NAME } from "../../../../Dashboard/components/icon";
import { saveTag } from "../../../../common/services/firebase/vault";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Modal from "../../../components/modal";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const ICONS = Object.values(ICONS_NAME);

const NewTagModal = ({
  tag,
  onClose,
  onRefresh,
}) => {
  const [label, setLabel] = useState(tag?.label || "");
  const [icon, setIcon] = useState(tag?.icon);
  const [saving, setSaving] = useState(false);
  const isNewTag = !tag?.id;

  useEffect(() => {
    setLabel(tag?.label || "");
    setIcon(tag?.icon);
  }, [tag]);

  const onSave = async () => {
    if (!label?.trim().length) return alert("Tag needs a label")
    setSaving(true);
    await saveTag({
      id: tag?.id,
      label,
      icon,
    });
    setSaving(false);
    onRefresh();
    onClose();
  }

  const selectIcon = (selectedIcon) => {
    setIcon(prev => {
      if (prev === selectedIcon) return undefined;
      return selectedIcon;
    });
  };

  return (
    <Modal visible={!!tag} onClose={onClose}>
      <div className={styles.container}>
        <Text className={styles.title}>{isNewTag ? "New" : "Edit"} Tag</Text>
        <Input value={label} onChange={evt => setLabel(evt.target.value)} label="Label" className={styles.input} />
        <div className={styles.iconsContainer}>
          {ICONS.map(iconName => {
            const isSelected = icon === iconName;
            return (
              <div className={cn(styles.iconContainer, { [styles.selectedIcon]: isSelected })} onClick={() => selectIcon(iconName)}>
                <Icon name={iconName} alt="icon" size={30} />
              </div>
            )
          })}
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={onSave} variant="outline" disabled={saving}>Save</Button>
        </div>
      </div>
    </Modal>
  )
};

export default React.memo(NewTagModal);
