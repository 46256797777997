import React from "react";
import Text from "../../../components/text";
import { CONTENT_TYPES } from "../../../constants";
import styles from './styles.module.css';

const ContentRenderer = ({ content }) => {

  return (
    <div className={styles.container}>
      {content.type === CONTENT_TYPES.regular ? (
        <div>
          <Text>{content.text}</Text>
        </div>
      ) : null}
      {content.type === CONTENT_TYPES.bullet_item_with_title ? (
        <div className={styles.bulletWtemWithTitleContainer} style={{ marginLeft: (content.margin ?? 0) * 10 }}>
          <Text className={styles.bullet}>&#8226;</Text>
          <Text className={styles.bulletWtemWithTitleText}>
            <Text className={styles.bulletWtemWithTitleTitle}>{content.title}</Text>
            {content.text}
            {content.text}
            {content.text}
          </Text>
        </div>
      ) : null}
      {content.type === CONTENT_TYPES.item_with_title ? (
        <Text className={styles.itemWithTitleText}>
          <Text className={styles.itemWithTitleTitle}>{content.title}</Text>
          {content.text}
        </Text>
      ) : null}
      {content.type === CONTENT_TYPES.title ? (
        <Text>
          {content.text}
        </Text>
      ) : null}
    </div>
  )
};

export default React.memo(ContentRenderer);
