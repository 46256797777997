import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ADMIN_ROUTES } from "../../../../common/route";
import { httpsCallable } from "../../../../common/services/firebase";
import { saveNewsletter } from "../../../../common/services/firebase/newsletter";
import { replaceRoute } from "../../../../common/utils/routes";
import { getImagePreviewUrl } from "../../../../common/utils/storage";
import Tags from "../../../components/tags";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const sendMail = httpsCallable("sendNewslleterEmail");

const NewsletterCard = ({ newsletter, newslettersTags }) => {
  const history = useHistory();
  const tags = newsletter.tags;
  const imageUrl = getImagePreviewUrl("newsletter", newsletter.image);

  const onClick = () => history.push(replaceRoute(ADMIN_ROUTES.editNewsletter, ['id', newsletter.id]));

  const onSendEmail = async (evt) => {
    try {
      evt.stopPropagation();
      if (newsletter.emailSent && !window.confirm("You already sent this newsletter via email. Do you want to send it again?")) {
        return;
      }
      await sendMail({ newsletter });
      newsletter.emailSent = true;
      await saveNewsletter(newsletter)
      toast.success("Email sent");
    } catch (error) {
      console.log('error', error);
      toast.error("Something went wrong");
    }
  }

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.mainContent}>
        {imageUrl ? <img src={imageUrl} alt="preview" className={styles.image} /> : null}
        <div className={styles.content}>
          <Text className={styles.title}>{newsletter.title}</Text>
          <Text>{newsletter.intro}</Text>
          <Tags tags={tags} collectionTags={newslettersTags} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(NewsletterCard);
