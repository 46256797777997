import React, { useEffect, useMemo, useState } from "react";
import CheckIcon from "../../../Dashboard/assets/icons/check.svg";
import Button from "../button";
import Input from "../input";
import Modal from "../modal";
import Text from "../text";
import styles from './styles.module.css';

const TagsModal = ({ visible, onClose, selectedTags, tags, onSelectTags }) => {
  const [search, setSearch] = useState('');
  const [newSelectedTags, setNewSelectedTags] = useState(selectedTags || []);
  const filteredTags = useMemo(() => {
    const searchLC = search?.trim()?.toLowerCase();
    if (!searchLC) return tags || [];
    return tags.filter(tag => {
      const name = tag.label.trim().toLowerCase();
      return name.includes(searchLC);
    });
  }, [tags, search]);

  useEffect(() => {
    setSearch('');
    setNewSelectedTags(selectedTags || []);
  }, [visible, selectedTags]);

  const onAddNewTag = (tagId) => {
    setNewSelectedTags(prev => {
      return [...prev, tagId];
    })
  }

  const onRemoveTag = (tagId) => {
    setNewSelectedTags(prev => {
      return prev.filter(tag => tag !== tagId);
    })
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <div>
          <div className={styles.headerContainer}>
            <Text>Select Tags</Text>
            <div onClick={onClose} className={styles.closeButton}>
              <Text>X</Text>
            </div>
          </div>
          <Input value={search} onChange={evt => setSearch(evt.target.value)} />
          <div className={styles.tagsContainer}>
            {filteredTags.map(tag => {
              const isSelected = newSelectedTags.includes(tag.id);
              return (
                <div key={tag.id} className={styles.tag} onClick={() => isSelected ? onRemoveTag(tag.id) : onAddNewTag(tag.id)}>
                  <Text>{tag.label}</Text>
                  {isSelected ? <img src={CheckIcon} alt="CheckIcon" className={styles.icon} /> : null}
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={() => onSelectTags(newSelectedTags)} variant="outline">Save</Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TagsModal);
