import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VAULT_PERMISSION } from "../../../common/constants";
import { useGoBack } from "../../../common/hooks/onGoBackListener";
import { removeLastVaultSection } from "../../../common/redux/reducers/vault-section";
import Input from "../../components/input";
import RestrictedPage from "../../components/restricted-page";
import Text from "../../components/text";
import VideoCard from "../../components/video-card";
import { getCollectionTags } from "../../utils/tags";
import styles from "./styles.module.css";

const VaultSection = () => {
  const tags = useSelector((state) => state.vault.tags);
  const vaultSection = useSelector((state) => state.vaultSection[0]);
  const videosInProgress = useSelector(
    (state) => state.videosInProgress.videos
  );
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const trimSearch = search.trim().toLowerCase();
  useGoBack(() => {
    dispatch(removeLastVaultSection());
  });

  const { title, videos } = vaultSection || {};

  const tagVideos = useMemo(() => {
    return getCollectionTags(videos, tags);
  }, [tags, videos]);

  const filteredVideos = useMemo(() => {
    return tagVideos.filter((video) => {
      if (!trimSearch.length) return video;
      const title = video.title.trim().toLowerCase();
      const vTags = video.tags;
      return (
        title.includes(trimSearch) ||
        (vTags.length &&
          !!vTags.find((tag) =>
            tag.label.trim().toLowerCase().includes(trimSearch)
          ))
      );
    });
  }, [trimSearch, tagVideos]);

  if (!vaultSection) return <Text>Section not found...</Text>;

  return (
    <RestrictedPage restrictedTitle="The Vault" allowedPlans={VAULT_PERMISSION}>
      <div className={styles.headerContainer}>
        <Text variant="title">{title}</Text>
        <Input
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
          placeholder="Search"
          className={styles.input}
          variant="transparent"
          search="true"
        />
      </div>
      <div className={styles.videosContainer}>
        {filteredVideos.map((video) => (
          <VideoCard
            key={`vault-section-video-${video.id}`}
            video={video}
            videosInProgress={videosInProgress}
            videosFromSection={videos}
          />
        ))}
      </div>
    </RestrictedPage>
  );
};

export default React.memo(VaultSection);
