import { DASHBOARD_ROUTES } from '../../common/route';
import { ICONS_NAME } from '../components/icon';

export const PRODUCTS_MENU = [
  { key: 'database', label: 'Database', icon: ICONS_NAME.pin, route: DASHBOARD_ROUTES.database },
  { key: 'vault', label: 'The Vault', icon: ICONS_NAME.playButton, route: DASHBOARD_ROUTES.vault },
  { key: 'review', label: 'Review', icon: ICONS_NAME.reviewIcon, route: DASHBOARD_ROUTES.review, extraRoutes: [DASHBOARD_ROUTES.newReview] },
  { key: 'rules', label: 'Rules & Regs', icon: ICONS_NAME.openBook, route: DASHBOARD_ROUTES.rules },
  { key: 'newsletter', label: 'Newsletter', icon: ICONS_NAME.newspaper, route: DASHBOARD_ROUTES.newsletter },
];

export const REVIEW_STATUS = {
  SUBMITTED: 'SUBMITTED',
  IN_REVIEW: 'IN_REVIEW',
  WAITING_FOR_ADMIN_APPROVAL: 'WAITING_FOR_ADMIN_APPROVAL',
  APPROVED: 'APPROVED',
  DONE: 'DONE',
}

export const REVIEW_LABEL = {
  [REVIEW_STATUS.SUBMITTED]: 'Sent for review',
  [REVIEW_STATUS.IN_REVIEW]: 'In Review',
  [REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL]: 'In Review',
  [REVIEW_STATUS.APPROVED]: 'In Review',
  [REVIEW_STATUS.DONE]: 'Done',
}

export const ADMIN_REVIEW_LABEL = {
  [REVIEW_STATUS.SUBMITTED]: 'Ready for review',
  [REVIEW_STATUS.IN_REVIEW]: 'In Review',
  [REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL]: 'Waiting for admin approval',
  [REVIEW_STATUS.APPROVED]: 'Approved',
  [REVIEW_STATUS.DONE]: 'Done',
}

export const VIDEO_TYPE = {
  highlightsFilm: { key: 'highlightsFilm', label: 'Highlights Film' },
  technicalFilm: { key: 'technicalFilm', label: 'Technical Film' },
}

export const POSITION = {
  quaterback: { key: 'quaterback', label: 'Quarterback' },
  receiverTightend: { key: 'receiverTightend', label: 'Receiver / Tightend' },
  runningBack: { key: 'runningBack', label: 'Runningback' },
  offensiveLineman: { key: 'offensiveLineman', label: 'Offensive Lineman' },
  defensiveLineman: { key: 'defensiveLineman', label: 'Defensive Lineman' },
  linebacker: { key: 'linebacker', label: 'Linebacker' },
  defensiveBack: { key: 'defensiveBack', label: 'Defensive Back' },
}

export const VIDEO_TYPE_OPTIONS = [
  { value: VIDEO_TYPE.highlightsFilm.key, label: VIDEO_TYPE.highlightsFilm.label },
  { value: VIDEO_TYPE.technicalFilm.key, label: VIDEO_TYPE.technicalFilm.label },
]

export const POSITION_OPTIONS = [
  { value: POSITION.quaterback.key, label: POSITION.quaterback.label },
  { value: POSITION.receiverTightend.key, label: POSITION.receiverTightend.label },
  { value: POSITION.runningBack.key, label: POSITION.runningBack.label },
  { value: POSITION.offensiveLineman.key, label: POSITION.offensiveLineman.label },
  { value: POSITION.defensiveLineman.key, label: POSITION.defensiveLineman.label },
  { value: POSITION.linebacker.key, label: POSITION.linebacker.label },
  { value: POSITION.defensiveBack.key, label: POSITION.defensiveBack.label },
]

export const CONTENT_TYPES = {
  regular: 'regular',
  item_with_title: 'item_with_title',
  bullet_item_with_title: 'bullet_item_with_title',
  title: 'title',
}

export const DIVISION1_CONTENT = {
  basicRules: [
    { text: 'D1 football coaches can send athletes recruiting questionnaires, camp brochures and non-athletic institutional publications freshman and sophomore year. Effective for the 2023-24 school year, the NCAA now allows contactbetween coaches and athletes to begin June 15 of the athlete\'s junior year.', type: CONTENT_TYPES.regular },
    { text: 'Junior year:', type: CONTENT_TYPES.title },
    { title: 'Any time:', text: ' Athletes can receive brochures for camps, recruiting questionnaires, NCAA materials and non-athletic recruiting publications.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'April 1 through the Sunday before the last Wednesday in June:', text: ' A college may pay for a prospective student-athlete and two family members to visit campus. After this date, a college may offer an expense-paid visit after Sept. 1 of senior year.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { text: 'Senior year:', type: CONTENT_TYPES.title },
    { title: 'July 1 before senior year:', text: ' Coaches can contact athletes off-campus, but only during the contact periods. Coaches are only allowed six off-campus contacts with each student-athlete.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'First day of classes:', text: ' Athletes can take unlimited official visits. Only one per school.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'September 1:', text: ' Coaches can call athletes once a week after this time, and they can call recruits unlimited times during the contact period.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'Evaluation days:', text: ' Coaches can evaluate each recruit three times. They can take one evaluation per recruit in the fall, and then two evaluations from April 15 to May 31.', type: CONTENT_TYPES.item_with_title },
  ],
}

export const DIVISION2_CONTENT = {
  basicRules: [
    { text: 'The NCAA football recruiting rules for Division 2 are the same across all sports. For football, the rules are slightly more relaxed than those for Division 1:', type: CONTENT_TYPES.regular },
    { title: 'Any time:', text: ' Athletes can receive brochures for camps, recruiting questionnaires, NCAA materials and non-athletic recruiting publications.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'June 15 before junior year: ', text: ' Athletes can receive any form of private, electronic communication. This includes emails, recruiting materials, texts and direct messages on social media.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
    { title: 'Evaluations:', text: ' Coaches are not restricted in how many times they can evaluate a student-athlete at the D2 level. Coaches just have to make sure that they don\'t communicate with the recruits until their practice/competition has ended and they\'ve been released by their coach or the proper authority.', type: CONTENT_TYPES.bullet_item_with_title, margin: 1 },
  ],
  schedule: {
    cards: [
      { title: 'Quiet period', text: 'The quiet period is a time you can talk to college coaches in- person on their college campus. However, the coach is not allowed to watch athletes compete in-person, visit their school, talk to them at their home-or talk to them anywhere outside of the college campus.'},
      { title: 'Evaluation period', text: 'This is a specific time of year when college coaches are allowed to watch an athlete compete in person or visit their school. However, coaches are not allowed to communicate with that athlete (or their parents) off the college campus.'},
      { title: 'Contact period', text: 'The NCAA contact period is exactly what it sounds like—all communication between athletes and coaches is fair game. In other words, the communication floodgates are open, so take advantage of this opportunity to get unlimited access to coaches.'},
    ]
  }
}

export const DIVISION3_CONTENT = {
  basicRules: [
    { text: 'D3 and NAIA colleges are generally left to create their own recruiting rules and schedules. They don\'t have limits on when coaches can contact recruits. The only standardized rule for D3 schools is that coaches are not allowed to meet with recruits or their families off-campus until the athlete has completed their sophomore year of high school.', type: CONTENT_TYPES.regular },
  ]
}

export const NAIA_CONTENT = {
  basicRules: [
    { text: 'D3 and NAIA colleges are generally left to create their own recruiting rules and schedules. They don\'t have limits on when coaches can contact recruits. The only standardized rule for D3 schools is that coaches are not allowed to meet with recruits or their families off-campus until the athlete has completed their sophomore year of high school.', type: CONTENT_TYPES.regular },
  ]
}
