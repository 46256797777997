import React, { useMemo } from "react";
import cn from "classnames";
import Text from "../.text";
import styles from "./styles.module.css";
import Button from "../button";
import Title from "../.title";
import DatabaseMap from "../../assets/images/database-map-2.png";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";

const ProductCard = ({ titleContent, line1, line2, extraContent, variant }) => {
  const history = useHistory();

  const variantStyle = useMemo(() => {
    if (variant === "1") return styles.one;
    if (variant === "2") return styles.two;
    if (variant === "3") return styles.three;
  }, [variant]);

  return (
    <div className={styles.container}>
      <div className={styles.seperateContainer}>
        {variant === "2" ? (
          <img className={styles.imgContainer} src={DatabaseMap} alt="See Demo" onClick={() => history.push(BASE_ROUTES.database)} />
        ) : null}
        <div className={styles.cardContainer}>
          <Title>{titleContent}</Title>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>
          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}
          <div className={variantStyle} />
        </div>

        {variant === "1" || variant === "3" ? (
          <img className={styles.imgContainer} src={DatabaseMap} alt="See Demo" onClick={() => history.push(BASE_ROUTES.database)} />
        ) : null}

      </div>

      <div className={styles.inBetweenContainer}>
        {variant === "2" ? (
          <img className={styles.imgContainer} src={DatabaseMap} alt="See Demo" onClick={() => history.push(BASE_ROUTES.database)} />
        ) : null}
        <div className={styles.cardContainer}>
          <div className={styles.titleContainer} >
            <Title>{titleContent}</Title>
            <div className={variantStyle} />
          </div>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>
          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}

        </div>
        {variant === "1" || variant === "3" ? (
          <img className={styles.imgContainer} src={DatabaseMap} alt="See Demo" onClick={() => history.push(BASE_ROUTES.database)} />
        ) : null}


      </div>

      <div className={styles.stackedContainer}>
        <div className={styles.stackedCardContainer}>
          <div className={variantStyle} />
          <Title style={{ marginBottom: "10px" }}>{titleContent}</Title>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>


          <img className={styles.imgContainer} src={DatabaseMap} alt="See Demo" onClick={() => history.push(BASE_ROUTES.database)} />


          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}
        </div>

      </div>
    </div>
  );
};

export default React.memo(ProductCard);
