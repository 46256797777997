import React from "react";
import Text from "../../../components/text";
import styles from './styles.module.css';

const Rating = ({ question, disabled, value, note, onChangeValue, onChangeNote }) => {
  if (!question) return null;
  const equalToOrLess = question.equalToOrLess;

  const onInnerChangeValue = (questionKey, index) => {
    onChangeValue(questionKey, index);
    if (equalToOrLess === undefined || index === undefined || index + 1 > equalToOrLess) {
      onChangeNote(questionKey, undefined);
    }
  }

  const scoreNotSatisfactory = (equalToOrLess !== undefined && value !== undefined && value + 1 <= equalToOrLess);
  const showNotes = note !== undefined || scoreNotSatisfactory;

  return (
    <div className={styles.container}>
      <div className={styles.ratesContainer}>
        {question.rates.map((rate, index) => (
          <div className={styles.rateContainer} key={`${question.key}-${index}`}>
            <input className={styles.radio} type="radio" name={question.key} disabled={rate === null} checked={value === index} onChange={disabled ? null : () => onInnerChangeValue(question.key, index)} />
            <div>
              <Text className={styles.rateText}>{rate}</Text>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.showNotesContainer}>
        {scoreNotSatisfactory ? null : (
          <input type="checkbox" checked={showNotes} onChange={() => onChangeNote(question.key, showNotes ? undefined : "")} disabled={disabled} />
        )}
        {showNotes ? (
          <textarea className={styles.notes} readOnly={disabled} value={note} onChange={evt => onChangeNote(question.key, evt.target.value)} />
        ) : (
          <Text>Show Notes</Text>
        )}
      </div>
    </div>
  )
}


export default React.memo(Rating);
