import React from "react";
import ChevronDownWhiteIcon from "../../../assets/icons/chevron-down-white.svg";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg";
import ChevronUpWhiteIcon from "../../../assets/icons/chevron-up-white.svg";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg";
import FilterArrowUpWhite from "../../../assets/icons/filter-arrow-up-white.svg";
import FilterArrowUp from "../../../assets/icons/filter-arrow-up.svg";
import FilterArrowDownWhite from "../../../assets/icons/filter-arrow-white.svg";
import FilterArrowDown from "../../../assets/icons/filter-arrow.svg";
import { useFilter } from "../../../context/filter";
import { getClassName, getTextClassName } from "../../../utils/dark-mode";
import styles from "./styles.module.css";

const FilterSection = ({ title, isOpen, children, onToggle, isState }) => {
  const { isDark } = useFilter();
  return (
    <div className={styles.container}>
      <div
        className={getClassName(styles, "titleContainer", isDark)}
        onClick={onToggle}
      >
        {!isOpen && (
          <img
            src={isDark ? FilterArrowDown : FilterArrowDownWhite}
            width={18}
            // height={10}
            alt="Down"
          />
        )}
        {isOpen && (
          <img
            src={isDark ? FilterArrowUp : FilterArrowUpWhite}
            width={18}
            // height={10}
            alt="Up"
          />
        )}
        <div className={getTextClassName(styles.title, isDark)}>{title}</div>
      </div>
      {isOpen && (
        <div
          className={`${styles.contentContainer} ${
            isState && styles.stateContainer
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default React.memo(FilterSection);
