import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { INVITE_FROM, INVITE_STATUS, USER_PLANS } from "../../../../common/constants";
import { BASE_ROUTES } from "../../../../common/route";
import { acceptCoachPlan, refuseCoachPlan } from "../../../../common/services/firebase/coach";
import Loading from "../../../../Dashboard/components/loading";
import Text from "../../../../Dashboard/components/text";
import ExpireCard from "../../../../Dashboard/pages/plans/expire-card";
import PlanCard from "../../../../Dashboard/pages/plans/plan-card";
import Button from "../../../components/button";
import Input from "../../../components/input";
import styles from "./styles.module.css";

const CoachInvite = ({ invite, stripeInfo, extraInfo, }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userPlan = stripeInfo?.userPlan;
  const subscription = stripeInfo?.subscription;
  const user = stripeInfo?.user;
  const coachUser = extraInfo?.coachUser;

  if (invite.status !== INVITE_STATUS.SENT) return (
    <div>
      <Text>Invite no longer valid. Ask the coach to send you the invite again</Text>
    </div>
  );

  if (!coachUser || !invite.id) return (
    <div>
      <Text>Something went wrong. Ask the coach to send you the invite again</Text>
    </div>
  );

  const onAccept = async () => {
    try {
      if (!user && (!firstName || !lastName || !password || !retypePassword)) return toast.error("All fields are required");
      if (password?.length && password !== retypePassword) return toast.error("Passwords don't match");
      setLoading(true);
      await acceptCoachPlan(
        invite.id,
        invite.coachPlan,
        user || { firstName, lastName, email: invite.email, password },
        subscription
      );
      toast.success("Inivite accepted");
      history.push(BASE_ROUTES.login);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  const onDecline = async () => {
    setLoading(true);
    try {
      await refuseCoachPlan(invite.id);
      toast.success("Inivite not accepted");
      history.push(BASE_ROUTES.plans);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  return (
    <div>
      <Text variant="h2">Would you like to join coach's {coachUser.firstName} {coachUser.lastName} Coach Plan?</Text>
      <div className={styles.contentContainer}>
        {invite.userId ? null : (
          <div className={styles.signUpContainer}>
            <div className={styles.doubleCell}>
              <Input variant="half" placeholder="First Name" required value={firstName} onChange={evt => setFirstName(evt.target.value)} />
              <Input variant="half" placeholder="Last Name" required value={lastName} onChange={evt => setLastName(evt.target.value)} />
            </div>
            <Input placeholder="Email" value={invite.email} disabled />
            <Input placeholder="Password" required value={password} onChange={evt => setPassword(evt.target.value)} type="password" />
            <Input placeholder="Re-type password" required value={retypePassword} onChange={evt => setRetypePassword(evt.target.value)} type="password" />
          </div>
        )}
        <div className={styles.cardsContainer}>
          <PlanCard planKey={USER_PLANS.COMPETITOR.key} hideButton hidePrice title="Coach Plan" />
          {userPlan ? <PlanCard hideButton hidePrice title="Current Plan" planKey={userPlan?.plan} /> : null}
          {subscription ? <ExpireCard date={Number(`${subscription.current_period_end}000`)} /> : null}
        </div>
      </div>
      {userPlan ? (
        <div className={styles.acceptMessageContainer}>
          <Text>* By accepting this you confirm you are aware that you will not be reimbursed by the time you have left on your current plan</Text>
        </div>
      ) : null}
      <div className={styles.acceptButtonsContainer}>
        <Button variant="outline" onClick={onDecline}>Decline</Button>
        <Button onClick={onAccept}>Accept</Button>
        {loading ? <Loading /> : null}
      </div>
    </div >
  );
};

const InvitesComponent = ({ invite, stripeInfo, extraInfo }) => {
  return (
    <div>
      {invite.from === INVITE_FROM.COACH_PLAN ? <CoachInvite invite={invite} stripeInfo={stripeInfo} extraInfo={extraInfo} /> : null}
    </div>
  )
};

export default React.memo(InvitesComponent);
