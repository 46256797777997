import _ from "lodash";

export const getVideosInFixedCategories = (videos = []) => {
  const featuredVideos = [];
  const shortVideos = [];
  const fullInterviewVideos = [];
  const videosById = videos.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});
  for (let i = 0; i < videos.length; i++) {
    const video = videos[i];
    if (video.featured && featuredVideos.length < 10) {
      featuredVideos.push(video);
    }
    if (video.shorts && shortVideos.length < 10) {
      shortVideos.push(video);
    }
    const fullInterviewVideo = videosById[video.fullInterviewVideo];
    const isFullInterviewFromOtherVideo = !!fullInterviewVideo;
    if ((isFullInterviewFromOtherVideo || video.fullInterview) && fullInterviewVideos.length < 10) {
      const videoToCompare = fullInterviewVideo || video;
      if (videoToCompare) {
        if (!fullInterviewVideos.find(v => v.id === videoToCompare.id)) {
          fullInterviewVideos.push(videoToCompare);
        }
      }
    }
  }
  return {
    featuredVideos,
    shortVideos,
    fullInterviewVideos,
  }
}

export const getVideoInProgress = (videos = [], videosInProgress) => {
  if (!videos?.length || !videosInProgress) return;
  const orderedVideosInProgress = _.filter(videosInProgress, video => !!video.id).sort((a, b) => b._updatedAt - a._updatedAt);
  for (let i = 0; i < orderedVideosInProgress.length; i++) {
    const vip = orderedVideosInProgress[i];
    const video = videos.find(v => v.id === vip.id);
    return {
      ...video,
      currentTime: vip.currentTime,
    }
  }
}
