import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../common/route";
import { getCategories, getTags, getVideos } from '../../../common/services/firebase/vault';
import Button from "../../components/button";
import Text from "../../components/text";
import styles from './styles.module.css';
import VideoCard from "./video-card";

const Vault = () => {
  const history = useHistory();
  const [loadingVideos, setLoadingVideos] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [videos, setVideos] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setLoadingVideos(true);
    getVideos({ showDeleted: false })
      .then(setVideos)
      .finally(() => setLoadingVideos(false));
  }, []);

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setTags)
      .finally(() => setLoadingTags(false));
  }, []);

  useEffect(() => {
    setLoadingCategories(true);
    getCategories({ showDeleted: false })
      .then(setCategories)
      .finally(() => setLoadingCategories(false));
  }, []);

  if (loadingVideos || loadingTags || loadingCategories) return <Text>Loading...</Text>;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.titleSectionContainer}>
          <Text className={styles.title}>Vault</Text>
          <Button onClick={() => history.push(ADMIN_ROUTES.newVideo)}>
            New Video
          </Button>
        </div>
        <div className={styles.titleSectionContainer}>
          <Button onClick={() => history.push(ADMIN_ROUTES.vaultTags)}>
            Manage Tags
          </Button>
          <Button onClick={() => history.push(ADMIN_ROUTES.vaultCategories)}>
            Manage Categories
          </Button>
          <Button onClick={() => history.push(ADMIN_ROUTES.vaultTopics)}>
            Manage Topics
          </Button>
        </div>
      </div>
      <div>
        {videos.length ? videos.map(video => <VideoCard key={video.id} video={video} vaultTags={tags} categories={categories} />) : (
          <Text>No videos created</Text>
        )}
      </div>
    </div>
  )
};

export default React.memo(Vault);
