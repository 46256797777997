import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { THUMBNAIL_URL } from "../../../common/constants";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { replaceRoute } from "../../../common/utils/routes";
import { secondsToHms } from "../../../common/utils/time";
import Icon, { ICONS_NAME } from "../icon";
import Text from "../text";
import styles from "./styles.module.css";

const ContinueWatching = ({ video, onClick, className }) => {
  const history = useHistory();
  
  if (!video) return null;

  const onContinueWatchingClick = () => {
    history.push(replaceRoute(DASHBOARD_ROUTES.videoDetails, ['id', video.id]));
  };

  return (
      <div className={cn(styles.container, className)} onClick={onClick || onContinueWatchingClick}>
        <Text variant="h2">Continue Watching</Text>
        <div className={styles.videoInProgressCard}>
          <img src={THUMBNAIL_URL.replace('{videoId}', video.videoId)} alt="thumbnail" className={styles.videoInProgressImage} />
          <div className={styles.videoInProgressCardContent}>
            <Text variant="h3">{video.title}</Text>
            <Text variant="description">{video.description}</Text>
            <div className={styles.videoInProgressFooter}>
              {/* <ExtraLinkButton video={video} /> */}
              <div className={styles.iconContainer}>
                <Icon name={ICONS_NAME.playButton} alt="PlayIcon" size={35} />
              </div>
              <Text>{secondsToHms(video.duration - video.currentTime)} left</Text>
            </div>
          </div>
        </div>
      </div>
  )
};

export default React.memo(ContinueWatching);
