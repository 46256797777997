import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading1 from "../.heading1";
import cn from "classnames";
import Heading2 from "../.heading2";
import Youtube from "../../assets/icons/youtube.svg"
import Insta from "../../assets/icons/instagram.svg"
import Twitter from "../../assets/icons/twitter.svg"
import LinkedIn from "../../assets/icons/linkedin.svg"

const AboutCard = ({ headshot, name, description, extra, linkedIn, youtube, insta, twitter }) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img
          className={styles.headshot}
          src={headshot}
          alt="profile picture"
        />
        {extra ? (
          <div>{extra}</div>
        ) : null}
        <div className={styles.socialsContainer}>
          {linkedIn ? (
            <a href={linkedIn} target="_blank">
              <img
                className={styles.socials}
                src={LinkedIn}
                alt="linkedin"
              />
            </a>
          ) : null}
          {youtube ? (
            <a href={youtube} target="_blank">
              <img
                className={styles.socials}
                src={Youtube}
                alt="linkedin"
              />
            </a>
          ) : null}
          {insta ? (
            <a href={insta} target="_blank">
              <img
                className={styles.socials}
                src={Insta}
                alt="linkedin"
              />
            </a>
          ) : null}
          {twitter ? (
            <a href={twitter} target="_blank">
              <img
                className={styles.socials}
                src={Twitter}
                alt="linkedin"
              />
            </a>
          ) : null}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <Heading2>{name}</Heading2>
        <Text>{description}</Text>
      </div>
    </div>
  );
};

export default React.memo(AboutCard);
