import React, { useEffect, useState } from "react";
import FilterWhiteIcon from "../../assets/icons/filter-white.svg";
import FilterIcon from "../../assets/icons/filter.svg";
import PreviousWhiteIcon from "../../assets/icons/previous-white.svg";
import PreviousIcon from "../../assets/icons/previous.svg";
import RecentsWhiteIcon from "../../assets/icons/recents-white.svg";
import RecentsIcon from "../../assets/icons/recents.svg";
import SavedIcon from "../../assets/icons/save-black.svg";
import SavedWhiteIcon from "../../assets/icons/save-white.svg";
import { useFilter } from "../../context/filter";
import { getContainerClassName, getTextClassName } from "../../utils/dark-mode";
import DarkModeToggle from "../dark-mode-toggle";
import ClearDirections from "../filters/clear-directions";
import FilterSection from "../filters/filter-section";
import { RecentsPopup } from "../filters/recents";
import { SavedPopup } from "../filters/saved";
import { SchoolFilterPopup } from "../filters/school-filter";
import Distance from "../filters/school-filter/distance";
import Division from "../filters/school-filter/division";
import Search from "../filters/search";
import Popup from "../popup";
import styles from "./styles.module.css";
import Text from "../../../common/components/text";

const SchoolFilter = ({ onClick, isDark }) => (
  <div
    onClick={onClick}
    className={`${styles.schoolFilterContainer} ${getContainerClassName(
      styles.iconContainer,
      isDark
    )}`}
  >
    <Text className={getTextClassName(styles.filterText, isDark)}>Filter</Text>
    <img
      src={isDark ? FilterIcon : FilterWhiteIcon}
      width={22}
      height={22}
      alt="filter"
    />
  </div>
);

const Saved = ({ onClick, isDark }) => (
  <div
    onClick={onClick}
    className={`${styles.savedContainer} ${getContainerClassName(
      styles.iconContainer,
      isDark
    )}`}
  >
    <img
      src={isDark ? SavedIcon : SavedWhiteIcon}
      width={22}
      height={22}
      alt="saved"
    />
  </div>
);

const Recents = ({ onClick, isDark }) => (
  <div
    onClick={onClick}
    className={`${styles.recentsContainer} ${getContainerClassName(
      styles.iconContainer,
      isDark
    )}`}
  >
    <img
      src={isDark ? RecentsIcon : RecentsWhiteIcon}
      width={22}
      height={22}
      alt="recents"
    />
  </div>
);

const Previous = ({ onClick, isDark }) => (
  <div
    onClick={onClick}
    className={`${styles.previousContainer} ${getContainerClassName(
      styles.iconContainer,
      isDark
    )}`}
  >
    <img
      src={isDark ? PreviousIcon : PreviousWhiteIcon}
      width={22}
      height={22}
      alt="previous"
    />
  </div>
);

const MobileFilters = ({ isDemo }) => {
  const [openedPopup, setOpenedPopup] = useState();
  const [selectedSavedSchool, setSelectedSavedSchool] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSchoolNotesOpen, setIsSchoolNotesOpen] = useState(false);
  const [filtersOpened, setFiltersOpened] = useState({ division: true });
  const { goToPreviousSchool, saveSchoolNotes, isDark } = useFilter();

  const onTogglePopup = (popupName) => {
    setOpenedPopup((prev) => (prev === popupName ? undefined : popupName));
  };

  const onOpenSchoolNotes = (school) => {
    setSelectedSavedSchool(school);
    setIsSchoolNotesOpen(true);
  };

  useEffect(() => {
    if (selectedSavedSchool) {
      const timeout = setTimeout(() => {
        saveSchoolNotes(selectedSavedSchool);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [selectedSavedSchool?.notes]);

  const onChangeNotes = (newNotes) => {
    setSelectedSavedSchool((prev) => ({
      ...prev,
      notes: newNotes,
    }));
  };

  const onToggleFilterSection = (filterName) => {
    setFiltersOpened((prev) => {
      if (prev[filterName]) delete prev[filterName];
      else prev[filterName] = true;
      return { ...prev };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topMenu}>
        <Search />
        <SchoolFilter
          onClick={() => onTogglePopup("schoolFilter")}
          isDark={isDark}
        />
      </div>

      <div className={styles.sideMenu}>
        <DarkModeToggle />
        <Previous onClick={goToPreviousSchool} isDark={isDark} />
        <Recents onClick={() => onTogglePopup("recents")} isDark={isDark} />
        <Saved onClick={() => onTogglePopup("saved")} isDark={isDark} />
      </div>
      <ClearDirections isDemo={isDemo} />
      {!!openedPopup?.length && (
        <div className={styles.popup}>
          <Popup
            expandOnBottom
            onClose={() => setOpenedPopup(undefined)}
            onOpenFilter={
              openedPopup === "saved" ? () => setIsFilterOpen(true) : undefined
            }
          >
            {openedPopup === "schoolFilter" && <SchoolFilterPopup />}
            {openedPopup === "saved" && (
              <SavedPopup
                isDemo={isDemo}
                onOpenSchoolNotes={onOpenSchoolNotes}
              />
            )}
            {openedPopup === "recents" && <RecentsPopup isDemo={isDemo} />}
          </Popup>
          <div style={{ display: "flex" }}>
            {isFilterOpen && (
              <Popup
                className={styles.extraPopupContainer}
                onClose={() => setIsFilterOpen(false)}
              >
                <FilterSection
                  title="Division"
                  isOpen={filtersOpened.division}
                  onToggle={() => onToggleFilterSection("division")}
                >
                  <Division fromSaved />
                </FilterSection>
                <FilterSection
                  title="Distance"
                  isOpen={filtersOpened.distance}
                  onToggle={() => onToggleFilterSection("distance")}
                >
                  <Distance fromSaved />
                </FilterSection>
              </Popup>
            )}
            {isSchoolNotesOpen && (
              <Popup
                className={styles.extraPopupContainer}
                onClose={() => setIsSchoolNotesOpen(false)}
              >
                <div>Notes</div>
                <textarea
                  value={selectedSavedSchool?.notes || ""}
                  onChange={(evt) => onChangeNotes(evt.target.value)}
                  className={styles.notes}
                />
              </Popup>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(MobileFilters);
