import cn from 'classnames';
import React from "react";
import Icon from '../icon';
import Text from '../text';
import styles from './styles.module.css';

const Button = ({
  className,
  children,
  variant = "primary",
  shape = "round",
  onClick,
  disabled,
  iconName,
  iconSize = 15,
  ...props
}) => {

  return (
    <div
      className={cn(
        styles.container,
        styles[variant],
        className,
        {
          [styles.disabled]: disabled,
          [styles[`${shape}Shape`]]: shape,
        }
      )}
      onClick={disabled ? null : onClick}
      {...props}
    >
      <div className={cn(styles.contentContainer, { [styles.showGap]: !!children && iconName })}>
        {iconName ? <Icon name={iconName} size={iconSize} alt="buttonIcon" /> : null}
        <Text>{children}</Text>
      </div>
    </div>
  );
};

export default React.memo(Button);