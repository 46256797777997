import React, { useRef, useState } from "react";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import RecentsIcon from "../../../assets/icons/recents.svg";
import RecentsIconWhite from "../../../assets/icons/recents-white.svg";
import DirectionIcon from "../../../assets/icons/direction-black.svg";
import DirectionWhiteIcon from "../../../assets/icons/direction.svg";
import SaveDarkOutline from "../../../assets/icons/save-outline-dark.svg";
import SaveWhiteOutline from "../../../assets/icons/save-outline-white.svg";
import SaveRedIcon from "../../../assets/icons/save-filled.svg";
import SaveWhiteIcon from "../../../assets/icons/save-filled-white.svg";
import { useFilter } from "../../../context/filter";
import {
  getClassName,
  getContainerClassName,
  getTextClassName,
} from "../../../utils/dark-mode";
import Popup from "../../popup";
import styles from "./styles.module.css";
import classNames from "classnames";

export const RecentsPopup = ({ isDemo }) => {
  const {
    savedSchools,
    recentSchools,
    goToSelectedSchool,
    saveSchool,
    removeSavedSchool,
    setSchoolDirection,
    isDark,
  } = useFilter();

  function hasSchool(school) {
    for (let i = 0; i < savedSchools.length; i++) {
      if (savedSchools[i].name === school.name) {
        return true;
      }
    }
    return false;
  }

  function handleDemoClick() {
    document.getElementById("saveButton").classList.add(styles.wiggle);
    setTimeout(
      () =>
        document.getElementById("saveButton").classList.remove(styles.wiggle),
      200
    );
  }

  return (
    <>
      {!recentSchools.length && (
        <div className={getTextClassName(styles.emptyContainer, isDark)}>
          See your recently viewed schools here.
        </div>
      )}
      {recentSchools.map((school) => (
        <div
          className={getClassName(styles, "savedSchoolContainer", isDark)}
          onClick={() => goToSelectedSchool(school)}
          key={`recent-school-${school.name}`}
        >
          <div className={getTextClassName(styles.schoolName, isDark)}>
            {school.name}
          </div>
          <div className={styles.buttonsContainer}>
            <div
              className={classNames(
                styles.actionButton,
                styles.directionsButton
              )}
              onClick={() => setSchoolDirection([school.lat, school.lng])}
            >
              <img
                src={DirectionWhiteIcon}
                alt="directions"
                className={styles.icon}
              />
              {/* Directions */}
            </div>
            <div
              id="saveButton"
              className={classNames(styles.actionButton, styles.saveButton)}
              onClick={() =>
                isDemo
                  ? handleDemoClick()
                  : hasSchool(school)
                  ? removeSavedSchool(school.name)
                  : saveSchool(school)
              }
            >
              <img
                src={hasSchool(school) ? SaveRedIcon : SaveWhiteOutline}
                alt="save"
                className={styles.icon}
              />
              {/* Directions */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const Recents = ({ isDemo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { goToPreviousSchool, isDark } = useFilter();
  const ref = useRef(null);
  onClickOutside(ref, () => setIsOpen(false));

  const toggleOpen = () => setIsOpen((prev) => !prev);

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={getContainerClassName(styles.innerContainer, isDark)}
        onClick={toggleOpen}
      >
        <div className={styles.contentContainer}>
          {isOpen ? (
            <img
              src={isDark ? RecentsIcon : RecentsIconWhite}
              width={20}
              height={20}
              alt="Up"
            />
          ) : (
            <img
              src={isDark ? RecentsIcon : RecentsIconWhite}
              width={20}
              height={20}
              alt="recents"
            />
          )}
          <div
            className={classNames(
              getTextClassName("", isDark),
              styles.textContainer
            )}
          >
            Recently Viewed
          </div>
        </div>
      </div>
      {isOpen && (
        <Popup
          className={styles.popupContainer}
          expandOnBottom
          onClose={() => setIsOpen(false)}
        >
          <RecentsPopup isDemo={isDemo} />
        </Popup>
      )}
    </div>
  );
};

export default React.memo(Recents);
