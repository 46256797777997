import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { httpsCallable } from "../../../../common/services/firebase";
import CloseIcon from "../../../assets/icons/close.svg";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import PaymentMethods from "../../../components/payment-methods";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const getPromotionCode = httpsCallable("getPromotionCodeFunc");

const calcDiscount = (value = 0, discountCoupon) => {
  const coupon = discountCoupon?.coupon;
  let newValue = value;
  if (coupon.amount_off) newValue = value - coupon.amount_off / 100;
  if (coupon.percent_off) newValue = value - value * (coupon.percent_off / 100);
  return newValue.toFixed(2);
};

const PromotionCodeInput = ({ loading, value, onChange }) => {
  return (
    <input
      id="discountInput"
      placeholder="Discount Code"
      value={value}
      onChange={onChange}
      className={styles.discountInput}
    />
  );
};

const CheckoutModal = ({
  title,
  description,
  children,
  loading,
  creditCard,
  showSubscriptionWarning,
  onClose,
  onConfirm,
  hideCoupon,
  plan,
  monthly,
}) => {
  const [discountCoupon, setDiscountCoupon] = useState();
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        if (!coupon.length) return setDiscountCoupon(undefined);
        setLoadingCoupon(true);
        const resp = await getPromotionCode(coupon);
        if (resp.data) {
          setDiscountCoupon(resp.data);
        }
      } catch (error) {
        console.log("error", error);
        setDiscountCoupon(undefined);
      }
      setLoadingCoupon(false);
    }, 750);
    return () => clearTimeout(timeout);
  }, [coupon]);

  const onPromotionCodeChange = (event) => {
    const { value } = event.target;
    setCoupon(value);
  };

  const checkPrice = () => {
    const prices = plan.prices;
    if (prices) {
      return prices[monthly ? "monthly" : "yearly"][0].price;
    }
    return "";
  };

  return (
    <div className={styles.container}>
      <Text style={{ marginBottom: "6px" }} variant="h1">
        {title}
      </Text>
      <Text>{description}</Text>
      <div className={styles.contentContainer}>{children}</div>
      <div className={styles.sectionContainer}>
        {creditCard ? (
          <div className={styles.paymentContainer}>
            <div>
              <Text>Default card:</Text>
              <div className={styles.card}>
                <Text>•••• •••• •••• {creditCard.last4}</Text>
                <Text variant="description">
                  {creditCard.exp_month}/{creditCard.exp_year}
                </Text>
              </div>

              <div className={styles.addPaymentContainer}>
                <Button
                  onClick={() => setShowPaymentMethodsModal(true)}
                  className={styles.changePaymentButton}
                >
                  Change Payment Method
                </Button>
              </div>
            </div>
            <div>
              {!hideCoupon ? (
                <div>
                  <Text>Coupon:</Text>
                  <div className={styles.discountInputContainer}>
                    <PromotionCodeInput
                      loading={loadingCoupon}
                      value={coupon}
                      onChange={onPromotionCodeChange}
                    />
                    {loadingCoupon ? (
                      <div className={styles.loadingContainer}>
                        <Loading />
                      </div>
                    ) : null}
                    <div
                      className={classNames(
                        styles.discountCancelButton,
                        coupon ? styles.discountCancelButtonActive : null
                      )}
                      onClick={() => setCoupon("")}
                    >
                      <img src={CloseIcon} width={14} height={14} />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <Text className={styles.danger}>Add a default payment method:</Text>
            <div className={styles.addPaymentContainer}>
              <Button
                onClick={() => setShowPaymentMethodsModal(true)}
                className={styles.changePaymentButton}
              >
                Add Payment Method
              </Button>
            </div>
          </div>
        )}

        <div className={styles.summaryContainer}>
          <div className={styles.planDiscountContainer}>
            <Text>
              {plan.label} Plan: ${checkPrice()}
            </Text>
            {discountCoupon ? (
              <div>
                <Text>
                  {coupon}: -$
                  {(
                    checkPrice() - calcDiscount(checkPrice(), discountCoupon)
                  ).toFixed(2)}
                </Text>
              </div>
            ) : null}
          </div>

          <span className={styles.divider} />

          <div className={styles.totalRow}>
            <Text>Total: </Text>
            {discountCoupon ? (
              <Text>${calcDiscount(checkPrice(), discountCoupon)}</Text>
            ) : (
              <Text>${checkPrice()}</Text>
            )}
          </div>
          <Text style={{ color: "#707070" }}>
            {monthly ? "paid monthly" : "paid annually"}
          </Text>
        </div>

        <Checkbox
          checked={agreeCheck}
          onChange={(evt) => setAgreeCheck(evt.target.checked)}
        >
          I verify that I am at least 18 years of age or have consulted a
          guardian and gotten their approval before purchasing this item
        </Checkbox>
        {showSubscriptionWarning ? (
          <Text style={{ color: "#777" }}>
            All sales are final, refunds will not be issued. Cancellation of a
            subscription will result in your account being downgraded to a free
            account 30 days after your last payment
          </Text>
        ) : null}
      </div>
      <div className={styles.buttonsContainer}>
        {loading ? <Loading /> : null}
        <Button variant="outlineTertiary" shape="square" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          shape="square"
          disabled={loading || !creditCard || !agreeCheck}
          onClick={() => onConfirm(coupon)}
        >
          Confirm & Pay
        </Button>
      </div>

      <Modal
        visible={showPaymentMethodsModal}
        onClose={() => setShowPaymentMethodsModal(false)}
      >
        <PaymentMethods />
      </Modal>
    </div>
  );
};

export default React.memo(CheckoutModal);
