import React from "react";
import { useDropzone } from 'react-dropzone';
import Text from "../text";

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const Dropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': ['.jpeg', '.png'] } });


  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <Text>Drop the image here ...</Text> :
          <Text>Drag 'n' drop some image here, or click to select it</Text>
      }
    </div>
  )
};

export default React.memo(Dropzone);
