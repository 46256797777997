import { createSlice } from '@reduxjs/toolkit';

export const vaultSlice = createSlice({
  name: 'vault',
  initialState: {
    lastVideosUpdate: undefined,
    lastTopicsUpdate: undefined,
    lastTagsUpdate: undefined,
    lastCategoriesUpdate: undefined,
    videos: [],
    topics: [],
    tags: [],
    categories: [],
  },
  reducers: {
    setVideos: (state, action) => {
      const oldVideos = [...state.videos];
      const videos = action.payload || [];
      if (videos.length) {
        for (let i = 0; i < videos.length; i++) {
          const video = videos[i];
          const oldVideoIndex = oldVideos.findIndex(v => v.id === video.id);
          if (oldVideoIndex !== -1) {
            oldVideos.splice(oldVideoIndex, 1);
          }
        }
        state.videos = [...videos.filter(v => !v._deleted), ...oldVideos];
        const lastUpdatedAt = videos[0]._updatedAt;
        state.lastVideosUpdate = lastUpdatedAt;
      }
    },
    setVaultTags: (state, action) => {
      const oldTags = [...state.tags];
      const tags = action.payload || [];
      if (tags.length) {
        for (let i = 0; i < tags.length; i++) {
          const tag = tags[i];
          const oldTagIndex = oldTags.findIndex(t => t.id === tag.id);
          if (oldTagIndex !== -1) {
            oldTags.splice(oldTagIndex, 1);
          }
        }
        state.tags = [...tags.filter(t => !t._deleted), ...oldTags];
        const lastUpdatedAt = tags[0]._updatedAt;
        state.lastTagsUpdate = lastUpdatedAt;
      }
    },
    setTopics: (state, action) => {
      const oldTopics = [...state.topics];
      const topics = action.payload || [];
      if (topics.length) {
        for (let i = 0; i < topics.length; i++) {
          const tag = topics[i];
          const oldTopicIndex = oldTopics.findIndex(t => t.id === tag.id);
          if (oldTopicIndex !== -1) {
            oldTopics.splice(oldTopicIndex, 1);
          }
        }
        state.topics = [...topics.filter(t => !t._deleted), ...oldTopics];
        const lastUpdatedAt = topics[0]._updatedAt;
        state.lastTopicsUpdate = lastUpdatedAt;
      }
    },
    setVaultCategories: (state, action) => {
      const oldCategories = [...state.categories];
      const categories = action.payload || [];
      if (categories.length) {
        for (let i = 0; i < categories.length; i++) {
          const category = categories[i];
          const oldCategoryIndex = oldCategories.findIndex(t => t.id === category.id);
          if (oldCategoryIndex !== -1) {
            oldCategories.splice(oldCategoryIndex, 1);
          }
        }
        state.categories = [...categories.filter(c => !c._deleted), ...oldCategories];
        const lastUpdatedAt = categories[0]._updatedAt;
        state.lastCategoriesUpdate = lastUpdatedAt;
      }
    },
  },
});

export const { setVideos, setVaultTags, setTopics, setVaultCategories } = vaultSlice.actions;

export default vaultSlice.reducer;
