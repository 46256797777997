import { useScroll } from "framer-motion";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from "../../components/button";
import ProductCard2 from "../../components/product-card2";
import Card from "../../components/test-card";
import pageStyles from "../styles.module.css";

export default function Home() {
  const history = useHistory();
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const cards = [
    <ProductCard2
      titleContent="Our Database"
      line1="Find every college across the country with our all-encompassing Database."
      line2="For any school in any division, you'll find information on every coach, camp, and more."
      extraContent={
        <div className={pageStyles.flex}>
          <Button onClick={() => history.push(BASE_ROUTES.database)}>
            Learn More
          </Button>
          <Button variant="outline" onClick={() => history.push(BASE_ROUTES.demo)}>Try Demo</Button>
        </div>
      }
      variant="1"
    />,

    <ProductCard2
      titleContent="The Vault"
      line1="The Vault is an extensive video library that answers all your questions about the recruitment process."
      line2="We'll go over topics like How To Get Noticed, Social Media, Making Your Films, and more."
      extraContent={
        <div className={pageStyles.flex}>
          <Button onClick={() => history.push(BASE_ROUTES.vault)}>
            Learn More
          </Button>
        </div>
      }
      variant="2"
    />,

    <ProductCard2
      titleContent="Film Review"
      line1="Using our coach and athlete-built formula, we create a report based on your highlight film to show you how to improve to maximize your recruitment chances."
      extraContent={
        <div className={pageStyles.flex}>
          <Button onClick={() => history.push(BASE_ROUTES.review)}>
            Learn More
          </Button>
          <Button
            onClick={() =>
              history.push({
                pathname: BASE_ROUTES.review,
                search: "?section=what-to-expect",
              })
            }
            variant="outline"
          >
            Example Report
          </Button>
        </div>
      }
      variant="3"
    />,
  ];

  return (
    <div className={pageStyles.page}>
      <Title>Test Page</Title>
      <Text>
        Lorem ipsum dolor sit amet. Et asperiores rerum ea dolorem sunt eos
        obcaecati itaque aut officiis quod ab accusamus quaerat. Qui nesciunt
        minima quo recusandae quaerat id necessitatibus assumenda aut quasi
        omnis et enim galisum qui nulla repellat ut dolore consequatur.
      </Text>

      <div ref={container}>
        {cards.map((card, i) => {
          const targetScale = 1 - (cards.length - i) * 0.05;
          return (
            <Card
              card={card}
              key={`p_${i}`}
              i={i}
              {...card}
              progress={scrollYProgress}
              range={[i * 0.25, 1]}
              targetScale={targetScale}
            />
          );
        })}
      </div>
    </div>
  );
}
