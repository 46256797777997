import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { replaceRoute } from "../../../../common/utils/routes";
import { secondsToHms } from "../../../../common/utils/time";
import { ICONS_NAME } from "../../../components/icon";
import Text from "../../../components/text";
import WidgetBaseCard from "./base-card";

const VaultWidget = ({ videoInProgress }) => {
  const history = useHistory();

  const onClick = () => {
    if (videoInProgress)
      return history.push(
        replaceRoute(DASHBOARD_ROUTES.videoDetails, ["id", videoInProgress.id])
      );
    history.push(DASHBOARD_ROUTES.vault);
  };

  return (
    <WidgetBaseCard
      title="The Vault"
      content={
        videoInProgress ? (
          <div>
            <Text variant="description">
              {secondsToHms(
                videoInProgress.duration - videoInProgress.currentTime
              )}{" "}
              left
            </Text>
            <div>
              <Text variant="description">{videoInProgress.title}</Text>
            </div>
          </div>
        ) : (
          <Text variant="description">
            Everything you need to know, all in one place{" "}
          </Text>
        )
      }
      buttonText={videoInProgress ? "Continue Watching" : "Start Watching"}
      icon={ICONS_NAME.playButton}
      onClick={onClick}
    />
  );
};

export default React.memo(VaultWidget);
