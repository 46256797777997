import React from "react";
import { logout } from "../../../common/utils/auth";
import Button from '../../components/button';
import Text from "../../components/text";

const Home = () => {
  return (
    <div>
      <Text>Home</Text>
      <Button onClick={logout}>Logout</Button>
    </div>
  )
};

export default React.memo(Home);
