import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { THUMBNAIL_URL } from "../../../../common/constants";
import { ADMIN_ROUTES } from "../../../../common/route";
import { replaceRoute } from "../../../../common/utils/routes";
import { secondsToHms } from '../../../../common/utils/time';
import Tags from '../../../components/tags';
import Text from "../../../components/text";
import styles from './styles.module.css';

const VideoCard = ({ video, vaultTags, categories }) => {
  const history = useHistory();
  const videoTags = video.tags;
  const thumbNailImage = `${THUMBNAIL_URL.replace('{videoId}', video.videoId)}`;
  const categoryName = useMemo(() => {
    if (!video.category?.length) return undefined;
    return categories?.find(c => c.id === video.category)?.name;
  }, [categories, video]);

  const onClick = () => history.push(replaceRoute(ADMIN_ROUTES.editVideo, ['id', video.id]));

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.cardContainer}>
        <img src={thumbNailImage} alt="thumbnail" className={styles.image} />
        <div>
          <Text className={styles.title}>{video.title} - {secondsToHms(video.duration)}</Text>
          <div>
            <Text>{video.description}</Text>
          </div>
        </div>
      </div>
      <Tags tags={videoTags} collectionTags={vaultTags} />
      {categoryName?.length ? (
        <div className={styles.categoryContainer}>
          <Text>Category: {categoryName}</Text>
        </div>
      ) : null}
    </div>
  )
};

export default React.memo(VideoCard);
