import React from "react";
import BasicRulesContent from "../basic-rules-content";
import ScheduleContent from "../schedule-content";

const TabContent = ({ content }) => {
  if (!content) return null;
  const {
    basicRules,
    schedule,
  } = content;
  return (
    <div>
      {!!basicRules ? <BasicRulesContent content={basicRules} /> : null}
      {!!schedule ? <ScheduleContent content={schedule} /> : null}
    </div>
  )
};

export default React.memo(TabContent);
