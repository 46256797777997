import cn from 'classnames';
import React from "react";
import styles from './styles.module.css';

const Text = ({
  className,
  children,
  style
}) => {
  return (
    <div
      className={cn(styles.container, className)}
      style={style}
    >
      {children}
    </div>
  );
};

export default React.memo(Text);