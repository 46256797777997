import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import DirectionWhiteIcon from "../../../assets/icons/direction.svg";
import NotesWhiteIcon from "../../../assets/icons/notes-white.svg";
import SaveIcon from "../../../assets/icons/save-filled-dark.svg";
import SaveIconWhite from "../../../assets/icons/save-filled-white.svg";
import TrashWhiteIcon from "../../../assets/icons/trash-white.svg";
import { useFilter } from "../../../context/filter";
import {
  getClassName,
  getContainerClassName,
  getTextClassName,
} from "../../../utils/dark-mode";
import { getDistanceFromLatLon } from "../../../utils/distance";
import Popup from "../../popup";
import FilterSection from "../filter-section";
import Distance from "../school-filter/distance";
import Division from "../school-filter/division";
import styles from "./styles.module.css";

export const SavedPopup = ({ onOpenSchoolNotes, isDemo }) => {
  console.log("SP isDemo: " + isDemo);
  const {
    savedSchools,
    setSchoolDirection,
    removeSavedSchool,
    setSelectedSchool,
    addToPreviousSchool,
    myLocation,
    savedSelectedDivision,
    savedSelectedDistance,
    isDark,
  } = useFilter();

  const filteredSavedSchools = useMemo(() => {
    return savedSchools.filter((s) => {
      if (savedSelectedDistance && myLocation) {
        const distanceFromUser = getDistanceFromLatLon(
          s.lat,
          s.lng,
          myLocation.lat,
          myLocation.lng
        );
        if (distanceFromUser > savedSelectedDistance) return false;
      }
      if (savedSelectedDivision) {
        return s.division === savedSelectedDivision;
      }
      return true;
    });
  }, [savedSchools, savedSelectedDivision, savedSelectedDistance, myLocation]);

  const onClick = (school) => {
    setSelectedSchool(school);
    addToPreviousSchool(school);
  };

  return (
    <>
      {isDemo ? (
        <>
          <div className={getTextClassName(styles.emptyContainer, isDark)}>
            Schools you save while logged in will appear here!
          </div>
          <div
            className={getClassName(styles, "savedSchoolContainer", isDark)}
            // key={`saved-school-${school.name}`}
            // onClick={() => onClick(school)}
          >
            <div className={getTextClassName(styles.schoolName, isDark)}>
              {/* {school.name} */}
              Example University
            </div>
            <div className={styles.buttonsContainer}>
              <div
                className={classNames(
                  styles.actionButton,
                  styles.directionsButton
                )}
                // onClick={() => setSchoolDirection([school.lat, school.lng])}
              >
                <img
                  src={DirectionWhiteIcon}
                  alt="directions"
                  className={styles.icon}
                />
                {/* Directions */}
              </div>

              <div
                className={classNames(styles.actionButton, styles.notesButton)}
                // onClick={() => onOpenSchoolNotes(school)}
              >
                <img src={NotesWhiteIcon} alt="notes" className={styles.icon} />
                {/* Notes */}
              </div>

              <div
                className={classNames(styles.actionButton, styles.trashButton)}
                // onClick={() => removeSavedSchool(school.name)}
              >
                <img src={TrashWhiteIcon} alt="trash" className={styles.icon} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!savedSchools.length && (
            <div className={getTextClassName(styles.emptyContainer, isDark)}>
              Save schools to quickly access them from this panel.
            </div>
          )}
          {filteredSavedSchools.map((school) => (
            <div
              className={getClassName(styles, "savedSchoolContainer", isDark)}
              key={`saved-school-${school.name}`}
              onClick={() => onClick(school)}
            >
              <div className={getTextClassName(styles.schoolName, isDark)}>
                {school.name}
              </div>
              <div className={styles.buttonsContainer}>
                <div
                  className={classNames(
                    styles.actionButton,
                    styles.directionsButton
                  )}
                  onClick={() => setSchoolDirection([school.lat, school.lng])}
                >
                  <img
                    src={DirectionWhiteIcon}
                    alt="directions"
                    className={styles.icon}
                  />
                  {/* Directions */}
                </div>

                <div
                  className={classNames(
                    styles.actionButton,
                    styles.notesButton
                  )}
                  onClick={() => onOpenSchoolNotes(school)}
                >
                  <img
                    src={NotesWhiteIcon}
                    alt="notes"
                    className={styles.icon}
                  />
                  {/* Notes */}
                </div>

                <div
                  className={classNames(
                    styles.actionButton,
                    styles.trashButton
                  )}
                  onClick={() => removeSavedSchool(school.name)}
                >
                  <img
                    src={TrashWhiteIcon}
                    alt="trash"
                    className={styles.icon}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

const Saved = ({ isDemo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSavedSchool, setSelectedSavedSchool] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSchoolNotesOpen, setIsSchoolNotesOpen] = useState(false);
  const [filtersOpened, setFiltersOpened] = useState({ division: true });
  const { saveSchoolNotes, isDark } = useFilter();
  const ref = useRef(null);
  onClickOutside(ref, () => setIsOpen(false));

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const onOpenSchoolNotes = (school) => {
    setSelectedSavedSchool(school);
    setIsSchoolNotesOpen(true);
  };

  useEffect(() => {
    if (selectedSavedSchool) {
      const timeout = setTimeout(() => {
        saveSchoolNotes(selectedSavedSchool);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [selectedSavedSchool?.notes]);

  const onChangeNotes = (newNotes) => {
    setSelectedSavedSchool((prev) => ({
      ...prev,
      notes: newNotes,
    }));
  };

  const onToggleFilterSection = (filterName) => {
    setFiltersOpened((prev) => {
      if (prev[filterName]) delete prev[filterName];
      else prev[filterName] = true;
      return { ...prev };
    });
  };

  return (
    <div ref={ref} className={styles.container}>
      {isOpen && (
        // <div  style={{ display: "flex" }}>
        <div className={styles.allPopupsContainer}>
          {isSchoolNotesOpen && (
            <Popup
              className={styles.extraPopupContainer}
              onClose={() => setIsSchoolNotesOpen(false)}
            >
              <div>Notes</div>
              <textarea
                value={selectedSavedSchool?.notes || ""}
                onChange={(evt) => onChangeNotes(evt.target.value)}
                className={styles.notes}
              />
            </Popup>
          )}

          {isFilterOpen && (
            <Popup
              className={styles.extraPopupContainer}
              onClose={() => setIsFilterOpen(false)}
            >
              <FilterSection
                title="Division"
                isOpen={filtersOpened.division}
                onToggle={() => onToggleFilterSection("division")}
              >
                <Division fromSaved />
              </FilterSection>
              <FilterSection
                title="Distance"
                isOpen={filtersOpened.distance}
                onToggle={() => onToggleFilterSection("distance")}
              >
                <Distance fromSaved />
              </FilterSection>
            </Popup>
          )}

          <Popup
            expandOnBottom
            onClose={() => setIsOpen(false)}
            className={styles.popupContainer}
            onOpenFilter={() => setIsFilterOpen(true)}
          >
            <SavedPopup isDemo={isDemo} onOpenSchoolNotes={onOpenSchoolNotes} />
          </Popup>
        </div>
      )}
      <div
        className={getContainerClassName(styles.contentContainer, isDark)}
        onClick={toggleOpen}
      >
        {isOpen ? (
          <img
            src={isDark ? SaveIcon : SaveIconWhite}
            width={18}
            height={18}
            alt="Down"
          />
        ) : (
          <img
            src={isDark ? SaveIcon : SaveIconWhite}
            width={18}
            height={18}
            alt="save"
          />
        )}
        <div
          className={classNames(
            getTextClassName("", isDark),
            styles.textContainer
          )}
        >
          Saved Schools
        </div>
      </div>
    </div>
  );
};

export default React.memo(Saved);
