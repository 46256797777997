import { child, get, ref, set } from "firebase/database";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { db, storage } from "..";

const getStorageChildRef = (child) => storageRef(storage, child);

export const getDivisionSchools = async (division) => {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `filters/division/${division}`));
  if (snapshot.exists()) {
    return snapshot.val();
  }
};

export const getSchool = async (schoolName) => {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `all/${schoolName}`));
  if (snapshot.exists()) {
    return snapshot.val();
  }
};

export const getSchoolsByName = async () => {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef,  'filters/search'));
  if (snapshot.exists()) {
    return snapshot.val();
  }
};

// ****************** ADMIN DATABASE ******************
export const uploadImage = (child, file) => uploadBytes(getStorageChildRef(child), file, { contentType: 'image/jpeg' });

export const saveAllDoc = async (doc) => {
  await set(ref(db, `all/${doc.name}`), doc);
}

export const saveDivisionDoc = async (doc, division) => {
  await set(ref(db, `filters/division/${division}/${doc.name}`), {
    temp: doc.temp,
    lat: doc.lat,
    lng: doc.lng,
    address: doc.address,
    publicPrivate: doc.publicPrivate,
    division,
    stateLoc: doc.stateLoc,
  });
}

export const saveSearchDoc = async (doc) => {
  await set(ref(db, `filters/search/${doc.name}`), {
    name: doc.name.toLowerCase()
  });
}
