import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STRIPE_KEY } from "../../../common/constants";
import { httpsCallable } from "../../../common/services/firebase";
import Text from "../text";

const stripePromise = loadStripe(STRIPE_KEY);

const getClientSecret = httpsCallable("getStripeUserSecretFunc");

const StripeLoader = ({ children }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState();

  const loadClientSecret = useCallback(async () => {
    setLoading(true);
    if (user?.stripeId) {
      const resp = await getClientSecret({ userStripeId: user.stripeId });
      setClientSecret(resp.data);
    }
    setLoading(false);
  }, [user?.stripeId]);

  useEffect(() => {
    loadClientSecret();
  }, [loadClientSecret]);

  if (loading) return <Text>Loading Plans & Payments...</Text>;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      {children}
    </Elements>
  );
};

export default React.memo(StripeLoader);
