import cn from 'classnames';
import React from "react";
import { useHistory } from 'react-router-dom';
import Icon from '../../../../Dashboard/components/icon';
import Button from '../../button';
import Text from '../../text';
import styles from './styles.module.css';

const MenuItem = ({
  title,
  iconName,
  isSelected,
  route
}) => {
  const history = useHistory();
  const onClick = () => {
    history.push(route)
  }

  return (
    <Button onClick={onClick}>
      <div className={cn(styles.container, {
        [styles.selectedMenu]: isSelected
      })}>
        {iconName ? <Icon name={iconName} alt="Icon" /> : null}
        {/* {Icon ? <img src={Icon} alt="Icon" className={styles.icon} /> : null} */}
        <Text>{title}</Text>
      </div>
    </Button>
  )
};

export default React.memo(MenuItem);
