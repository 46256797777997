export const removeUndefined = (obj) => {
  const newObj = { ...obj };
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (obj[key] === undefined) {
      delete newObj[key];
    }
  };
  return newObj;
}