export const USERS_COLLECTION = 'users';

export const REVIEWS_COLLECTION = 'reviews';

export const REVIEWS_STRUCTURE_COLLECTION = 'reviews_structure';

export const VAULT_COLLECTION = 'vault';

export const USER_VAULT_COLLECTION = 'user_vault';

export const TAGS_COLLECTION = 'vault_tags';

export const CATEGORIES_COLLECTION = 'categories';

export const TOPICS_COLLECTION = 'topics';

export const NEWSLETTER_COLLECTION = 'newsletter';

export const NEWSLETTER_TAGS_COLLECTION = 'newsletter_tags';

export const EMAILS_COLLECTION = 'email';

export const INVITES_COLLECTION = 'invites';
