import React from "react";
import BaseText from '../../../common/components/text';
import Styles from './styles.module.css'

const Heading1 = (props) => {
  return (
    <BaseText className={Styles.container}{...props} />
  );
};

export default React.memo(Heading1);