import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  COACHES_PLAN,
  FILM_REVIEW_PRODUCT,
  PLANS_ORDER,
} from "../../../common/constants";
import { clearRedirectData } from "../../../common/redux/reducers/app-redirect";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { httpsCallable } from "../../../common/services/firebase";
import Button from "../../components/button";
import Modal from "../../components/modal";
import PlanTitle from "../../components/page-title";
import PaymentMethods from "../../components/payment-methods";
import ReviewsLeft from "../../components/reviews-left";
import Screen from "../../components/screen";
import StripeLoader from "../../components/stripe-loader";
import Text from "../../components/text";
import CancelSubscriptionCard from "./cancel-subscription-card";
import ConfirmCoachPlanModal from "./confirm-coach-plan-modal";
import ConfirmFilmReviewModal from "./confirm-film-review-modal";
import ConfirmPlanModal from "./confirm-plan-modal";
import ExpireCard from "./expire-card";
import PlanCard from "./plan-card";
import styles from "./styles.module.css";

const getStripeUserPaymentMethods = httpsCallable(
  "getStripeUserPaymentMethodsFunc"
);

const Plans = () => {
  const user = useSelector((state) => state.user);
  const { subscription, userPlan } = useSelector((state) => state.stripe);
  const redirectData = useSelector((state) => state.appRedirect.data);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const [showBuyFilmReviewModal, setShowBuyFilmReviewModal] = useState(false);
  const [showBuyCoachesPlanModal, setShowBuyCoachesPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const defaultPaymentMethod = useMemo(() => {
    if (!user.defaultPaymentMethod) return;
    return paymentMethods.find((pm) => pm.id === user.defaultPaymentMethod);
  }, [paymentMethods, user.defaultPaymentMethod]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getStripeUserPaymentMethods({ userStripeId: user.stripeId })
      .then(({ data }) => setPaymentMethods(data || []))
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  }, [user, showPaymentMethodsModal]);

  useEffect(() => {
    if (redirectData) {
      if (redirectData === COACHES_PLAN.key) {
        setShowBuyCoachesPlanModal(true);
      } else if (redirectData === FILM_REVIEW_PRODUCT.key) {
        setShowBuyFilmReviewModal(true);
      } else {
        setSelectedPlan(redirectData);
      }
      dispatch(clearRedirectData());
    }
  }, [redirectData]);

  const getMoreClick = () => {
    setShowBuyFilmReviewModal(true);
  };

  return (
    <StripeLoader>
      <>
        <Screen loading={loading}>
          <PlanTitle title="Plans & Payments" />
          {userPlan?.coachPlan ? (
            <div className={styles.coachPlanContainer}>
              <PlanCard current title="Coach Plan" hideButton hidePrice />
              <ExpireCard date={subscription?.endDate} />
            </div>
          ) : (
            <div className={styles.plansContainer}>
              {userPlan ? (
                <div className={styles.currentPlanContainer}>
                  <Text variant="h2">Current Plan</Text>
                  <div className={styles.planCardContainer}>
                    <PlanCard current onSelectSubscription={setSelectedPlan} />
                    <CancelSubscriptionCard />
                    <ReviewsLeft
                      outline
                      getMoreClick={getMoreClick}
                      className={styles.reviewsLeftContainer}
                    />
                  </div>
                </div>
              ) : null}
              <div className={styles.otherPlansContainer}>
                <Text variant="h2">{userPlan ? "Other " : ""}Plans</Text>
                <div className={styles.planCardContainer}>
                  {[...PLANS_ORDER].reverse().map((planKey) => {
                    if (userPlan?.plan === planKey) return null;
                    return (
                      <PlanCard
                        key={`other-plans-${planKey}`}
                        planKey={planKey}
                        onSelectSubscription={setSelectedPlan}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className={styles.paymentsContainer}>
            <Button
              onClick={() => setShowPaymentMethodsModal(true)}
              variant="outlinePrimary"
            >
              Payment methods
            </Button>
          </div>
          {userPlan?.userIsCoach ? (
            <div className={styles.paymentsContainer}>
              <Button
                onClick={() => history.push(DASHBOARD_ROUTES.coachPlanManager)}
                variant="outlinePrimary"
              >
                Manage Users
              </Button>
            </div>
          ) : null}
        </Screen>
        <Modal
          visible={showPaymentMethodsModal}
          onClose={() => setShowPaymentMethodsModal(false)}
        >
          <PaymentMethods />
        </Modal>
        <Modal
          visible={selectedPlan}
          onClose={() => setSelectedPlan(undefined)}
        >
          <ConfirmPlanModal
            selectedPlan={selectedPlan}
            defaultPaymentMethod={defaultPaymentMethod}
            onClose={() => setSelectedPlan(undefined)}
          />
        </Modal>
        <Modal
          visible={showBuyFilmReviewModal}
          onClose={() => setShowBuyFilmReviewModal(false)}
        >
          <ConfirmFilmReviewModal
            defaultPaymentMethod={defaultPaymentMethod}
            onClose={() => setShowBuyFilmReviewModal(false)}
          />
        </Modal>
        <Modal
          visible={showBuyCoachesPlanModal}
          onClose={() => setShowBuyCoachesPlanModal(false)}
        >
          <ConfirmCoachPlanModal
            defaultPaymentMethod={defaultPaymentMethod}
            onClose={() => setShowBuyCoachesPlanModal(false)}
          />
        </Modal>
      </>
    </StripeLoader>
  );
};

export default React.memo(Plans);
