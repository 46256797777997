import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { COACHES_PLAN, INVITE_STATUS, INVITE_STATUS_LABEL } from "../../../common/constants";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { getUsersInCoachPlan, inviteUserToCoachPlan, removeUserInvite } from "../../../common/services/firebase/coach";
import { isValidEmail } from "../../../common/utils/auth";
import Button from "../../components/button";
import Icon, { ICONS_NAME } from "../../components/icon";
import Input from "../../components/input";
import Loading from "../../components/loading";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import styles from "./styles.module.css";

const CoachPlanManager = () => {
  const [usersInPlan, setUsersInPlan] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState("");
  const { subscription, userPlan } = useSelector(state => state.stripe);
  const user = useSelector(state => state.user);
  const coachPlan = useMemo(() => {
    if (!userPlan?.userIsCoach) return;
    return COACHES_PLAN.prices.find(price => price.id === subscription.metadata.priceId);
  }, [subscription?.metadata?.priceId, userPlan?.userIsCoach]);
  const notRemovedUsersInPlan = useMemo(() => {
    return (usersInPlan || []).filter(u => u.status !== INVITE_STATUS.REMOVED);
  }, [usersInPlan]);

  const loadUsers = useCallback(async () => {
    if (!subscription?.id || !userPlan?.userIsCoach || !user?.id) return;
    setLoadingUsers(true);
    getUsersInCoachPlan(subscription?.id)
      .then(resp => setUsersInPlan(resp.filter(u => u.id !== user.id)))
      .catch(error => console.log('error', error))
      .finally(() => setLoadingUsers(false));
  }, [subscription?.id, user.id, userPlan?.userIsCoach]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  if (!userPlan?.userIsCoach) return <Redirect to={DASHBOARD_ROUTES.base} />;

  const onInviteUser = async () => {
    try {
      if (notRemovedUsersInPlan.length >= coachPlan.totalAccounts) return toast.error("You've reached the maximum number of athletes in the plan.")
      if (!isValidEmail(emailToInvite)) return toast.error("Please, enter a valid email");
      setAddingUser(true);
      await inviteUserToCoachPlan(emailToInvite.trim(), user.id, user.coachPlan, usersInPlan, `${user.firstName} ${user.lastName}`);
      loadUsers();
      toast.success("Invite sent");
    } catch (error) {
      toast.error(error.message);
      console.log('error', error, error.message);
    }
    setEmailToInvite("");
    setAddingUser(false);
  }

  const onRemoveUserInvite = async (inviteId, userId) => {
    try {
      if (!window.confirm("Are you sure you want to remove this user from your plan? When you remove a user, you won't be able to add them again for another month")) return;
      setDeletingUser(true);
      await removeUserInvite(inviteId, userId, usersInPlan, `${user.firstName} ${user.lastName}`);
      setDeletingUser(false);
      loadUsers();
    } catch (error) {
      console.log('error', error, error.message);
    }
  }

  return (
    <Screen loading={loadingUsers}>
      <PageTitle title="Coach Plan" />
      <Text variant="description">In this page you can manage the users that have access to the platform through your plan</Text>
      <div className={styles.usersInPlanContainer}>
        <Text variant="h3">Users in plan: {notRemovedUsersInPlan.length}/{coachPlan.totalAccounts}</Text>
        <div className={styles.inviteUserContainer}>
          {addingUser ? <Loading /> : null}
          <Input value={emailToInvite} onChange={evt => setEmailToInvite(evt.target.value)} />
          <Button variant="outlinePrimary" onClick={onInviteUser} disabled={addingUser}>Invite User</Button>
        </div>
      </div>
      <div className={styles.usersListContainer}>
        <div className={styles.userListHeaderContainer}>
          <Text className={styles.userListColumn}>Email</Text>
          <Text className={styles.userListColumnCenter}>Sent at</Text>
          <Text className={styles.userListColumnCenter}>Status</Text>
          <div className={styles.userListColumn} />
        </div>
        {notRemovedUsersInPlan.length ? notRemovedUsersInPlan.map(userInPlan => {
          return (
            <div className={styles.userCard} key={`user-in-plan-${userInPlan.id}`}>
              <Text className={styles.userListColumn}>{userInPlan.email}</Text>
              <Text className={styles.userListColumnCenter}>{new Date(userInPlan._createdAt).toLocaleDateString()}</Text>
              <Text className={styles.userListColumnCenter}>{INVITE_STATUS_LABEL[userInPlan.status]}</Text>
              <div className={cn(styles.userListColumn, styles.removeContainer)} onClick={deletingUser ? null : () => onRemoveUserInvite(userInPlan.id, userInPlan.userId)}>
                <Icon name={ICONS_NAME.trashBin} />
              </div>
            </div>
          )
        }) : (
          <Text variant="description">Invite users to your Coach Plan</Text>
        )}
      </div>
    </Screen>
  )
};

export default React.memo(CoachPlanManager);