import React, { useEffect, useMemo, useState } from "react";
import Input from "../../../components/input";
import Modal from "../../../components/modal";
import Text from "../../../components/text";
import styles from './styles.module.css';

const CategoriesModal = ({ categories, visible, onClose, onSelectCategory }) => {
  const [search, setSearch] = useState('');
  const filteredCategories = useMemo(() => {
    const searchLC = search?.trim()?.toLowerCase();
    if (!searchLC) return categories || [];
    return categories.filter(cat => {
      const name = cat.name.trim().toLowerCase();
      return name.includes(searchLC);
    });
  }, [categories, search]);

  useEffect(() => {
    setSearch('');
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Text>New Category</Text>
          <div onClick={onClose} className={styles.closeButton}>
            <Text>X</Text>
          </div>
        </div>
        <Input value={search} onChange={evt => setSearch(evt.target.value)} />
        <div className={styles.categoriesContainer}>
          {filteredCategories.map(cat => (
            <div key={cat.id} className={styles.category} onClick={() => onSelectCategory(cat)}>
              <Text>{cat.name}</Text>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CategoriesModal);
