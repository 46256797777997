import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import Carousel from "../../components/carousel";


import { BASE_ROUTES } from "../../../common/route";
import Heading1 from "../../components/.heading1";
import Headline from "../../components/.headline";
import Title from "../../components/.title";
import Button from "../../components/button";
import Description from "../../components/description";
import Header from "../../components/header";
import IconCard from "../../components/icon-card";
import InfoCard from "../../components/info-card";
import pageStyles from "../styles.module.css";

const Database = () => {
  const history = useHistory();
  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.pageTitleContainer}>
        <Title>Our Database</Title>
      </div>
      <Header id="database" button="Try Demo" link={BASE_ROUTES.demo} />
      <Description
        id="database"
        txt="All the college information you need, in one easy-to-use place."
        btn="Get Access"
      />
      <div className={pageStyles.infocards}>
        <InfoCard
          titleContent="Easy To Use"
          content="The Database has all the information you could need about every college, all in one easy to use map."
        />
        <InfoCard
          titleContent="Coach Info"
          content="Featured in the Database is the X (Twitter) profile of each college's many coaches + a link to their official team site."
        />
        <InfoCard
          titleContent="Up-To-Date Info"
          content="By linking users directly to colleges websites we insure that our data is accurate and up to date."
        />
      </div>

      <div className={pageStyles.iconcards}>
        <IconCard iconName="coach" title={<span>Coach<br />Profiles</span>} />
        <IconCard iconName="camp" title={<span>Camp<br />Info</span>} />
        <IconCard iconName="map" title={<span>Map/<br />Distance</span>} />
        <IconCard iconName="division" title={<span>Division/<br />Conference</span>} />
        <IconCard iconName="niche" title={<span>Niche<br />Review</span>} />
        <IconCard iconName="save" title={<span>Save<br />Favorites</span>} />
      </div>
      <div className={pageStyles.iconcardsMED}>
        <div className={pageStyles.iconcardsCol}>
          <IconCard iconName="coach" title={<span>Coach<br />Profiles</span>} />
          <IconCard iconName="camp" title={<span>Camp<br />Info</span>} />
        </div><div className={pageStyles.iconcardsCol}>
          <IconCard iconName="map" title={<span>Map/<br />Distance</span>} />
          <IconCard iconName="division" title={<span>Division/<br />Conference</span>} />
        </div><div className={pageStyles.iconcardsCol}>
          <IconCard iconName="niche" title={<span>Niche<br />Review</span>} />
          <IconCard iconName="save" title={<span>Save<br />Favorites</span>} />
        </div>
      </div>
      <div className={pageStyles.iconcardsSMALL}>
        <div className={pageStyles.iconcardsCol}>
          <IconCard iconName="coach" title={<span>Coach<br />Profiles</span>} />
          <IconCard iconName="camp" title={<span>Camp<br />Info</span>} />
          <IconCard iconName="map" title={<span>Map/<br />Distance</span>} />
        </div><div className={pageStyles.iconcardsCol}>
          <IconCard iconName="division" title={<span>Division/<br />Conference</span>} />
          <IconCard iconName="niche" title={<span>Niche<br />Review</span>} />
          <IconCard iconName="save" title={<span>Save<br />Favorites</span>} />
        </div></div>

      <div className={pageStyles.centerColumn}>
        <div className={cn(pageStyles.smallSectionContainer, pageStyles.whiteGradient)}>
          <Heading1>Built With Our Other Products in Mind</Heading1>
        </div>
        <div className={pageStyles.infocardsPairContainer}>
          <div className={pageStyles.carouselInfocards}>
            <InfoCard

              link="review"
              variant="outline"
              titleContent="Film Reviews"
              content="A strong network of schools and coaches is a good start, but high quality highlight film will set you apart from the rest."
              extraContent={
                <div className={pageStyles.flex}>
                  <Button variant="darkOutline">Learn More</Button>
                </div>
              }
            />
            <InfoCard

              link="vault"
              variant="outline"
              titleContent="The Vault"
              content="The Database will help you put the things you learn in the Vault into action."
              extraContent={
                <div className={pageStyles.flex}>
                  <Button variant="darkOutline">Learn More</Button>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={pageStyles.carousel}>
        <Carousel cardsList={purchaseCards} />
      </div>


      <div className={pageStyles.sectionContainer}>
        <div className={pageStyles.centerColumn}>
          <div className={pageStyles.headlineContainer}>
            <Headline>Let’s Get Started</Headline>
          </div>
          <div className={pageStyles.flex}>
            <Button variant="outline" onClick={() => history.push(BASE_ROUTES.demo)}>Try Demo</Button>
            <Button onClick={() => history.push(BASE_ROUTES.plans)} style={{ paddingLeft: "70px", paddingRight: "70px" }}>See Plans</Button>
          </div>
        </div>
      </div>

      <div className={pageStyles.redGradient} />

    </div>
  );
};

const purchaseCards = [
  <InfoCard
    style={{ height: "170px" }}
    link="review"
    variant="outline"
    titleContent="Film"
    content="A strong network of schools and coaches is a good start, but high quality highlight film will set you apart from the rest."
    extraContent={
      <div className={pageStyles.flex}>
        <Button variant="darkOutline">Learn More</Button>
      </div>
    }
  />,
  <InfoCard
    style={{ height: "170px" }}
    link="vault"
    variant="outline"
    titleContent="The Vault"
    content="The Database will help you put the things you learn in the Vault into action."
    extraContent={
      <div className={pageStyles.flex}>
        <Button variant="darkOutline">Learn More</Button>
      </div>
    }
  />
]

export default React.memo(Database);
