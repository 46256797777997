import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpsCallable } from "../../../common/services/firebase";
import { selectDefaultPaymentMethod } from "../../../common/services/firebase/users";
import Button from "../button";
import Text from "../text";
import styles from "./styles.module.css";
import TrashIcon from "./../../assets/icons/trash-bin.svg";

const getStripeUserPaymentMethods = httpsCallable(
  "getStripeUserPaymentMethodsFunc"
);
const deleteStripeUserPaymentMethod = httpsCallable(
  "deleteStripeUserPaymentMethodFunc"
);
const getClientSecret = httpsCallable("getStripeUserSecretFunc");

const PaymentMethods = () => {
  const [addNewCard, setAddNewCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectingDefaultPM, setSelectingDefaultPM] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const user = useSelector((state) => state.user);
  const stripe = useStripe();
  const elements = useElements();

  const loadPaymentMethods = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getStripeUserPaymentMethods({
        userStripeId: user.stripeId,
      });
      setPaymentMethods(data || []);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    loadPaymentMethods();
  }, [loadPaymentMethods]);

  const submitPaymentIntent = async () => {
    setSaving(true);
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log("submitError", submitError);
        return;
      }
      const { data: clientSecret } = await getClientSecret({
        userStripeId: user.stripeId,
      });
      const { error } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        clientSecret,
      });
      console.log("error", error);
      if (!error) {
        await loadPaymentMethods();
        setAddNewCard(false);
      }
    } catch (error) {
      console.log("error", error);
    }
    setSaving(false);
  };

  const onDeletePaymentMethod = async (pmId) => {
    setDeleting(true);
    try {
      await deleteStripeUserPaymentMethod({
        paymentMethodId: pmId,
        userId: user.id,
      });
      await loadPaymentMethods();
    } catch (error) {
      console.log("error", error);
    }
    setDeleting(false);
  };

  const onSelectDefaultPaymentMethod = async (pmId) => {
    setSelectingDefaultPM(true);
    try {
      await selectDefaultPaymentMethod(user.id, pmId);
    } catch (error) {
      console.log("error", error);
    }
    setSelectingDefaultPM(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSideContainer}>
        <div className={styles.titleContainer}>
          <Text variant="h1" className={styles.title}>
            Payment Methods
          </Text>
        </div>
        <div className={styles.cardsListContainer}>
          {loading ? <Text className={styles.loading}>Loading...</Text> : null}
          {!loading && !paymentMethods?.length ? (
            <Text className={styles.loading}>
              You don't have any payment methods set up, please add a new one.
            </Text>
          ) : null}
          {paymentMethods?.map((pm) => {
            const isDefaultMethod = user.defaultPaymentMethod === pm.id;
            return (
              <div
                key={`payment-method-${pm.id}`}
                className={styles.paymentMethodContainer}
              >
                <Text className={styles.last4}>
                  **** **** **** {pm.card.last4}
                </Text>
                <div className={styles.paymentMethodButtonsContainer}>
                  {!isDefaultMethod ? (
                    <Button
                      disabled={selectingDefaultPM}
                      variant="setAsDefault"
                      onClick={() => onSelectDefaultPaymentMethod(pm.id)}
                    >
                      Set as default
                    </Button>
                  ) : (
                    <Text style={{ color: "#555" }}>Default</Text>
                  )}
                  <Button
                    disabled={deleting}
                    variant="icon"
                    onClick={() => onDeletePaymentMethod(pm.id)}
                  >
                    <img
                      className={styles.trashIcon}
                      src={TrashIcon}
                      width={16}
                      height={16}
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="tertiary"
            onClick={() => setAddNewCard((prev) => !prev)}
          >
            {addNewCard ? "Cancel" : "Add New Card"}
          </Button>
        </div>
      </div>
      {addNewCard ? (
        <div className={styles.rightSideContainer}>
          <PaymentElement />
          <div className={styles.buttonContainer}>
            <Button
              variant="tertiary"
              onClick={() => setAddNewCard((prev) => !prev)}
            >
              Cancel
            </Button>
            <Button
              disabled={saving}
              variant="danger"
              onClick={submitPaymentIntent}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(PaymentMethods);
