import React, { useEffect, useState } from "react";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import ReviewSection from "./review-section";
import styles from './styles.module.css';

const ReviewsStructureDetails = ({ reviewStructure, position, onClose, onSave, isTechnical }) => {
  const [sections, setSections] = useState(reviewStructure || []);
  const [selectedSection, setSelectedSection] = useState();

  useEffect(() => {
    // if (!!FEEDBACK_MAP[position.value]) {
    //   setSections(FEEDBACK_MAP[position.value]);
    // } else {
    setSections(reviewStructure || []);
    // }
    setSelectedSection(undefined);
  }, [reviewStructure, position]);

  const saveSection = (sectionToSave) => {
    setSections(prev => {
      const newSections = [...prev];
      const prevSectionIndex = newSections.findIndex(section => section.key === sectionToSave.key);
      if (prevSectionIndex >= 0) newSections[prevSectionIndex] = sectionToSave;
      else newSections.push(sectionToSave);
      return newSections;
    });
    setSelectedSection(undefined);
  };

  const toggleSectionActive = (sectionKey) => {
    setSections(prev => {
      const newSections = [...prev];
      const toggleSection = newSections.find(section => section.key === sectionKey);
      toggleSection.active = !toggleSection.active;
      return newSections;
    });
  };

  const onSaveStructure = () => {
    onSave(sections, position, isTechnical ? `${position.value}Technical` : position.value, isTechnical);
  }

  if (selectedSection) return <ReviewSection section={selectedSection} onClose={() => setSelectedSection(undefined)} onSave={saveSection} isTechnical={isTechnical} />;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Text className={styles.title}>{position.label}</Text>
        <Button onClick={onClose}>
          <Text className={styles.title}>X</Text>
        </Button>
      </div>
      <div className={styles.sectionsContainer}>
        {sections.map(section => {
          return (
            <div className={styles.sectionContainer} key={`section_${section.key}`}>
              <Text className={styles.sectionTitle}>{section.title}</Text>
              <Text>{(section.questions?.length || 0)} question(s)</Text>
              <div className={styles.activeContainer}>
                <input type="checkbox" checked={section.active} onChange={() => toggleSectionActive(section.key)} />
                <Text>Active</Text>
              </div>
              <Button onClick={() => setSelectedSection(section)}>Edit</Button>
            </div>
          );
        })}
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={() => setSelectedSection({})}>Add new section</Button>
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={onSaveStructure}>Save Structure</Button>
      </div>
    </div>
  )
}

export default React.memo(ReviewsStructureDetails);
