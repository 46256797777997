export const secondsToHms = (d) => {
  const dNumber = Number(d);
  const h = Math.floor(dNumber / 3600);
  let m = Math.floor(dNumber % 3600 / 60);
  if (m === 0) m = 1;

  const hours = h > 0 ? h.toString().slice(-1) + ` hour${h > 1 ? 's' : ''} ` : '';
  const minutes = m > 0 ? (`${hours.length ? ' ' : ''}0` + m).slice(-2) + " min" : '';
  return hours + minutes;
}

export const getStartAndEndOfMonth = () => {
  const date = new Date();
  return [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
}