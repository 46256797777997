import React from "react";
import styles from "./styles.module.css";

export const Coach = ({ isDemo, data, coachTitle = "Coach" }) => {
  if (!data || data?.toLowerCase() === "u") return null;
  const isNotAvailable = data.toLowerCase() === "n";

  const onClick = () => {
    if (isNotAvailable) return null;
    window.open(`https://twitter.com/${data}`, "_blank", "noreferrer");
  };

  function createRandomString(str) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789____--..";
    let result = "";
    for (let i = 0; i < str.length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  return (
    <div className={styles.quickInfo}>
      {coachTitle}:{" "}
      <span
        className={`${styles.coachInfo} ${
          !isNotAvailable && styles.clickableCoach
        } ${isNotAvailable && styles.notAvail} ${isDemo && styles.demo}`}
        onClick={!isDemo ? onClick : null}
      >
        {isNotAvailable
          ? "Not available"
          : isDemo
          ? createRandomString(data)
          : data}
      </span>
    </div>
  );
};

export default React.memo(Coach);
