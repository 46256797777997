import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_REVIEW_LABEL, POSITION, VIDEO_TYPE } from "../../../../Dashboard/constants";
import { ADMIN_ROUTES } from '../../../../common/route';
import { getUser } from '../../../../common/services/firebase/users';
import { replaceRoute } from '../../../../common/utils/routes';
import Text from "../../../components/text";
import styles from './styles.module.css';

const ReviewListItem = ({
  id,
  title,
  status,
  userId,
  videoType,
  position,
  _createdAt,
}) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  
  useEffect(() => {
    setLoading(true);
    getUser({ userId })
      .then(setUser)
      .finally(() => {
        setLoading(false);
      })
  }, [userId]);

  if (!loading && !user) return null;

  const onClick = () => {
    history.push(replaceRoute(ADMIN_ROUTES.reviewDetail, ['id', id]));
  }

  return (
    <div className={styles.container} onClick={onClick}>
      <Text className={styles.flex}>{title}</Text>
      <Text className={styles.flex}>{loading ? 'Loading...' : `${user.firstName} ${user.lastName}`}</Text>
      <Text className={styles.flex}>{POSITION[position].label}</Text>
      <Text className={styles.flex}>{VIDEO_TYPE[videoType].label}</Text>
      <Text className={styles.flex}>{ADMIN_REVIEW_LABEL[status]}</Text>
      <Text className={styles.flex}>{new Date(_createdAt).toLocaleDateString()} - {new Date(_createdAt).toLocaleTimeString()}</Text>
    </div>
  )
};

export default React.memo(ReviewListItem);
