"use client";
import styles from "../test-card/styles.module.css";
import { useTransform, motion, useScroll } from "framer-motion";
import { useRef } from "react";

const Card = ({ card, i, color, progress, range, targetScale }) => {
  const container = useRef(null);

  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div
      ref={container}
      className={styles.cardContainer}
      style={{
        width: `calc(100% + ${i * 19}px)`,
        transform: `translateX(${((i * 19) / 2) * -1}px)`,
      }}
    >
      <motion.div
        style={{
          backgroundColor: color,
          scale,
          top: `calc(30px + ${i * 25}px)`,
        }}
        className={styles.card}
      >
        {card}
      </motion.div>
    </div>
  );
};

export default Card;
