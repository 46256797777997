import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import FullLogo from '../../../Dashboard/assets/images/full-logo.png';
import { checkSelectedRouteNavBar } from '../../../common/utils/routes';
import { ADMIN_MENU } from '../../constants';
import MenuItem from './menu-item';
import styles from './styles.module.css';

const NavBar = ({ children }) => {
  const location = useLocation();
  const user = useSelector(state => state.user);
  const selectedRoute = location.pathname;

  return (
    <div className={styles.container}>
      <div className={styles.navContainer}>
        <div className={styles.logoContainer}>
          <img src={FullLogo} alt="Logo" className={styles.fullLogo} />
        </div>
        <div className={styles.adminMenuContainer}>
          {ADMIN_MENU.map(menu => {
            const isSelected = checkSelectedRouteNavBar(selectedRoute, menu.route, ...(menu.extraRoutes || []));
            if (menu.blackListRoles?.includes(user.userType)) return null;
            return (
              <MenuItem
                key={menu.key}
                title={menu.label}
                iconName={menu.icon}
                isSelected={isSelected}
                route={menu.route}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div >
  )
};

export default React.memo(NavBar);
