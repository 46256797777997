import React, { useEffect, useState } from "react";
import { USER_TYPE } from "../../../../common/constants";
import { getUsers } from "../../../../common/services/firebase/users";
import Button from "../../../components/button";
import Dropdown from '../../../components/dropdown';
import Text from "../../../components/text";
import styles from './styles.module.css';

const ReviewersSelector = ({
  currentReviewer,
  onAssignUser
}) => {
  const [reviewers, setReviewers] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState(currentReviewer === 'undefined' ? undefined : currentReviewer);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUsers({ userTypes: [USER_TYPE.REVIEWER, USER_TYPE.ADMIN] })
      .then(result => {
        setReviewers(result.map(r => ({
          value: r.id,
          label: `${r.firstName} ${r.lastName}`,
        })))
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  if (loading) return <Text>Loading Reviewers...</Text>;

  if (!reviewers.length) return <Text>No reviewers available</Text>;

  return (
    <div className={styles.container}>
      <Dropdown controlClassName={styles.dropdown} placeholder="Assign a reviewer" options={reviewers} value={selectedReviewer} onChange={option => setSelectedReviewer(option.value)} />
      <Button variant="red" onClick={() => onAssignUser(selectedReviewer)}>Assign</Button>
    </div>
  )
}

export default React.memo(ReviewersSelector);
