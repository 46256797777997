import React from "react";
import Text from "../../../components/text";
import Done from "../done";
import Loading from '../loading';
import styles from './styles.module.css';

const FileStatus = ({ file, index, onChangeDivision }) => {
  if (!file) return null;
  if (file.error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}><Text>{file.message}</Text></div>
        <div className={styles.errorIcon}>X</div>
      </div>
    )
  }
  if (file.loading) {
    return <Loading />;
  }
  if (file.done) {
    return <Done />;
  }
  
  if (file.documents?.length) {
    return (
      <div className={styles.container}>
        <Text>
          <input type="radio" checked={file.division === 'DI'} value="DI" name={`division_radio_${index}`} onChange={(evt) => onChangeDivision(evt.target.value, index)} />
          DI
        </Text>
        <Text>
          <input type="radio" checked={file.division === 'DII'} value="DII" name={`division_radio_${index}`} onChange={(evt) => onChangeDivision(evt.target.value, index)} />
          DII
        </Text>
        <Text>
          <input type="radio" checked={file.division === 'DIII'} value="DIII" name={`division_radio_${index}`} onChange={(evt) => onChangeDivision(evt.target.value, index)} />
          DIII
        </Text>
        <Text>
          <input type="radio" checked={file.division === 'NAIA'} value="NAIA" name={`division_radio_${index}`} onChange={(evt) => onChangeDivision(evt.target.value, index)} />
          NAIA
        </Text>
        <Text>
          <input type="radio" checked={file.division === 'JUCO'} value="JUCO" name={`division_radio_${index}`} onChange={(evt) => onChangeDivision(evt.target.value, index)} />
          JUCO
        </Text>
      </div>
    )
  }
  return null;
};

export default FileStatus;
