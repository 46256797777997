import cn from 'classnames';
import React from "react";
import Icon, { ICONS_NAME } from '../icon';
import Text from '../text';
import styles from './styles.module.css';

const InfoCard = ({
  containerClassname,
  className,
  titleContent,
  content,
  extraContent,
  onClick
}) => {
  return (
    <div className={cn(styles.container, containerClassname, { [styles.clickContainer]: !!onClick })} onClick={onClick}>
      <div className={cn(styles.mainContentContainer, className)}>
        <Text variant="h3">{titleContent}</Text>
        {content ? (
          <div className={styles.contentContainer}>
            <Text variant="regular">{content}</Text>
          </div>
        ) : null}
      </div>
      {onClick ? (
        <div className={styles.arrowContainer}>
          <Icon name={ICONS_NAME.arrow} />
        </div>
      ) : null}
      {extraContent ? (
        <div className={styles.extraContentContainer}>
          {extraContent}
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(InfoCard);
