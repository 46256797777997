import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_PLANS } from "../../../common/constants";
import { clearRedirectData } from "../../../common/redux/reducers/app-redirect";
import { BASE_ROUTES } from "../../../common/route";
import LogoExtended from "../../assets/icons/AA logo extended_White.svg";
import emailIcon from "../../assets/icons/email.svg";
import Text from "../.text";
import Styles from "./styles.module.css";

const Nav = (props) => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const onLogin = () => {
    dispatch(clearRedirectData());
    history.push(BASE_ROUTES.login)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.banner}>
        <img className={Styles.emailIcon} src={emailIcon} />
        {/* BANNER LINK AND TEXT. To turn off banner, go to CSS */}
        <a href={`mailto:?subject=Athletes%20After&body=Hi!%0A%0AI%20recently%20came%20across%20Athletes%20After%2C%20a%20service%20that%20helps%20high%20school%20football%20players%20get%20recruited.%0A%0AI'm%20interested%20in%20the%20Coaches%20Plan%20that%20they%20offer%2C%20which%20gives%201-year%20access%20to%20each%20athlete.%20For%20individual%20students%2C%20their%20highest%20plan%20costs%20%24${USER_PLANS.COMPETITOR.price}%20per%20month%2C%20but%20through%20the%20Coaches%20Plan%2C%20the%20school%20would%20only%20pay%20%2430-35%20per%20athlete%20for%20an%20entire%20YEAR%20of%20access.%0A%0AThese%20are%20their%203%20main%20services%3A%0A-%20The%20Database%3A%20A%20user-friendly%20map%20that%20has%20info%20on%20every%20college%20college%2C%20like%20camps%2C%20coaches%2C%20and%20more.%0A-%20The%20Video%20Vault%3A%20A%20library%20of%20videos%20that%20explain%20the%20recruiting%20process%20and%20give%20tips%20and%20strategies%20for%20getting%20recruited.%0A-%20Film%20Reviews%3A%20Film%20is%20a%20big%20part%20of%20what%20colleges%20want%20to%20see%2C%20and%20Athletes%20After%20will%20evaluate%20player%20film%20and%20make%20a%20report%20on%20what%20was%20done%20well%20and%20how%20to%20improve.%0A%0AThey%20also%20have%20info%20on%20the%20NCAA%20Rules%20%26%20Regulations%20and%20a%20weekly%20newsletter%20for%20the%20students%20with%20extra%20tips%20and%20reminders.%0A%0AAll%20of%20this%20comes%20with%20the%20Coaches%20Plan%2C%20and%20our%20players%20would%20have%20access%20to%20it%20for%20a%20year!%0A%0AHere's%20a%20link%20to%20their%20site%3A%0Ahttps%3A%2F%2Fwww.athletesafter.com%2Fcoaches-plan%2F%0A%0AThis%20would%20be%20a%20great%20investment%20in%20our%20players'%20futures%2C%20not%20to%20mention%20it%20would%20look%20great%20for%20our%20school.%20Are%20we%20able%20to%20make%20this%20happen%3F%0A`} style={{ color: "white", textDecoration: "underline" }}>Tell my school about this</a>
      </div>
      <div className={Styles.footerContainer} {...props}>
        <div className={Styles.contentContainer}>
          <div className={Styles.left}>
            <img className={Styles.logo} src={LogoExtended} alt="logo" />
            <div className={Styles.textContainer}>
              <div className={Styles.contactContainer}>
                <Text style={{ color: "var(--darkText)" }}>Contact:</Text>
                <Text style={{ color: "var(--darkText)" }}>724-888-8888</Text>
                <a href="mailto:info@athletesafter.com">info@athletesafter.com</a>
              </div>
              <Text style={{ color: "var(--darkText)" }}>Copyright © 2024 Athletes After</Text>
            </div>
          </div>
          <div className={Styles.right}>
            <div className={Styles.pagesContainer}>
              <div className={Styles.column}>
                <a onClick={() => history.push(BASE_ROUTES.database)}>Database</a>
                <a onClick={() => history.push(BASE_ROUTES.vault)}>The Vault</a>
                <a onClick={() => history.push(BASE_ROUTES.review)}>Film Review</a>
              </div>
              <div className={Styles.column}>
                <a onClick={() => history.push(BASE_ROUTES.about)}>About Us</a>
                <a href="mailto:info@athletesafter.com">Contact</a>
              </div>
              <div className={Styles.column}>
                <a style={{ color: "var(--athleteRed)" }} onClick={() => history.push(BASE_ROUTES.signup)}>Sign Up</a>
                {!user ? <a onClick={onLogin}>Log In</a> : null}
                <a onClick={() => history.push(BASE_ROUTES.plans)}>Plans</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Nav);
