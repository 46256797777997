import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { replaceRoute } from "../../../common/utils/routes";
import InfoCard from "../../components/info-card";
import { REVIEW_LABEL } from "../../constants";
import Button from "../button";
import Icon, { ICONS_NAME } from "../icon";
import Text from "../text";
import styles from "./styles.module.css";

const ReviewCard = ({ id, title, status }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(replaceRoute(DASHBOARD_ROUTES.reviewDetail, ["id", id]));
  };

  return (
    <InfoCard
      className={styles.container}
      titleContent={
        <div className={styles.titleContainer}>
          <Icon name={ICONS_NAME.googleDocs} className={styles.titleIcon} />
          <Text variant="h1">{title}</Text>
        </div>
      }
      content={<Text variant="description">{REVIEW_LABEL[status]}</Text>}
      // onClick={onClick}
      extraContent={
        <div className={styles.submissionButtonContainer}>
          <Icon name={ICONS_NAME.googleDocs} alt="submission" />
          <Button variant="tertiary" onClick={onClick}>
            Submission Details
          </Button>
        </div>
      }
    />
  );
};

export default React.memo(ReviewCard);
