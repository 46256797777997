import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FILM_REVIEW_PRODUCT } from "../../../../common/constants";
import { httpsCallable } from "../../../../common/services/firebase";
import Text from "../../../components/text";
import CheckoutModal from "../checkout-modal";

const purchaseFilmReview = httpsCallable("purchaseFilmReviewFunction");

const ConfirmFilmReviewModal = ({
  defaultPaymentMethod,
  onClose,
}) => {
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const { totalReviewsLeft } = useSelector(state => state.reviews);

  const onConfirm = async () => {
    setLoading(true);
    try {
      await purchaseFilmReview({ userId: user.id, priceId: FILM_REVIEW_PRODUCT.stripeId });
      toast.success("Film Review purchased");
    } catch (error) {
      if (error.message !== "INTERNAL") {
        toast.error(error.message);
      }
      console.log('error', error);
    }
    setLoading(false);
    onClose();
  }

  return (
    <CheckoutModal
      title="Purchase 1 Film Review"
      creditCard={defaultPaymentMethod?.card}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
      hideCoupon
    >
      <Text variant="h3">Would you like to confirm the purchase of 1 Extra Film Review for ${FILM_REVIEW_PRODUCT.price}?</Text>
      <div>
        <Text variant="description">You currently have {totalReviewsLeft} review{totalReviewsLeft === 1 ? "" : "s"} left</Text>
      </div>
    </CheckoutModal>
  )
};

export default React.memo(ConfirmFilmReviewModal);