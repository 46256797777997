import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATABASE_PERMISSION } from "../common/constants";
import { setAppState } from "../common/redux/reducers/app-state";
import RestrictedPage from "../Dashboard/components/restricted-page";
import App from "./App";
import { FilterProvider } from "./context/filter";

const EntryPoint = ({ isDemo }) => {
  const { navBarOpen: open } = useSelector((state) => state.appState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAppState({
        navBarOpen: false,
      })
    );
  }, []);

  return (
    <FilterProvider>
      <RestrictedPage restrictedTitle="The Database" allowedPlans={DATABASE_PERMISSION} dark>
        <App isDemo={isDemo} />
      </RestrictedPage>
    </FilterProvider>
  );
};

export default React.memo(EntryPoint);
