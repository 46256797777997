import cn from "classnames";
import React from "react";
import styles from './styles.module.css';

const Modal = ({
  visible,
  className,
  contentClassName,
  onClose,
  children
}) => {
  if (!visible) return null;
  return (
    <div className={cn(styles.container, className)} onClick={onClose ? onClose : null}>
      <div className={cn(styles.contentContainer, contentClassName)} onClick={evt => evt.stopPropagation()}>
        {children}
      </div>
    </div>
  )
};

export default React.memo(Modal);
