import cn from "classnames";
import React from "react";
import Text from "../text";
import styles from './styles.module.css';

const Button = ({
  className,
  contentClassname,
  children,
  ...props
}) => {
  return (
    <div className={cn(styles.container, className)} {...props}>
      <Text className={cn(styles.content, contentClassname)}>{children}</Text>
    </div>
  );
};

export default React.memo(Button);
