import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { replaceRoute } from "../../../../common/utils/routes";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import styles from './styles.module.css';

const Topics = ({ topics }) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      {topics.map(topic => {
        return (
          <div key={`topics-${topic.id}`}>
            <InfoCard
            containerClassname={styles.cardContainer}
              titleContent={topic.title}
              content={<Text variant="description">{topic.shortDescription}</Text>}
              onClick={() => history.push(replaceRoute(DASHBOARD_ROUTES.vaultTopic, ['id', topic.id]))}
            />
          </div>
        )
      })}
    </div>
  )
};

export default React.memo(Topics);
