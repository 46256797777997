import _ from "lodash";

export const getColumns = (data0, data1) => {
  const columns = [];
  for (let i = 0; i < data0.length; i++) {
    const c = data0[i];
    if (c !== 'Twitter') columns.push(c);
  }
  for (let i = 0; i < data1.length; i++) {
    const c = data1[i];
    if (c) columns.push(c);
  }
  return columns;
}

export const getRows = (data) => {
  const rows = [];
  for (let i = 2; i < data.length; i++) {
    const row = data[i];
    if (row.length) {
      rows.push(row);
    }
  }
  return rows;
}

export const prepareColumnName = (columnName) => {
  let newColumnName = columnName;
  newColumnName = _.camelCase(newColumnName);
  if (newColumnName === 'adress') newColumnName = 'address';
  if (newColumnName === 'privatePublic') newColumnName = 'publicPrivate';
  return newColumnName;
};

export const treatDoc = (doc) => {
  const newDoc = { ...doc };
  newDoc.lat = Number(newDoc.loc.split(',')[0])
  newDoc.lng = Number(newDoc.loc.split(',')[1])
  delete newDoc.loc;
  return newDoc;
}

export const getDocuments = (columns, rows) => {
  const documents = [];
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const doc = {};
    for (let j = 0; j < row.length; j++) {
      let data = row[j];
      if (typeof data === 'string' && j === 0) {
        data = data.replace('.', '');
      }
      const column = columns[j];
      const columnName = prepareColumnName(column);
      if (columnName) {
        doc[columnName] = data || null;
      }
    }
    if (doc.name?.length) {
      documents.push(treatDoc(doc));
    }
  }
  return documents;
}