import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { COACHES_PLAN, USER_PLANS } from "../../../common/constants";
import { setRedirectData } from "../../../common/redux/reducers/app-redirect";
import { BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import emailIconDark from "../../assets/icons/email dark.svg";
import Headline from "../../components/.headline";
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from "../../components/button";
import CoachesIconCard from "../../components/coaches-icon-card";
import CoachesPlanInfoCard from "../../components/coachesPlan-info-card";
import Styles from "../coaches-plan/styles.module.css";
import pageStyles from "../styles.module.css";

const CoachesPlan = () => {
  const dispatch = useDispatch();

  const onSelectCoachPlan = () => {
    dispatch(
      setRedirectData({
        route: DASHBOARD_ROUTES.plans,
        data: COACHES_PLAN.key,
      })
    );
    history.push(BASE_ROUTES.login);
  };

  const history = useHistory();
  return (
    <div className={pageStyles.page}>
      <div className={Styles.centerColumn}>
        <Title>Coaches Plan</Title>
        <div className={Styles.headlineContainer}>
          <Headline>
            The Full Package,
            <br /> for Your Whole Team
          </Headline>
        </div>
        <div className={Styles.infoCardsContainer}></div>

        <div className={Styles.iconcards}>
          <CoachesIconCard iconName="database" title="Database" />
          <CoachesIconCard iconName="vault" title="The Vault" />
          <CoachesIconCard iconName="review" title="Film Review" />
        </div>

        <div className={Styles.descriptionContainer}>
          <Text>
            Help your athletes reach their full potential, at a fraction of the
            cost.
          </Text>
          <Button
            onClick={() => history.push(BASE_ROUTES.base)}
            variant="outline"
          >
            What is Athletes After?
          </Button>
        </div>

        <div className={Styles.cardsContainer}>
          <CoachesPlanInfoCard
            titleContent="About"
            content={
              <div className={Styles.listContainer}>
                <ul>
                  <li>
                    Rather than each athlete paying a monthly fee, the coach or
                    school pays a one-time fee for everyone.
                  </li>
                  <li>
                    Each account has unlimited access to the Database, Vault and
                    Film Reviews
                  </li>
                </ul>
              </div>
            }
            extraContent={
              <Button
                variant="white"
                style={{ paddingLeft: "30px", paddingRight: "30px" }}
              >
                <a
                  style={{ display: "flex", gap: "6px", alignItems: "center" }}
                  href={`mailto:?subject=Athletes%20After&body=Hi!%0A%0AI%20recently%20came%20across%20Athletes%20After%2C%20a%20service%20that%20helps%20high%20school%20football%20players%20get%20recruited.%0A%0AI'm%20interested%20in%20the%20Coaches%20Plan%20that%20they%20offer%2C%20which%20gives%201-year%20access%20to%20each%20athlete.%20For%20individual%20students%2C%20their%20highest%20plan%20costs%20%24${USER_PLANS.COMPETITOR.price}%20per%20month%2C%20but%20through%20the%20Coaches%20Plan%2C%20the%20school%20would%20only%20pay%20%2430-35%20per%20athlete%20for%20an%20entire%20YEAR%20of%20access.%0A%0AThese%20are%20their%203%20main%20services%3A%0A-%20The%20Database%3A%20A%20user-friendly%20map%20that%20has%20info%20on%20every%20college%20college%2C%20like%20camps%2C%20coaches%2C%20and%20more.%0A-%20The%20Video%20Vault%3A%20A%20library%20of%20videos%20that%20explain%20the%20recruiting%20process%20and%20give%20tips%20and%20strategies%20for%20getting%20recruited.%0A-%20Film%20Reviews%3A%20Film%20is%20a%20big%20part%20of%20what%20colleges%20want%20to%20see%2C%20and%20Athletes%20After%20will%20evaluate%20player%20film%20and%20make%20a%20report%20on%20what%20was%20done%20well%20and%20how%20to%20improve.%0A%0AThey%20also%20have%20info%20on%20the%20NCAA%20Rules%20%26%20Regulations%20and%20a%20weekly%20newsletter%20for%20the%20students%20with%20extra%20tips%20and%20reminders.%0A%0AAll%20of%20this%20comes%20with%20the%20Coaches%20Plan%2C%20and%20our%20players%20would%20have%20access%20to%20it%20for%20a%20year!%0A%0AHere's%20a%20link%20to%20their%20site%3A%0Ahttps%3A%2F%2Fwww.athletesafter.com%2Fcoaches-plan%2F%0A%0AThis%20would%20be%20a%20great%20investment%20in%20our%20players'%20futures%2C%20not%20to%20mention%20it%20would%20look%20great%20for%20our%20school.%20Are%20we%20able%20to%20make%20this%20happen%3F%0A`}
                >
                  <img className={Styles.emailIcon} src={emailIconDark} />
                  <Text style={{ color: "var(--darkGrey)" }}>
                    Send to my School
                  </Text>
                </a>
              </Button>
            }
          />
          <CoachesPlanInfoCard
            titleContent="Pricing"
            content={
              <div>
                <Text>
                  One-time payment,
                  <br />
                  1-Year Access:
                </Text>
                {COACHES_PLAN.prices.map((plan) => (
                  <div className={Styles.pricingItem}>
                    <Text>
                      <span style={{ fontWeight: "700" }}>${plan.price}</span> /{" "}
                      {plan.totalAccounts} Accounts
                    </Text>
                    <Text style={{ color: "var(--darkText" }}>
                      (${plan.price / plan.totalAccounts} per athlete)
                    </Text>
                  </div>
                ))}
              </div>
            }
            extraContent={
              <Button
                style={{ paddingLeft: "35px", paddingRight: "35px" }}
                onClick={onSelectCoachPlan}
              >
                Get Started
              </Button>
            }
          />
        </div>
      </div>

      <div className={pageStyles.redGradient} />
      <div className={pageStyles.topRedGradient} />
    </div>
  );
};

export default React.memo(CoachesPlan);
