import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { REVIEW_STATUS } from "../../../../Dashboard/constants";
import { USER_TYPE } from "../../../../common/constants";
import { getReviewsStructure } from '../../../../common/services/firebase/reviews';
import Button from "../../../components/button";
import Text from "../../../components/text";
import Rating from "../rating";
import styles from './styles.module.css';

const FEEDBACK_ENABLED_STATUS = [
  REVIEW_STATUS.IN_REVIEW,
  REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL,
  REVIEW_STATUS.APPROVED,
];

const getTotalQuestions = (sections) => {
  let total = 0;
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.active) {
      for (let j = 0; j < section.questions.length; j++) {
        const question = section.questions[j];
        if (question.active) total += 1;
      }
    }
  }
  return total;
};

const getTotalAnswers = (answer) => {
  let total = 0;
  const answers = Object.values(answer);
  for (let i = 0; i < answers.length; i++) {
    const a = answers[i];
    if (a.value !== undefined && a.value >= 0) total += 1;
  }
  return total;
};

const ReviewFeedback = ({
  feedback,
  onSubmitFeedback,
  onAskForAdminApproval,
  onAdminApproval,
  position,
  isTechnical,
  status,
}) => {
  const [positionFeedback, setPositionFeedback] = useState([]);
  const [answer, setAnswer] = useState(feedback || {});
  const [loading, setLoading] = useState(true);
  const loggedUser = useSelector(state => state.user);

  const feedbackEnabled = FEEDBACK_ENABLED_STATUS.includes(status);

  useEffect(() => {
    setLoading(true);
    getReviewsStructure()
      .then(resp => {
        setPositionFeedback(resp.find(r => r.position === position && r.isTechnical === isTechnical)?.sections);
      })
      .finally(() => setLoading(false));
  }, [position, isTechnical]);

  const onChangeValue = (key, value) => {
    setAnswer(prev => ({
      ...prev,
      [key]: {
        ...(prev[key] || {}),
        value,
      },
    }));
  };

  const onChangeNote = (key, note) => {
    setAnswer(prev => ({
      ...prev,
      [key]: {
        ...(prev[key] || {}),
        note,
      },
    }));
  };

  if (loading) return <Text>Loading...</Text>;

  if (!positionFeedback?.length) return null;

  const checkForAllAnswers = (func, ...props) => {
    const totalQuestions = getTotalQuestions(positionFeedback);
    const totalAnswers = getTotalAnswers(answer);
    if (totalQuestions > totalAnswers) {
      const proceed = window.confirm("Not all questions have been answered, do you wish to proceed?");
      if (!proceed) return;
    }
    func(answer, ...props);
  }

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Feedback</Text>
      {positionFeedback?.length ? positionFeedback.map(pf => {
        if (!pf.active) return null;
        return (
          <div key={pf.key}>
            <Text className={styles.positionFeedbackTitle}>{pf.title}</Text>
            <div className={styles.questionsContainer}>
              {pf.questions.map(q => {
                if (!q.active) return null;
                return (
                  <div key={q.key} className={styles.questionContainer}>
                    <div className={styles.questionTitleContainer}>
                      <Text>{q.title}</Text>
                      {q.blueQuestion ? (
                        <div className={styles.blueQuestionContainer}>
                          <Text className={styles.blueQuestion}>If the athlete scores 1 in this question, all the other questions from this section will equal to 1 and only this one will be printed.</Text>
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.questionRatingContainer}>
                      <Rating question={q} value={answer[q.key]?.value} note={answer[q.key]?.note} onChangeValue={onChangeValue} onChangeNote={onChangeNote} disabled={!feedbackEnabled} />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }) : null}
      {feedbackEnabled ? (
        <div className={styles.buttonContainer}>
          <Button variant="outline" onClick={() => onSubmitFeedback(answer)}>Save Draft</Button>
          {(status === REVIEW_STATUS.IN_REVIEW && loggedUser.userType !== USER_TYPE.ADMIN) ?
            <Button onClick={() => checkForAllAnswers(onAskForAdminApproval)}>Ask for admin approval</Button> :
            null}
          {(loggedUser.userType === USER_TYPE.ADMIN && status === REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL) ?
            <Button onClick={() => onAdminApproval(answer)}>Approve review</Button> :
            null}
          <Button onClick={() => checkForAllAnswers(onSubmitFeedback, true)}>Publish Feedback</Button>
        </div>
      ) : null}
    </div>
  )
}


export default React.memo(ReviewFeedback);
