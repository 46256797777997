import React from "react";
import { useLocation } from "react-router-dom";

export const checkSelectedRouteNavBar = (selectedRoute, ...routes) => {
  if (selectedRoute && routes.length) {
    return !!routes.find(r => r === selectedRoute);
  }
  return false;
};

export const replaceRoute = (route, ...props) => {
  let replacedRoute = route;
  for (let i = 0; i < props.length; i++) {
    const replace = props[i];
    const [key, value] = replace;
    replacedRoute = replacedRoute.replace(`:${key}`, value);
  };
  return replacedRoute
}

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}