import cn from 'classnames';
import React from "react";
import Text from "../text";
import styles from './styles.module.css';

const Input = ({
  label,
  className,
  inputClassName,
  labelClassName,
  textArea = false,
  ...props
}, ref) => {
  return (
    <div className={cn(styles.container, className)}>
      {label ? <Text className={cn(styles.label, labelClassName)}>{label}</Text> : null}
      {textArea ? (
        <textarea ref={ref} className={cn(styles.input, inputClassName)} style={{ flex: "none" }} {...props} />
      ) : (
        <input ref={ref} className={cn(styles.input, inputClassName)} {...props} />
      )}
    </div>
  )
}

export default React.forwardRef(Input);
