import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  PLACEHOLDER_PASSWORD,
  updateUser,
} from "../../../common/services/firebase/users";
import { logout } from "../../../common/utils/auth";
import UserAvatar from "../../assets/icons/user-profile.svg";
import Button from "../../components/button";
import Input from "../../components/input";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import styles from "./styles.module.css";
import Plans from "../plans";

const UserSettings = () => {
  const user = useSelector((state) => state.user);
  const [firstName, setFirstName] = useState(user.firstName || "");
  const [lastName, setLastName] = useState(user.lastName || "");
  const [phone, setFirstPhone] = useState(user.phone || "");
  const [password, setPassword] = useState(PLACEHOLDER_PASSWORD);
  const [saving, setSaving] = useState(false);

  const onSave = async () => {
    setSaving(true);
    try {
      await updateUser({
        id: user.id,
        firstName,
        lastName,
        phone,
        password,
      });
    } catch (error) {
      console.log("error", error.code);
      console.log("error", error.message);
    }
    setSaving(false);
  };

  if (!user) return <Text>No user found...</Text>;

  return (
    <Screen>
      <PageTitle title="My Account" />
      <div className={styles.topContainer}>
        <div className={styles.userAvatarContainer}>
          <img
            src={UserAvatar}
            alt="UserAvatar"
            className={styles.userAvatar}
          />
          <div>
            <Text variant="h3">{user.firstName}</Text>
            <Text variant="h3"> {user.lastName}</Text>
          </div>
        </div>
        <Button
          variant="secondary"
          className={styles.logoutButton}
          onClick={logout}
        >
          Logout
        </Button>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.inputContainer}>
          <Input
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={(evt) => setFirstName(evt.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={(evt) => setLastName(evt.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            label="Email"
            placeholder="Email"
            value={user.email}
            disabled
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            label="Phone"
            placeholder="Phone"
            value={phone}
            onChange={(evt) => setFirstPhone(evt.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            type="password"
            onFocus={() => setPassword("")}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant="outlinePrimary"
          className={styles.button}
          onClick={onSave}
          disabled={saving}
        >
          Save Changes
        </Button>
      </div>

      <div className={styles.plansContainer}>
        <Plans />
      </div>
    </Screen>
  );
};

export default React.memo(UserSettings);
