import React from "react";
import Button from "../../../components/button";
import Icon from "../../../components/icon";
import InfoCard from "../../../components/info-card";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const WidgetBaseCard = ({
  title,
  content,
  buttonText,
  onClick,
  icon,
}) => {
  return (
    <InfoCard
      className={styles.baseCardContainer}
      titleContent={
        <div className={styles.titleContainer}>
          {icon ? <Icon name={icon} className={styles.titleIcon} /> : null}
          <Text variant="h1">{title}</Text>
        </div>
      }
      content={content}
      onClick={onClick}
      extraContent={
        <div className={styles.buttonContainer}>
          {icon ? <Icon name={icon} className={styles.buttonIcon} /> : null}
          <Button onClick={onClick} variant="outlineTransparent">{buttonText}</Button>
        </div>
      }
    />
  )
};

export default React.memo(WidgetBaseCard);
