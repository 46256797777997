import React, { useCallback, useEffect, useState } from "react";
import { httpsCallable } from "../../../common/services/firebase";
import { getInvite } from "../../../common/services/firebase/coach";
import { getUser } from "../../../common/services/firebase/users";
import { useQuery } from "../../../common/utils/routes";
import Loading from "../../../Dashboard/components/loading";
import Screen from "../../../Dashboard/components/screen";
import Text from "../../components/.text";
import InvitesComponent from "./invites-component";
import styles from "./styles.module.css";

const getStripeUserInfo = httpsCallable("getStripeUserInfoFunc");

const AcceptInvite = () => {
  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState();
  const [stripeInfo, setStripeInfo] = useState();
  const [extraInfo, setExtraInfo] = useState();
  const query = useQuery();
  const inviteId = query.get("token");

  const loadInvite = useCallback(async () => {
    setLoading(true);
    try {
      const newInvite = await getInvite(inviteId);
      if (newInvite) {
        if (newInvite.userId) {
          const newStripeInfo = await getStripeUserInfo(newInvite.userId);
          setStripeInfo(newStripeInfo?.data);
        }
        if (newInvite.coachId) {
          const coachUser = await getUser({ userId: newInvite.coachId });
          setExtraInfo({ coachUser });
        }
      }
      setInvite(newInvite);
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false);
  }, [inviteId]);

  useEffect(() => {
    loadInvite();
  }, [loadInvite]);

  return (
    <Screen>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <div className={styles.container}>
          {invite ? (
            <InvitesComponent invite={invite} stripeInfo={stripeInfo} extraInfo={extraInfo} onRefetch={loadInvite} />
          ) : (
            <Text>This invite has expired. Please request a new invite to be sent to you</Text>
          )}
        </div>
      )}
    </Screen>
  )
};

export default React.memo(AcceptInvite);
