import React from "react";
import Moon from "../../assets/icons/moon.svg";
import Sun from "../../assets/icons/sun.svg";
import { useFilter } from "../../context/filter";
import { getContainerClassName } from "../../utils/dark-mode";
import styles from "./styles.module.css";

const DarkModeToggle = () => {
  const { isDark, setIsDark } = useFilter();
  return (
    <div
      className={getContainerClassName(styles.container, isDark)}
      onClick={() => setIsDark(!isDark)}
    >
      <img
        src={isDark ? Sun : Moon}
        alt="icon"
        className={isDark ? styles.sunIcon : styles.moonIcon}
      />
    </div>
  );
};

export default React.memo(DarkModeToggle);
