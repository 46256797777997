import React, { useCallback, useEffect, useState } from "react";
import { deleteTag, getTags } from "../../../common/services/firebase/vault";
import Button from "../../components/button";
import Text from "../../components/text";
import NewTagModal from "./new-tag-modal";
import styles from "./styles.module.css";
import TagCard from "./tag-card";

const VaultTags = () => {
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState();

  const loadTags = useCallback(() => {
    setLoading(true);
    getTags({ showDeleted: false })
      .then(setTags)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  if (loading) return <Text>Loading...</Text>;

  const onDeleteTag = async (evt, tagId) => {
    evt.stopPropagation();
    if (window.confirm("Are you sure you want to delete this tag?")) {
      await deleteTag(tagId);
      loadTags();
    }
  }

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Manage Tags</Text>
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={() => setSelectedTag({})}>New tag</Button>
      </div>
      <div className={styles.tagsListContainer}>
        {tags.map(tag =>
          <TagCard
            {...tag}
            key={`tag-card-${tag.id}`}
            onClick={() => setSelectedTag(tag)}
            onDelete={(evt) => onDeleteTag(evt, tag.id)}
          />
        )}
      </div>
      <NewTagModal tag={selectedTag} onClose={() => setSelectedTag(undefined)} onRefresh={loadTags} />
    </div>
  )
};

export default React.memo(VaultTags);
