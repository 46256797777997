import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { firestore } from '..';
import { isValidEmail } from "../../../utils/auth";
import { EMAILS_COLLECTION } from '../constants';

const emailsCollection = collection(firestore, EMAILS_COLLECTION);

export const getEmailsList = async () => {
  const querySnapshot = await getDocs(emailsCollection);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getEmailInList = async (email) => {
  const queryConstraints = [];
  if (email?.length) {
    queryConstraints.push(where('email', '==', email));
  }
  const q = query(emailsCollection, ...queryConstraints);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs?.[0]?.data();
};

export const addEmailToList = async (email, userId) => {
  if (!isValidEmail(email)) throw new Error("Invalid email");
  const emailExists = await getEmailInList(email);
  if (emailExists) return;
  const docRef = doc(emailsCollection);
  const newEmail = {
    id: docRef.id,
    email,
  };
  if (userId) newEmail.userId = userId;
  else newEmail.noUser = true;
  await setDoc(docRef, newEmail);
};
