import React, { useEffect, useState } from "react";
import { useFilter } from "../../../../context/filter";
import { getClassName, getTextClassName } from "../../../../utils/dark-mode";
import styles from "./styles.module.css";
import CloseIcon from "../../../../assets/icons/close.svg";
import CloseIconLight from "../../../../assets/icons/close-light.svg";

const DISTANCE = [
  { label: 100, value: 100 },
  { label: 300, value: 300 },
  { label: 500, value: 500 },
  { label: 1000, value: 1000 },
  { label: "Custom", value: "custom" },
];

const Distance = ({ fromSaved }) => {
  const [customDistance, setCustomDistance] = useState(0);
  const {
    selectedDistance,
    setSelectedDistance,
    savedSelectedDistance,
    setSavedSelectedDistance,
    isDark,
    customIsSelected,
    setCustomIsSelected,
  } = useFilter();
  const distanceSelected = fromSaved ? savedSelectedDistance : selectedDistance;
  const onSelectDistance = fromSaved
    ? setSavedSelectedDistance
    : setSelectedDistance;

  const onDistanceClick = (clickedValue, isSelected) => {
    if (clickedValue === "custom") {
      // setCustomIsSelected(!isSelected);
      if (customIsSelected) {
        setCustomIsSelected(false);
      } else {
        setCustomIsSelected(true);
      }
      onSelectDistance(undefined);
    } else {
      setCustomIsSelected(false);
      onSelectDistance(isSelected ? undefined : clickedValue);
    }
  };

  useEffect(() => {
    if (customIsSelected) {
      const timeout = setTimeout(() => {
        onSelectDistance(customDistance || undefined);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [customIsSelected, customDistance, onSelectDistance]);

  return (
    <div>
      {DISTANCE.map((d) => {
        const isCustom = d.value === "custom";
        if (fromSaved && isCustom) return null;
        const isSelected = !customIsSelected && distanceSelected === d.value;
        return (
          <div>
            <div
              key={d.value}
              className={`${getTextClassName(styles.label, isDark)} ${
                isSelected || (customIsSelected && isCustom)
                  ? getClassName(styles, "selectedLabel", isDark)
                  : ""
              }`}
              onClick={() => onDistanceClick(d.value, isSelected)}
            >
              {d.label} {!isCustom && "mi"}
              {isSelected || (customIsSelected && isCustom) ? (
                <img src={isDark ? CloseIconLight : CloseIcon} width={10} />
              ) : null}
            </div>
            {customIsSelected && isCustom && (
              <div>
                <input
                  className={styles.input}
                  placeholder="Distance"
                  type="number"
                  value={customDistance}
                  onChange={(evt) =>
                    setCustomDistance(evt.target.valueAsNumber)
                  }
                  min={0}
                />{" "}
                mi
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Distance);
