import React from "react";
import SaveFilled from "../../../assets/icons/save-filled.svg";
import SaveOutline from "../../../assets/icons/save-outline-white.svg";
import { useFilter } from "../../../context/filter";
import { getSchoolImage } from "../../../utils/images";
import ActionButtons from "../action-buttons";
import Coach from "../coach";
import styles from "./styles.module.css";
import Check from "../../../assets/icons/checkThin.svg";
import Loading from "../../../../Dashboard/components/loading";
import Text from "../../../../Dashboard/components/text";

const SchoolContent = ({ school, loading, isDemo }) => {
  const { savedSchools, saveSchool, removeSavedSchool, setSchoolDirection } =
    useFilter();
  function hasSchool(school) {
    for (let i = 0; i < savedSchools.length; i++) {
      if (savedSchools[i].name === school.name) {
        return true;
      }
    }
    return false;
  }

  function handleDemoClick() {
    document.getElementById("saveButton").classList.add(styles.wiggle);
    setTimeout(
      () =>
        document.getElementById("saveButton").classList.remove(styles.wiggle),
      200
    );
  }

  return (
    <>
      {loading && (
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.schoolContainer}>
              <div className={styles.schoolLogoContainer}>
                <div className={styles.loading}>
                  <Loading size={40} />
                  <Text style={{ fontSize: "16px" }}>
                    Loading school info...
                  </Text>
                </div>
              </div>
            </div>

            <div id="saveButton" className={styles.saveButton}>
              <img src={SaveOutline} width={20} height={20} alt="Save" />
              <div>Save School</div>
            </div>
          </div>

          <ActionButtons
            school={school}
            setSchoolDirection={setSchoolDirection}
            isDemo={isDemo}
            isLoading={true}
          />

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Quick Info</div>
            <div className={styles.quickInfoGrid}>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Division</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Conference</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Status</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Climate</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Coaches on X</div>
            <div className={styles.quickInfoContent}></div>
          </div>
        </div>
      )}

      {!loading && school && (
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.schoolContainer}>
              <div className={styles.schoolLogoContainer}>
                <img
                  className={styles.schoolLogo}
                  src={getSchoolImage(school.name)}
                  alt="school-logo"
                />
              </div>

              <div className={styles.schoolNameContainer}>
                <div className={styles.schoolName}>{school.name}</div>
                <div className={styles.schoolAdress}>{school.address}</div>
              </div>
            </div>

            <div
              id="saveButton"
              className={styles.saveButton}
              onClick={() =>
                isDemo
                  ? handleDemoClick()
                  : hasSchool(school)
                  ? removeSavedSchool(school.name)
                  : saveSchool(school)
              }
            >
              <img
                src={hasSchool(school) ? SaveFilled : SaveOutline}
                width={20}
                height={20}
                alt="Save"
              />

              <div>
                {hasSchool(school) ? (
                  <div className={styles.savedTextContainer}>
                    Saved
                    <img src={Check} width={14} height={14} />
                  </div>
                ) : (
                  "Save School"
                )}
              </div>
            </div>
          </div>

          <ActionButtons
            school={school}
            setSchoolDirection={setSchoolDirection}
            isDemo={isDemo}
          />

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Quick Info</div>
            <div className={styles.quickInfoGrid}>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>{school.devision}</div>
                  <div className={styles.quickInfoDescription}>Division</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    {school.conference}
                  </div>
                  <div className={styles.quickInfoDescription}>Conference</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    {school.publicPrivate}
                  </div>
                  <div className={styles.quickInfoDescription}>Status</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>{school.temp}</div>
                  <div className={styles.quickInfoDescription}>Climate</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Coaches on X</div>
            <div className={styles.quickInfoContent}>
              <Coach isDemo={isDemo} data={school.hc} coachTitle="Head Coach" />
              <Coach
                isDemo={isDemo}
                data={school.dc}
                coachTitle="Defensive Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.oc}
                coachTitle="Offensive Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.wRs}
                coachTitle="Wide Receivers Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.qBs}
                coachTitle="Quarterbacks Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.rBs}
                coachTitle="Runningbacks Caoch"
              />
              <Coach
                isDemo={isDemo}
                data={school.ol}
                coachTitle="Offensive Linemen Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.te}
                coachTitle="Tight Ends Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.dBs}
                coachTitle="Defensive Backs Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.lBs}
                coachTitle="Linebackers Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.dl}
                coachTitle="Defensive Line Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.rc}
                coachTitle="Recruiting Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.specialists}
                coachTitle="Special Teams Coordinator"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SchoolContent);
