import React from "react";
import Icon from "../../../../Dashboard/components/icon";
import Button from "../../../components/button";
import Text from "../../../components/text";
import styles from "./styles.module.css";

const TagCard = ({
  label,
  icon,
  onClick,
  onDelete,
}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.labelContainer}>
      <Icon name={icon} />
      <Text>{label}</Text>
      </div>
      <Button onClick={onDelete}>
        Delete
      </Button>
    </div>
  )
};

export default React.memo(TagCard);
