import React, { useMemo } from "react";
import cn from "classnames";
import BaseText from "../../../common/components/text";
import Styles from "./styles.module.css";

const Heading2 = ({ variant, ...props }) => {
  const variantStyle = useMemo(() => {
    if (variant === "plans") return Styles.plans;
    if (variant === "plansDark") return Styles.plansDark;
  }, [variant]);
  return <BaseText className={cn(Styles.container, variantStyle)} {...props} />;
};

export default React.memo(Heading2);
