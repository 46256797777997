import cn from 'classnames';
import React from "react";
import DP from 'react-select';
import Text from '../text';
import styles from './styles.module.css';

const Dropdown = ({
  className,
  label,
  labelClassName,
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#333', // Background color of the control
      borderColor: state.isFocused ? '#666' : '#444', // Border color when focused
      boxShadow: state.isFocused ? '0 0 0 1px #666' : 'none', // Box shadow when focused
      '&:hover': {
        borderColor: '#666', // Border color on hover
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#666' : '#222', // Background color of selected option
      '&:hover': {
        backgroundColor: '#444', // Background color on hover
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff', // Color of the selected value text
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333', // Background color of the dropdown menu
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: '#333', // Background color of the options dropdown
      color: '#fff', // Color of the placeholder text
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999', // Color of the placeholder text
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: '#666', // Color of the indicator separator
    }),
  };
  return (
    <div className={cn(styles.container, className)}>
      {label ? (
        <div className={styles.labelContainer}>
          <Text className={cn(styles.label, labelClassName)}>{label}</Text>
        </div>
      ) : null}
      <div className={styles.dropdownContainer}>
        <DP styles={customStyles} {...props} />
      </div>
    </div>
  )
};

export default React.memo(Dropdown);
