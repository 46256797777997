import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { VAULT_PERMISSION } from "../../../common/constants";
import { clearVaultPlaylist } from "../../../common/redux/reducers/vault-playlist";
import { setVaultSection } from "../../../common/redux/reducers/vault-section";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { REMOVE_CURRENT_TIME, saveVideoProgress } from "../../../common/services/firebase/vault";
import Button from "../../components/button";
import HorizontalList from "../../components/horizontal-list";
import Icon from "../../components/icon";
import RestrictedPage from "../../components/restricted-page";
import SmallVideoCard from "../../components/small-video-card";
import Text from '../../components/text';
import VideoCard from "../../components/video-card";
import { getVideoTags } from "../../utils/tags";
import styles from './styles.module.css';

const VideoDetails = () => {
  const { videos, tags: vaultTags } = useSelector(state => state.vault);
  const videosInProgress = useSelector(state => state.videosInProgress.videos);
  const vaultPlaylist = useSelector(state => state.vaultPlaylist);
  const user = useSelector(state => state.user);
  const [moreFromThisSection, setMoreFromThisSection] = useState([]);
  const [showUpNext, setShowUpNext] = useState(false);
  const [loadingPlayer, setLoadingPlayer] = useState(true);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const dispatch = useDispatch();
  const { id: _videoId } = useParams();
  const history = useHistory();

  const {
    video,
    // fullInterviewVideo,
  } = useMemo(() => {
    if (!videos?.length || !_videoId) return {};
    const video = videos.find(v => v.id === _videoId);
    const fullInterviewVideoId = video?.fullInterviewVideo;
    let fullInterviewVideo;
    if (video?.shorts && fullInterviewVideoId) {
      fullInterviewVideo = videos.find(v => v.id === fullInterviewVideoId);
    }
    return { video, fullInterviewVideo }
  }, [videos, _videoId]);

  const videoTags = video?.tags;
  const tags = useMemo(() => {
    return getVideoTags(videoTags, vaultTags);
  }, [videoTags, vaultTags]);
  const videoInProgress = videosInProgress?.[video?.id];

  const upNext = useMemo(() => {
    return moreFromThisSection?.[0];
  }, [moreFromThisSection]);

  useEffect(() => {
    setShowUpNext(false);
    if (!vaultPlaylist?.length || !video) return;
    const newMoreFromThisSection = vaultPlaylist.filter(v => v.id !== video.id);
    if (newMoreFromThisSection.length === vaultPlaylist.length) {
      dispatch(clearVaultPlaylist());
    } else {
      setMoreFromThisSection(newMoreFromThisSection);
    }
  }, [vaultPlaylist, video]);

  useEffect(() => {
    setCurrentVideoTime(videoInProgress?.currentTime || 0);
  }, [videoInProgress]);

  useEffect(() => {
    setLoadingPlayer(true);
    setTimeout(() => {
      setLoadingPlayer(false);
    }, 100);
  }, [_videoId]);

  const onPlayerPause = (event) => {
    const currentTime = event?.target?.getCurrentTime();
    if (user && video) {
      saveVideoProgress(user.id, video.id, currentTime);
    }
  }

  const onVideoEnd = () => {
    setShowUpNext(true);
    if (user && video) {
      saveVideoProgress(user.id, video.id, REMOVE_CURRENT_TIME);
      setCurrentVideoTime(0);
    }
  }

  if (!video?.videoId) return <Text>Video not found...</Text>;

  const opts = {
    playerVars: {
      // autoplay: 1,
      iv_load_policy: 3,
      rel: 0,
      showinfo: 0,
      start: Math.trunc(currentVideoTime),
    },
  };

  const goToSection = (title) => {
    if (!title?.length) return;
    dispatch(setVaultSection({
      title,
      videos: vaultPlaylist
    }))
    history.push(DASHBOARD_ROUTES.vaultSection);
  };

  return (
    <RestrictedPage restrictedTitle="The Vault" allowedPlans={VAULT_PERMISSION}>
      <div>
        <div className={styles.videoContainer}>
          {loadingPlayer ? null : (
            <YouTube videoId={video.videoId} opts={opts} onPause={onPlayerPause} onEnd={onVideoEnd} iframeClassName={styles.videoIframeContainer} />
          )}
          {showUpNext && upNext ? (
            <div className={styles.upNextContainer}>
              <div className={styles.upNextCardContainer}>
                <Text variant="h2">Up Next</Text>
                <VideoCard video={upNext} />
                <Button className={styles.cancelButton} variant="outlinePrimary" onClick={() => setShowUpNext(false)}>Cancel</Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.container}>
          <Text variant="title">{video.title}</Text>
          <Text variant="description">{video.description}</Text>
          {tags?.length ? (
            <div className={styles.tagsContainer}>
              {/* <ExtraLinkButton video={video} /> */}
              <Text>Tags:</Text>
              {tags.map(tag => (
                <div className={styles.tagContainer}>
                  <Icon name={tag.icon} alt="icon" size={18} />
                  <Text key={tag.id}>{tag.label}</Text>
                </div>
              ))}
            </div>
          ) : null}
          {/* {fullInterviewVideo ? (
          <div className={styles.sectionContainer}>
            <Text className={styles.upNextText}>Full Interview</Text>
            <VideoCard video={fullInterviewVideo} />
          </div>
        ) : null} */}
          {moreFromThisSection.length ? (
            <div className={styles.moreFromThisSectionMobileContainer}>
              <Text variant="h1">More From this Section</Text>
              {moreFromThisSection.slice(0, 3).map(v => {
                return (
                  <SmallVideoCard video={v} videosFromSection={vaultPlaylist} videosInProgress={videosInProgress} />
                )
              })}
              {moreFromThisSection?.length > 3 ? (
                <div className={styles.moreButtonContainer}>
                  <Button className={styles.moreButton} variant="tertiary" onClick={() => goToSection("Video Section")}>More</Button>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className={styles.moreFromThisSectionContainer}>
            <HorizontalList
              id="more-from-this-section"
              title="More From this Section"
              data={moreFromThisSection.map(video => ({ video, videosInProgress }))}
              Component={SmallVideoCard}
              seeAllTitle="Go to Section"
              onSeeAllClick={() => goToSection("Video Section")}
              videosFromSection={vaultPlaylist}
            />
          </div>
        </div>
      </div>
    </RestrictedPage>
  );
};

export default React.memo(VideoDetails);
