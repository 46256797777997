import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getNewsletter, getTags, saveNewsletter } from "../../../common/services/firebase/newsletter";
import { getImagePreviewUrl } from "../../../common/utils/storage";
import Button from "../../components/button";
import Dropzone from "../../components/dropzone";
import Input from "../../components/input";
import Text from "../../components/text";
import styles from "./styles.module.css";

const mapTags = (newsletterTags = [], collectionTags = []) => {
  if (!newsletterTags.length || !collectionTags.length) return [];
  return newsletterTags.map(tag => collectionTags.find(collectionTag => collectionTag.id === tag));
}

const NewNewsletter = () => {
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);
  const [abreviatedTitle, setAbreviatedTitle] = useState('');
  const [title, setTitle] = useState('');
  const [intro, setIntro] = useState('');
  const [description, setDescription] = useState('');
  const [editingBody, setEditingBody] = useState({});
  const [body, setBody] = useState([]);
  const [docKey, setDocKey] = useState('');
  const [tagLabel, setTagLabel] = useState('');
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState();
  const [oldImage, setOldImage] = useState();
  const [newslettersTags, setNewslettersTags] = useState([]);
  const { id: _newsletterId } = useParams();
  const tagInputRef = useRef(null);
  const history = useHistory();
  const filteredTags = useMemo(() => {
    const search = tagLabel?.trim()?.toLowerCase();
    if (!search) return [];
    return newslettersTags.filter(tag => {
      const label = tag.label.trim().toLowerCase();
      return label.includes(search) && (!tags.length || !tags.find(t => t.id === tag.id));
    });
  }, [newslettersTags, tagLabel, tags]);

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setNewslettersTags)
      .finally(() => setLoadingTags(false));
  }, []);

  useEffect(() => {
    if (_newsletterId) {
      if (!loadingTags) {
        setLoadingNewsletter(true);
        getNewsletter({ newsletterId: _newsletterId })
          .then(res => {
            setDocKey(res.id);
            setTitle(res.title);
            setIntro(res.intro);
            setAbreviatedTitle(res.abreviatedTitle);
            setDescription(res.description);
            setBody(res.body);
            if (res.image) {
              setOldImage(res.image);
              setImage({ preview: getImagePreviewUrl("newsletter", res.image), isEditing: true });
            }
            setTags(mapTags(res.tags, newslettersTags));
          })
          .finally(() => setLoadingNewsletter(false));
      }
    } else {
      setLoadingNewsletter(false);
    }
  }, [_newsletterId, newslettersTags, loadingTags]);

  if (loadingNewsletter || loadingTags) return <Text>Loading...</Text>;

  const onAddNewTag = () => {
    if (tagLabel) {
      setTags([...tags, { id: `NEW_TAG_${tags.length + 1}`, label: tagLabel }])
      setTagLabel('');
      tagInputRef.current?.focus();
    }
  }

  const onAddExistingTag = (tag) => {
    setTags([...tags, tag]);
    setTagLabel('');
    tagInputRef.current?.focus();
  }

  const onTagsKeyDown = (evt) => {
    if (evt.key === "Enter") {
      onAddNewTag();
    }
  };

  const onRemoveTag = (index) => {
    setTags(prev => {
      prev.splice(index, 1);
      return [...prev];
    })
  };

  const onDeleteBody = (index) => {
    if (window.confirm("Are you sure you want to delete?")) {
      setBody(prev => {
        prev.splice(index, 1);
        return [...prev];
      })
    }
  };

  const onSaveEditingBody = () => {
    if (editingBody.title || editingBody.description) {
      setBody(prev => {
        if (editingBody.index !== undefined) {
          prev[editingBody.index] = editingBody
        } else {
          prev.push(editingBody);
        }
        setEditingBody({});
        return [...prev];
      })
    }
  };

  const onSave = async () => {
    if (!title || !abreviatedTitle || !image) return alert("A newsletter needs a title, an abreviated title and an image");
    try {
      await saveNewsletter({
        id: docKey,
        title,
        intro,
        abreviatedTitle,
        description,
        body,
        tags,
        image,
        oldImage,
      });
      history.goBack();
    } catch (error) {
      console.log('error', error);
    }
  }

  const onDropImage = (acceptedImages) => {
    const acceptedImage = acceptedImages?.[0];
    if (!acceptedImage) return alert("Image type not supported");
    setImage(Object.assign(acceptedImage, {
      preview: URL.createObjectURL(acceptedImage)
    }));
  }

  return (
    <div className={styles.container}>
      <Text className={styles.title}>{docKey ? 'Edit' : 'New'} Newsletter</Text>
      <Input className={styles.section} label="Title" value={title} onChange={evt => setTitle(evt.target.value)} />
      <Input className={styles.section} label="Abreviated Title" value={abreviatedTitle} onChange={evt => setAbreviatedTitle(evt.target.value)} />
      <Input className={styles.section} label="Intro" value={intro} onChange={evt => setIntro(evt.target.value)} textArea />
      <Input className={styles.section} label="Description" value={description} onChange={evt => setDescription(evt.target.value)} textArea />
      <div className={styles.tagInputContainer}>
        <Input className={styles.section} label="Tags" value={tagLabel} onChange={evt => setTagLabel(evt.target.value)} onKeyDown={onTagsKeyDown} ref={tagInputRef} />
        <Button onClick={onAddNewTag}>
          Add Tag
        </Button>
      </div>
      {filteredTags.length ? (
        <div className={styles.tagsInputDropdown}>
          {filteredTags.map(tag => (
            <div className={styles.filteredTagContainer} key={tag.id} onClick={() => onAddExistingTag(tag)}>
              <Text>{tag.label}</Text>
            </div>
          ))}
        </div>
      ) : null}
      {tags.length ? (
        <div className={styles.tagsContainer}>
          {tags.map((tag, index) => (
            <div key={tag.id} className={styles.tag}>
              <Text key={tag.id}>{tag.label}</Text>
              <div className={styles.removeTagContainer} onClick={() => onRemoveTag(index)}>
                <Text className={styles.removeTag}>X</Text>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className={styles.section}>
        {image?.preview ? (
          <div className={styles.imageContainer}>
            <img
              alt="preview"
              src={image.preview}
              className={styles.image}
            />
            <Button onClick={() => setImage(undefined)}>Clear Image</Button>
          </div>
        ) : (
          <Dropzone onDrop={onDropImage} />
        )}
      </div>
      <div className={styles.section}>
        <Text className={styles.body}>Body</Text>
        <div className={styles.bodyCardsContainer}>
          {body?.map((b, index) => (
            <div key={`body_${index}`} className={styles.bodyCardContainer}>
              <Text className={styles.bodyCardTitle}>{b.title}</Text>
              <Text className={styles.bodyCardDescription}>{b.description}</Text>
              <div className={styles.bodyCardButtonsContainer}>
                <Button onClick={() => setEditingBody({ ...b, index })}>Edit</Button>
                <Button onClick={() => onDeleteBody(index)}>Delete</Button>
              </div>
            </div>
          ))}
          {!body.length ? (
            <Text>Add a new body in the section bellow</Text>
          ) : null}
        </div>
        <div>
          <Input className={styles.section} label="Body Title" value={editingBody.title || ""} onChange={evt => setEditingBody(prev => ({ ...prev, title: evt.target.value }))} />
          <Input className={styles.section} label="Body Description" value={editingBody.description || ""} onChange={evt => setEditingBody(prev => ({ ...prev, description: evt.target.value }))} textArea />
          <div className={styles.saveEditingBodyButtonContainer}>
            <Button variant="outline" onClick={onSaveEditingBody}>{editingBody.index !== undefined ? "Edit" : "Save"} Body</Button>
            <Button variant="outline" onClick={() => setEditingBody({})}>Clear</Button>
          </div>
        </div>
      </div>
      <div className={styles.saveButtonContainer}>
        <Button variant="outline" onClick={onSave}>Save Newsletter</Button>
      </div>
    </div>
  )
}

export default React.memo(NewNewsletter);
