import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../common/route";
import { getNewsletters, getTags } from "../../../common/services/firebase/newsletter";
import Button from "../../components/button";
import Text from "../../components/text";
import NewsletterCard from "./newsletter-card";
import styles from "./styles.module.css";

const Newsletter = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [loadingNewsltters, setLoadingNewsletters] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [tags, setTags] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLoadingNewsletters(true);
    getNewsletters({ showDeleted: false })
      .then(setNewsletters)
      .finally(() => setLoadingNewsletters(false));
  }, []);

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setTags)
      .finally(() => setLoadingTags(false));
  }, []);

  if (loadingNewsltters || loadingTags) return <Text>Loading...</Text>;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>Newsletter</Text>
        <Button onClick={() => history.push(ADMIN_ROUTES.newNewsletter)}>
          New Newsletter
        </Button>
      </div>
      <div>
        {newsletters.length ? (
          newsletters.map(newsletter => <NewsletterCard key={newsletter.id} newsletter={newsletter} newslettersTags={tags} />)
        ) : (
          <Text>No newsletters created</Text>
        )}
      </div>
    </div>
  )
};

export default React.memo(Newsletter);
