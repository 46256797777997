import React from "react";
import { ADMIN_REVIEW_LABEL, REVIEW_STATUS } from "../../../../Dashboard/constants";
import Button from "../../../components/button";
import Text from "../../../components/text";
import styles from './styles.module.css';

const ReviewStatus = ({ status, onStartReview }) => {
  if (status === REVIEW_STATUS.SUBMITTED) {
    return <Button variant="red" onClick={onStartReview}>Start Review</Button>;
  }

  return (
    <div className={styles.inReviewContainer}>
      <Text>{ADMIN_REVIEW_LABEL[status]}</Text>
    </div>
  )
}


export default React.memo(ReviewStatus);