import React, { useMemo } from "react";
import Text from '../text';
import styles from './styles.module.css';

const Tags = ({ tags, collectionTags }) => {
  const innerTags = useMemo(() => {
    if (!tags?.length || !collectionTags?.length) return [];
    return tags.map(tag => collectionTags.find(collectionTag => collectionTag.id === tag));
  }, [tags, collectionTags]);

  if (!innerTags.length) return null;

  return (
    <div className={styles.container}>
      {innerTags.map(tag => (
        <Text key={tag.id} className={styles.tag}>{tag.label}</Text>
      ))}
    </div>
  )
};

export default React.memo(Tags);
