import React from "react";
import Text from '../../../components/text';
import Card from "./card";
import styles from './styles.module.css';

const ScheduleContent = ({ content }) => {
  const { cards } = content;
  return (
    <div className={styles.container}>
      <Text variant="h3">Schedule</Text>
      <div className={styles.content}>
        {cards?.length ? (
          <div className={styles.cardsContainer}>
            {cards.map(card => <Card title={card.title} text={card.text} />)}
            </div>
        ) : null}
      </div>
    </div>
  )
};

export default React.memo(ScheduleContent);
