import React from "react";
import DarkModeToggle from "../dark-mode-toggle";
import ClearDirections from "./clear-directions";
import Previous from "./previous";
import Recents from "./recents";
import Saved from "./saved";
import SchoolFilter from "./school-filter";
import Search from "./search";
import styles from "./styles.module.css";

const Filters = ({ isDemo }) => {

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.topMenu}>
          <Search />
          <SchoolFilter />
        </div>
        <div className={styles.sideMenu}>
          <DarkModeToggle />
          <Previous />
          <Recents isDemo={isDemo} />
          <Saved isDemo={isDemo} />
          <ClearDirections />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Filters);
