import cn from "classnames";
import React from "react";
import { generateFirebaseKey } from "../../../common/services/firebase";
import Button from "../button";
import Text from "../text";
import styles from "./styles.module.css";

const HorizontalList = ({
  className,
  title,
  listClassname,
  data = [],
  Component,
  id = generateFirebaseKey(),
  seeAllTitle,
  onSeeAllClick,
  videosFromSection,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      {title ? (
        <div className={styles.headerContainer}>
          <Text variant="h2">{title}</Text>
          {onSeeAllClick ? <Button className={styles.seeAllButton} variant="tertiary" onClick={onSeeAllClick}>{seeAllTitle || "See All"}</Button> : null}
        </div>
      ) : null}
      <div className={cn(styles.listContainer, listClassname, { [styles.noTitleList]: !title })}>
        {data.map((d, index) => <Component key={`horizontal-item-list-${id}-${index}`} data={d} {...d} videosFromSection={videosFromSection} />)}
      </div>
    </div>
  )
};

export default React.memo(HorizontalList);
