import React, { useCallback, useEffect, useState } from "react";
import { deleteCategory, getCategories } from "../../../common/services/firebase/vault";
import Button from "../../components/button";
import Text from "../../components/text";
import CategoryCard from "./category-card";
import NewCategoryModal from "./new-category-modal";
import styles from "./styles.module.css";

const VaultCategories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const loadCategories = useCallback(() => {
    setLoading(true);
    getCategories({ showDeleted: false })
      .then(setCategories)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  if (loading) return <Text>Loading...</Text>;

  const onDeleteCategory = async (evt, categoryId) => {
    evt.stopPropagation();
    if (window.confirm("Are you sure you want to delete this category?")) {
      await deleteCategory(categoryId);
      loadCategories();
    }
  }

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Manage Categories</Text>
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={() => setSelectedCategory({})}>New Category</Button>
      </div>
      <div className={styles.categoriesListContainer}>
        {categories.map(category =>
          <CategoryCard
            {...category}
            key={`category-card-${category.id}`}
            onClick={() => setSelectedCategory(category)}
            onDelete={(evt) => onDeleteCategory(evt, category.id)}
          />
        )}
      </div>
      <NewCategoryModal category={selectedCategory} onClose={() => setSelectedCategory(undefined)} onRefresh={loadCategories} />
    </div>
  )
};

export default React.memo(VaultCategories);
