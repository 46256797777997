import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../common/route";
import { deleteTopic, getTopics } from "../../../common/services/firebase/vault";
import { replaceRoute } from "../../../common/utils/routes";
import Button from "../../components/button";
import Text from "../../components/text";
import styles from "./styles.module.css";
import TopicCard from "./topic-card";

const VaultTags = () => {
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  const history = useHistory();

  const loadTopics = useCallback(() => {
    setLoading(true);
    getTopics({ showDeleted: false })
      .then(setTopics)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadTopics();
  }, [loadTopics]);

  if (loading) return <Text>Loading...</Text>;

  const onDeleteTopic = async (evt, topicId) => {
    evt.stopPropagation();
    if (window.confirm("Are you sure you want to delete this topic?")) {
      await deleteTopic(topicId);
      loadTopics();
    }
  }

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Manage Topics</Text>
      <div className={styles.buttonContainer}>
        <Button variant="outline" onClick={() => history.push(ADMIN_ROUTES.newVaultTopic)}>New Topic</Button>
      </div>
      <div className={styles.tagsListContainer}>
        {topics.map(topic =>
          <TopicCard
            {...topic}
            key={`topic-card-${topic.id}`}
            onClick={() => history.push(replaceRoute(ADMIN_ROUTES.editVaultTopic, ['id', topic.id]))}
            onDelete={(evt) => onDeleteTopic(evt, topic.id)}
          />
        )}
      </div>
    </div>
  )
};

export default React.memo(VaultTags);
