import cn from "classnames";
import React from "react";
import Button from "../../../components/button";
import styles from "./styles.module.css";

const TagCard = ({ tag, isSelected, onClick }) => {
  if (!tag) return null;
  return (
    <div className={styles.container}>
      <Button
        variant="outlineTransparent"
        onClick={onClick}
        className={cn(
          styles.button,
          { [styles.selected]: isSelected },
        )}
        iconName={tag.icon}
        iconSize={22}
      >
        {tag.label}
      </Button>
    </div>
  )
};

export default React.memo(TagCard);
