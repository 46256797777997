import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading1 from "../.heading1";
import cn from "classnames";

const AuditCard = ({ titleContent, content, extraContent, variant }) => {
  const variantStyle = useMemo(() => {
    if (variant === "outline") return styles.outline;
  }, [variant]);
  return (
    <div className={cn(styles.container, variantStyle)}>
      <Text style={{ color: "var(--athleteRed)", fontWeight: "500" }}>{titleContent}</Text>
      <Text className={styles.contentContainer}>{content}</Text>
      {extraContent ? (
        <div className={styles.extraContentSpacer}>
          <div className={styles.extraContentContainer}>{extraContent}</div></div>
      ) : null}
    </div>
  );
};

export default React.memo(AuditCard);
