import React from "react";
import { useFilter } from "../../../../context/filter";
import { getClassName, getTextClassName } from "../../../../utils/dark-mode";
import styles from "./styles.module.css";
import CloseIcon from "../../../../assets/icons/close.svg";
import CloseIconLight from "../../../../assets/icons/close-light.svg";

const TEMPERATURE = [
  { label: "Hot", value: "Hot" },
  { label: "Warm", value: "Warm" },
  { label: "Cold", value: "Cold" },
  // { label: 'Fridged', value: 'Fridged' },
];

const Temperature = () => {
  const { selectedTemperature, setSelectedTemperature, isDark } = useFilter();

  return (
    <div>
      {TEMPERATURE.map((d) => {
        const isSelected = selectedTemperature === d.value;
        return (
          <div
            key={d.value}
            className={`${getTextClassName(styles.label, isDark)} ${
              isSelected ? getClassName(styles, "selectedLabel", isDark) : ""
            }`}
            onClick={() =>
              setSelectedTemperature(isSelected ? undefined : d.value)
            }
          >
            {d.label}
            {isSelected ? (
              <img src={isDark ? CloseIconLight : CloseIcon} width={10} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Temperature);
