import cn from 'classnames';
import React, { useMemo } from "react";
import BaseButton from '../../../common/components/button';
import Text from '../text';
import styles from './styles.module.css';

const Button = ({
  className,
  children,
  variant,
  disabled,
  onClick,
  ...props
}) => {

  const variantStyle = useMemo(() => {
    if (variant === 'outline') return styles.outline;
    if (variant === 'red') return styles.red;
  }, [variant]);

  return (
    <BaseButton className={cn(styles.container, variantStyle, className)} onClick={disabled ? null : onClick} {...props}>
      <Text>{children}</Text>
    </BaseButton>
  );
};

export default React.memo(Button);