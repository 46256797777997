import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const Text = ({
  children,
  className,
  variant = "regular",
  ...props
}) => {
  return (
    <span
      className={classNames(
        styles.container,
        styles[variant],
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
};

export default React.memo(Text);