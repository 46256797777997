import cn from "classnames";
import React, { useState } from "react";
import CommonMenus from "./common-menus";
import MobileMenu from "./mobile-menu";
import styles from "./styles.module.css";
import ToggleOpen from "./toggle-open";
import { useSelector } from "react-redux";

const NavBar = ({ children }) => {
  const { navBarOpen: open } = useSelector((state) => state.appState);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = (evt) => {
    const scrollTop = evt.target.scrollTop;
    setScrolling((scrollTop || 0) > 50);
  };

  return (
    <div className={styles.container}>
      <MobileMenu scrolling={scrolling} />
      <div
        className={cn(styles.navContainer, {
          [styles.navContainerClosed]: !open,
        })}
      >
        <div className={styles.toggleContainer}>
          <ToggleOpen />
        </div>
        <CommonMenus />
      </div>
      <div
        className={cn(styles.contentContainer, {
          [styles.contentContainerClosedNav]: !open,
        })}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(NavBar);
