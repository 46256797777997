import cn from "classnames";
import React, { useMemo, useState } from "react";
import { generateFirebaseKey } from "../../../common/services/firebase";
import Button from "../button";
import Text from "../text";
import styles from "./styles.module.css";

const VerticalList = ({
  className,
  title,
  listClassname,
  data = [],
  Component,
  id = generateFirebaseKey(),
  videosFromSection,
  listSize = 5,
}) => {
  const [page, setPage] = useState(1);
  const verticalData = useMemo(() => {
    if (!data.length) return [];
    return data.slice(0, listSize * page);
  }, [data, listSize, page]);

  const showLoadMore = data.length !== verticalData.length;
  const showHide = page > 1;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.headerContainer}>
        <Text variant="h2">{title}</Text>
        {showLoadMore ? <Button className={styles.seeAllButton} onClick={() => setPage(prevPage => prevPage + 1)}>Load More</Button> : null}
        {showHide ? <Button className={styles.seeAllButton} onClick={() => setPage(1)}>Hide Loaded</Button> : null}
      </div>
      <div className={cn(styles.listContainer, listClassname)}>
        {verticalData.map((d, index) => <Component key={`vertical-item-list-${id}-${index}`} {...d} videosFromSection={videosFromSection} />)}
      </div>
    </div>
  )
};

export default React.memo(VerticalList);
