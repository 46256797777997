import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from '../../../Dashboard/components/loading';
import { USER_TYPE } from '../../../common/constants';
import { BASE_ROUTES } from "../../../common/route";
import { signUp } from "../../../common/services/firebase/auth";
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from '../../components/button';
import Input from '../../components/input';
import pageStyles from "../styles.module.css";

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [creating, setCreating] = useState(false);
  const history = useHistory();

  const onSignUp = async () => {
    if (!firstName || !lastName || !email || !password || !retypePassword) return toast.error("All fields are required");
    if (password !== retypePassword) return toast.error('Passwords don\'t match');
    try {
      setCreating(true);
      await signUp({ email, firstName, lastName, password, userType: USER_TYPE.FREE });
      toast.success('Account created!');
      history.push(BASE_ROUTES.login, { state: true });
    } catch (error) {
      console.log('error', error);
      toast.error('Something went wrong');
    }
    setCreating(false);
  };
  return (
    <div className={pageStyles.formPage}>
      <div className={pageStyles.formPageContentContainer}>
        <div className={pageStyles.formPageTitle}>
          <Title>Get Started</Title>
          <div className={pageStyles.flex10}>
            <Text>Already have an account?</Text>
            <a onClick={() => history.push(BASE_ROUTES.login)}>Log In</a>
          </div>
        </div>
        <div className={pageStyles.form}>
          <div className={pageStyles.doubleCell}>
            <Input variant="half" placeholder="First Name" required value={firstName} onChange={evt => setFirstName(evt.target.value)} />
            <Input variant="half" placeholder="Last Name" required value={lastName} onChange={evt => setLastName(evt.target.value)} />
          </div>
          <Input placeholder="Email" required value={email} onChange={evt => setEmail(evt.target.value)} />
          <Input placeholder="Password" required value={password} onChange={evt => setPassword(evt.target.value)} type="password" />
          <Input placeholder="Re-type password" required value={retypePassword} onChange={evt => setRetypePassword(evt.target.value)} type="password" />
          <Button variant="form" onClick={onSignUp} disabled={creating}>
            Create Account
          </Button>
          {creating ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Signup);
