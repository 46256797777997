import cn from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { THUMBNAIL_URL } from "../../../common/constants";
import { setVaultPlaylist } from "../../../common/redux/reducers/vault-playlist";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { replaceRoute } from "../../../common/utils/routes";
import { secondsToHms } from "../../../common/utils/time";
import Text from "../text";
import styles from './styles.module.css';

const SmallVideoCard = ({ video, videosInProgress, highlightVideosIds = [], videosFromSection }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const thumbNailImage = `${THUMBNAIL_URL.replace('{videoId}', video.videoId)}`;
  const progress = videosInProgress?.[video?.id]?.currentTime;
  const isInProgress = !!progress && progress > 0;
  const shouldDecreaseOpacity = !!highlightVideosIds?.length && !highlightVideosIds.includes(video.id);

  const onClick = () => {
    if (videosFromSection?.length) dispatch(setVaultPlaylist(videosFromSection));
    history.push(replaceRoute(DASHBOARD_ROUTES.videoDetails, ['id', video.id]));
  };

  return (
    <div className={cn(styles.container, { [styles.lessOpacity]: shouldDecreaseOpacity })} onClick={onClick}>
      <img src={thumbNailImage} alt="thumbnail" className={styles.image} />
      <Text variant="h4">{video.title}</Text>
      <div>
        <Text variant="descriptionSmall">{isInProgress ? `${secondsToHms(video.duration - progress)} left` : secondsToHms(video.duration)}</Text>
      </div>
    </div>
  )
}

export default React.memo(SmallVideoCard);
