import { setLoaders } from "../../../common/redux/reducers/loaders";
import { setNewsletters, setNewslettersTags } from "../../../common/redux/reducers/newsletters";
import { setReviews, setReviewsStructure } from "../../../common/redux/reducers/reviews";
import { setTopics, setVaultCategories, setVaultTags, setVideos } from "../../../common/redux/reducers/vault";
import { setVideosInProgress } from "../../../common/redux/reducers/videos-in-progress";
import store from "../../../common/redux/store";
import { getTags as getNewsletterTags, getNewsletters } from "../../../common/services/firebase/newsletter";
import { getReviewsStructure, getUserReviews } from "../../../common/services/firebase/reviews";
import { getCategories, getTopics, getTags as getVaultTags, getVideos, getVideosInProgress } from "../../../common/services/firebase/vault";

export const loadVault = async () => {
  store.dispatch(setLoaders({ loadingVault: true }));
  const lastVideosUpdate = store.getState().vault.lastVideosUpdate;
  const lastTagsUpdate = store.getState().vault.lastTagsUpdate;
  const lastTopicsUpdate = store.getState().vault.lastTopicsUpdate;
  const lastCategoriesUpdate = store.getState().vault.lastCategoriesUpdate;
  const videos = await getVideos({ lastUpdatedAt: lastVideosUpdate });
  const topics = await getTopics({ lastUpdatedAt: lastTopicsUpdate });
  const vaultTags = await getVaultTags({ lastUpdatedAt: lastTagsUpdate });
  const vaultCategories = await getCategories({ lastUpdatedAt: lastCategoriesUpdate });
  store.dispatch(setVideos(videos));
  store.dispatch(setTopics(topics));
  store.dispatch(setVaultTags(vaultTags || []));
  store.dispatch(setVaultCategories(vaultCategories || []));
  store.dispatch(setLoaders({ loadingVault: false }));
};

export const loadVideoInProgress = async (userId) => {
  store.dispatch(setLoaders({ loadingVideosInProgress: true }));
  const lastVideosInProgressUpdate = store.getState().videosInProgress.lastVideosInProgressUpdate;
  const videoInProgress = await getVideosInProgress({ userId, lastUpdatedAt: lastVideosInProgressUpdate });
  store.dispatch(setVideosInProgress(videoInProgress));
  store.dispatch(setLoaders({ loadingVideosInProgress: false }));
};

export const loadNewsletters = async () => {
  store.dispatch(setLoaders({ loadingNewsletters: true }));
  const lastNewslettersUpdate = store.getState().newsletters.lastNewslettersUpdate;
  const lastTagsUpdate = store.getState().newsletters.lastTagsUpdate;
  const newsletters = await getNewsletters({ lastUpdatedAt: lastNewslettersUpdate });
  const newsletterTags = await getNewsletterTags({ lastUpdatedAt: lastTagsUpdate });
  store.dispatch(setNewslettersTags(newsletterTags));
  store.dispatch(setNewsletters(newsletters));
  store.dispatch(setLoaders({ loadingNewsletters: false }));
};

export const loadReviews = async (userId) => {
  store.dispatch(setLoaders({ loadingReviews: true }));
  const lastReviewsUpdate = store.getState().reviews.lastReviewsUpdate;
  const lastReviewsStructureUpdate = store.getState().reviews.lastReviewsStructureUpdate;
  const reviews = await getUserReviews({ userId, lastUpdatedAt: lastReviewsUpdate });
  const reviewsStructure = await getReviewsStructure({ lastUpdatedAt: lastReviewsStructureUpdate });
  store.dispatch(setReviews(reviews || []));
  store.dispatch(setReviewsStructure(reviewsStructure || []));
  store.dispatch(setLoaders({ loadingReviews: false }));
};