import { collection, deleteField, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, ref as storageRef, uploadBytes } from "firebase/storage";
import { firestore, storage } from '..';
import { removeUndefined } from '../../../utils/object';
import { NEWSLETTER_COLLECTION, NEWSLETTER_TAGS_COLLECTION } from '../constants';

const newsletterCollection = collection(firestore, NEWSLETTER_COLLECTION);
const tagsColletion = collection(firestore, NEWSLETTER_TAGS_COLLECTION);

const getIamgeName = (id, imageName) => {
  if (!id || !imageName) return undefined;
  if (imageName.includes(id)) return imageName;
  const splitName = imageName.split(".");
  const extension = splitName[splitName.length - 1];
  return `${id}.${extension}`;
}

export const getNewsletter = async ({ newsletterId }) => {
  const querySnapshot = await getDoc(doc(firestore, NEWSLETTER_COLLECTION, newsletterId));
  return querySnapshot.data();
};

export const getNewsletters = async ({ lastUpdatedAt, showDeleted = true }) => {
  const deletedStates = [false];
  if (showDeleted) deletedStates.push(true);
  const queryConstraints = [where("_deleted", 'in', deletedStates)];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(newsletterCollection, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getTags = async ({ lastUpdatedAt, showDeleted = true }) => {
  const deletedStates = [false];
  if (showDeleted) deletedStates.push(true);
  const queryConstraints = [where("_deleted", 'in', deletedStates)];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(tagsColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

const saveTags = async (tags) => {
  const dateNow = Date.now();
  const newTags = [];
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];
    if (typeof tag === "object") {
      if (tag?.id?.startsWith('NEW_TAG_')) {
        const docRef = doc(tagsColletion);
        tag.id = docRef.id;
        tag._updatedAt = dateNow;
        tag._createdAt = dateNow;
        tag._deleted = false;
        await setDoc(docRef, tag);
      }
      newTags.push(tag.id);
    } else if (typeof tag === "string") {
      newTags.push(tag);
    }
  }
  return newTags;
};

export const saveNewsletter = async (newsletter) => {
  const dateNow = Date.now();
  let tags = newsletter.tags || [];
  if (tags.length) {
    tags = await saveTags(tags);
  }
  const oldImage = newsletter.image;
  delete newsletter.image;
  let docRef = newsletter.id ? doc(newsletterCollection, newsletter.id) : doc(newsletterCollection);
  const imageName = getIamgeName(docRef.id, oldImage?.name);
  const newNewsletter = {
    ...removeUndefined(newsletter),
    tags,
  }
  delete newNewsletter.oldImage;
  if (imageName) {
    newNewsletter.image = imageName;
  }
  if (oldImage?.name && !oldImage.isEditing) {
    const imageRef = storageRef(storage, `newsletter/${imageName}`);
    await uploadBytes(imageRef, oldImage);
  } else if (!oldImage && newsletter.oldImage) {
    const imageRef = storageRef(storage, `newsletter/${newsletter.oldImage}`);
    newNewsletter.image = deleteField();
    await deleteObject(imageRef);
  }
  if (newNewsletter.id) {
    newNewsletter._updatedAt = dateNow;
    await updateDoc(docRef, newNewsletter);
  } else {
    newNewsletter.id = docRef.id;
    newNewsletter._createdAt = dateNow;
    newNewsletter._updatedAt = dateNow;
    newNewsletter._deleted = false;
    await setDoc(docRef, newNewsletter);
  }
  return newNewsletter;
};