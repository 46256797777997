import React, { useEffect, useRef, useState } from "react";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import { getSchool } from "../../../../common/services/firebase/database";
import { useFilter } from "../../../context/filter";
import { getClassName } from "../../../utils/dark-mode";
import styles from "./styles.module.css";
import SearchIcon from "../../../assets/icons/search.svg";
import SearchIconWhite from "../../../assets/icons/search-white.svg";

const Search = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [filteredSchools, setFilteredSchools] = useState([]);
  const { searchSchools, setSelectedSchool, addToPreviousSchool, isDark } =
    useFilter();
  const ref = useRef(null);
  onClickOutside(ref, () => setFilteredSchools([]));

  useEffect(() => {
    if (value?.length) {
      const timeout = setTimeout(() => {
        if (searchSchools?.length) {
          const newFilteredSchools = searchSchools.filter((s) =>
            s.searchName.includes(value.toLowerCase())
          );
          if (newFilteredSchools.length) {
            setFilteredSchools(newFilteredSchools.map((s) => s.name));
          } else {
            setFilteredSchools([]);
          }
        }
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setFilteredSchools([]);
    }
  }, [value, searchSchools]);

  const onSchoolClick = async (schoolName) => {
    setLoading(true);
    try {
      const school = await getSchool(schoolName);
      if (school) {
        setFilteredSchools([]);
        setSelectedSchool(school);
        addToPreviousSchool(school);
        setValue("");
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const selectedSchool = filteredSchools[0];
      if (selectedSchool) {
        onSchoolClick(selectedSchool);
      }
    }
  };

  return (
    <div ref={ref} className={styles.container}>
      <div ref={ref} className={styles.contentContainer}>
        <input
          className={getClassName(styles, "input", isDark)}
          placeholder="Search"
          type="text"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={handleKeyDown}
        />
        <img
          className={styles.searchIcon}
          src={isDark ? SearchIcon : SearchIconWhite}
          width={17}
          height={17}
          alt="save"
        />
      </div>
      {filteredSchools.length ? (
        <div className={getClassName(styles, "schoolNameContainer", isDark)}>
          <div className={styles.emptyContainer} />
          <div className={styles.scrollableContainer}>
            {filteredSchools.map((schoolName) => (
              <div
                key={`search-bar-${schoolName}`}
                onClick={() => onSchoolClick(schoolName)}
                className={getClassName(styles, "schoolName", isDark)}
              >
                {schoolName}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(Search);
