import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading1 from "../.heading1";
import cn from "classnames";
import ProfileIcon from "../../assets/icons/profile-icon.svg"

const Testimonial2 = ({ name, quote }) => {
  return (
    <div className={styles.container}>
      <div className={styles.nameContainer}>
        <img
          className={styles.profileIcon}
          src={ProfileIcon}
          alt="profile icon"
        />
        <Text className={styles.name}>{name}</Text>
      </div>
      <Text className={styles.contentContainer}>{quote}</Text>
    </div>
  );
};

export default React.memo(Testimonial2);
