import React from "react";
import { useFilter } from '../../../../context/filter';
import { getClassName, getTextClassName } from '../../../../utils/dark-mode';
import styles from './styles.module.css';

const DIVISIONS = [
  { label: 'DI', value: 'DI' },
  { label: 'DII', value: 'DII' },
  { label: 'DIII', value: 'DIII' },
  { label: 'NAIA', value: 'NAIA' },
  { label: 'Juco', value: 'Juco' },
]

const Division = ({ fromSaved }) => {
  const { selectedDivision, setSelectedDivision, savedSelectedDivision, setSavedSelectedDivision, isDark } = useFilter();
  const divisionSelected = fromSaved ? savedSelectedDivision : selectedDivision;
  const onSelectDivision = fromSaved ? setSavedSelectedDivision : setSelectedDivision;

  return (
    <div>
      {DIVISIONS.map(d => {
        const isSelected = divisionSelected === d.value;
        return (
          <div
            key={d.value}
            className={`${getTextClassName(styles.label, isDark)} ${isSelected ? getClassName( styles, 'selectedLabel', isDark) : ''}`}
            onClick={() => onSelectDivision(isSelected && fromSaved ? undefined : d.value)}>
            {d.label}
          </div>
        )
      })}
    </div>
  );
};

export default React.memo(Division);
