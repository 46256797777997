import React from "react";
import { REVIEW_STATUS } from "../../../../../Dashboard/constants";
import Dropdown from "../../../../components/dropdown";
import styles from './styles.module.css';

export const STATUS_OPTIONS = [
  { value: REVIEW_STATUS.SUBMITTED, label: "Submitted" },
  { value: REVIEW_STATUS.IN_REVIEW, label: "In Review" },
  { value: REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL, label: "Waiting for admin approval" },
  { value: REVIEW_STATUS.APPROVED, label: "Approved" },
  { value: REVIEW_STATUS.DONE, label: "Done" },
]

const Filters = ({ filters, onChange }) => {
  return (
    <div className={styles.container}>
      <Dropdown label="Position" options={STATUS_OPTIONS} value={filters?.status} onChange={option => onChange("status", option.value)} onClear={() => onChange("status", undefined)} />
    </div>
  )
};

export default React.memo(Filters);
