import cn from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearRedirectData } from "../../../common/redux/reducers/app-redirect";
import { BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import LogoIconWhite from "../../assets/icons/AA_logo_White.svg";
import ButtonStyles from "../../components/button/styles.module.css";
import Text from "../.text";
import Button from "../button";
import Styles from "./styles.module.css";

const Nav = (showBanner, props) => {
  const history = useHistory();
  const location = useLocation();
  const selectedPage = location.pathname;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [menuOpen, setMenu] = useState(false);

  let sidenav = document.getElementById("sidenav");
  let menuBtn = document.getElementById("menuBtn");
  document.onclick = function (e) {
    try {
      if (!sidenav.contains(e.target) && !menuBtn.contains(e.target)) {
        closeSideNav();
      }
    } catch (error) {}
  };

  function openSideNav() {
    setMenu(true);
    document.getElementById("sidenav").style.width = "240px";
    document.getElementById("actionButtons").style.opacity = 0;
  }

  function closeSideNav() {
    setMenu(false);
    document.getElementById("sidenav").style.width = "0";
    document.getElementById("actionButtons").style.opacity = 1;
  }

  function menuButtonAction() {
    if (menuOpen) {
      closeSideNav();
    } else {
      openSideNav();
    }
  }

  let sidenavHeightClass = Styles.sidenavNormalHeight;

  const onButtonClick = () => {
    dispatch(clearRedirectData());
    let route = BASE_ROUTES.login;
    if (user) route = DASHBOARD_ROUTES.base;
    closeSideNav();
    history.push(route);
  };

  return (
    <div className={Styles.container}>
      {/* SIDENAV: */}
      <div
        id="sidenav"
        className={cn(Styles.sidenavContainer, sidenavHeightClass)}
      >
        <div className={Styles.sidenavContent}>
          <div className={Styles.sidenavPagesContainer}>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.base);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>Home</Text>
            </div>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.database);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>Database</Text>
            </div>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.vault);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>Vault</Text>
            </div>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.review);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>Film Review</Text>
            </div>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.about);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>About</Text>
            </div>
            <div
              onClick={() => {
                history.push(BASE_ROUTES.plans);
                closeSideNav();
              }}
              className={Styles.sidenavPage}
            >
              <Text>Plans</Text>
            </div>
          </div>

          <div className={Styles.sidenavBottomContentContainer}>
            <Button
              onClick={onButtonClick}
              style={{ textAlign: "center" }}
              variant="outline"
            >
              {user ? "Open Dashboard" : "Log In / Sign Up"}
            </Button>
            <Button
              onClick={() => {
                history.push(BASE_ROUTES.plans);
                closeSideNav();
              }}
              style={{ textAlign: "center" }}
            >
              Get Started →
            </Button>
          </div>
        </div>
      </div>

      <div className={Styles.navContainer} {...props}>
        <div className={Styles.smallContentContainer}>
          <div className={Styles.logoContainer}>
            <img
              className={Styles.logo}
              src={LogoIconWhite}
              alt="Athletes After"
              onClick={() => history.push(BASE_ROUTES.base)}
            />
          </div>

          <div className={Styles.hiddenButton}>
            <Button className={ButtonStyles.action}>(Hidden)</Button>
          </div>

          <div className={Styles.shortMenuButtons}>
            <div id="actionButtons" className={Styles.actionButtons}>
              <Button
                className={{
                  [ButtonStyles.actionSelected]:
                    selectedPage === BASE_ROUTES.plans,
                }}
                variant={
                  selectedPage === BASE_ROUTES.plans
                    ? "actionSelected"
                    : "action"
                }
                onClick={() => history.push(BASE_ROUTES.plans)}
              >
                PLANS
              </Button>
              <Button
                className={{
                  [ButtonStyles.actionWhiteSelected]:
                    selectedPage === BASE_ROUTES.login,
                }}
                variant="actionWhite"
                onClick={onButtonClick}
              >
                {user ? "OPEN DASHBOARD" : "LOG IN"}
              </Button>
            </div>

            <div className={Styles.menuButton}>
              <Button
                id="menuBtn"
                onClick={() => menuButtonAction()}
                className={ButtonStyles.menu}
              >
                <div className={Styles.menuButtonContent}>
                  MENU
                  <div
                    id={Styles.icon}
                    className={menuOpen ? [Styles.opened] : [Styles.closed]}
                  >
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>

        <div className={Styles.largeContentContainer}>
          <div className={Styles.logoContainer}>
            <img
              className={Styles.logo}
              src={LogoIconWhite}
              alt="Athletes After"
              onClick={() => history.push(BASE_ROUTES.base)}
            />
          </div>

          <div className={Styles.navButtons}>
            <Button
              className={cn(ButtonStyles.nav, {
                [ButtonStyles.navSelected]: selectedPage === BASE_ROUTES.base,
              })}
              onClick={() => history.push(BASE_ROUTES.base)}
            >
              HOME
            </Button>
            <Button
              className={cn(ButtonStyles.nav, {
                [ButtonStyles.navSelected]:
                  selectedPage === BASE_ROUTES.database,
              })}
              onClick={() => history.push(BASE_ROUTES.database)}
            >
              DATABASE
            </Button>
            <Button
              className={cn(ButtonStyles.nav, {
                [ButtonStyles.navSelected]: selectedPage === BASE_ROUTES.vault,
              })}
              onClick={() => history.push(BASE_ROUTES.vault)}
            >
              VAULT
            </Button>
            <Button
              className={cn(ButtonStyles.nav, {
                [ButtonStyles.navSelected]: selectedPage === BASE_ROUTES.review,
              })}
              onClick={() => history.push(BASE_ROUTES.review)}
            >
              FILM REVIEW
            </Button>
            <Button
              className={cn(ButtonStyles.nav, {
                [ButtonStyles.navSelected]: selectedPage === BASE_ROUTES.about,
              })}
              onClick={() => history.push(BASE_ROUTES.about)}
            >
              ABOUT
            </Button>
          </div>

          <div className={Styles.actionButtons}>
            <Button
              className={{
                [ButtonStyles.actionSelected]:
                  selectedPage === BASE_ROUTES.plans,
              }}
              variant={
                selectedPage === BASE_ROUTES.plans ? "actionSelected" : "action"
              }
              onClick={() => history.push(BASE_ROUTES.plans)}
            >
              PLANS
            </Button>
            <Button
              className={{
                [ButtonStyles.actionWhiteSelected]:
                  selectedPage === BASE_ROUTES.login,
              }}
              variant="actionWhite"
              onClick={onButtonClick}
            >
              {user ? "OPEN DASHBOARD" : "LOG IN"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Nav);
