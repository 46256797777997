import { arrayRemove, collection, deleteField, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { firestore } from '..';
import { removeUndefined } from '../../../utils/object';
import { CATEGORIES_COLLECTION, TAGS_COLLECTION, TOPICS_COLLECTION, USER_VAULT_COLLECTION, VAULT_COLLECTION } from '../constants';

const vaultColletion = collection(firestore, VAULT_COLLECTION);
const userVaultColletion = collection(firestore, USER_VAULT_COLLECTION);
const tagsColletion = collection(firestore, TAGS_COLLECTION);
const categoriesColletion = collection(firestore, CATEGORIES_COLLECTION);
const topicsColletion = collection(firestore, TOPICS_COLLECTION);

export const REMOVE_CURRENT_TIME = "REMOVE_CURRENT_TIME";

export const getVideo = async ({ videoId }) => {
  const querySnapshot = await getDoc(doc(vaultColletion, videoId));
  return querySnapshot.data();
};

export const getVideos = async ({ lastUpdatedAt, showDeleted = true }) => {
  const deletedStates = [false];
  if (showDeleted) deletedStates.push(true);
  const queryConstraints = [where("_deleted", 'in', deletedStates)];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(vaultColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getTopics = async ({ lastUpdatedAt, showDeleted = true }) => {
  const deletedStates = [false];
  if (showDeleted) deletedStates.push(true);
  const queryConstraints = [where("_deleted", 'in', deletedStates)];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(topicsColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getTopic = async (topicId) => {
  const querySnapshot = await getDoc(doc(topicsColletion, topicId));
  return querySnapshot.data();
};

export const getTags = async ({ lastUpdatedAt, showDeleted = true }) => {
  const deletedStates = [false];
  if (showDeleted) deletedStates.push(true);
  const queryConstraints = [where("_deleted", 'in', deletedStates)];
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(tagsColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getCategories = async ({ lastUpdatedAt, showDeleted = true }) => {
  const queryConstraints = [where("_deleted", '==', false)];
  if (showDeleted) {
    queryConstraints.push(where("_deleted", '==', true))
  }
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(categoriesColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const getCategory = async (categoryId) => {
  const querySnapshot = await getDoc(doc(categoriesColletion, categoryId));
  return querySnapshot.data();
};

export const getVideosInProgress = async ({ userId, lastUpdatedAt }) => {
  if (!userId) return;
  const queryConstraints = [];
  queryConstraints.push(where("userId", '==', userId));
  if (lastUpdatedAt) {
    queryConstraints.push(where("_updatedAt", '>', lastUpdatedAt));
  }
  const q = query(userVaultColletion, ...queryConstraints, orderBy("_updatedAt", "desc"));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return undefined;
  return querySnapshot.docs[0].data();
};

export const saveVideoProgress = async (userId, videoId, currentTime) => {
  const dateNow = Date.now();
  const docRef = doc(userVaultColletion, userId);
  const videoData = {
    userId,
    _updatedAt: dateNow,
    [videoId]: currentTime === REMOVE_CURRENT_TIME ? deleteField() : {
      id: videoId,
      currentTime: currentTime || 0,
      _updatedAt: dateNow,
    }
  };
  await setDoc(docRef, videoData, { merge: true });
}

export const saveTopic = async (topic) => {
  const dateNow = Date.now();
  const newTopic = removeUndefined(topic);
  if (newTopic.id) {
    const docRef = doc(topicsColletion, newTopic.id);
    newTopic._updatedAt = dateNow;
    await updateDoc(docRef, newTopic);
    return newTopic;
  } else {
    const docRef = doc(topicsColletion);
    newTopic.id = docRef.id;
    newTopic._updatedAt = dateNow;
    newTopic._createdAt = dateNow;
    newTopic._deleted = false;
    await setDoc(docRef, newTopic);
    return newTopic;
  }
}

export const saveCategory = async (categoryId, categoryName) => {
  const dateNow = Date.now();
  const newCategory = {
    id: categoryId,
    name: categoryName,
  }
  if (categoryId) {
    const docRef = doc(categoriesColletion, categoryId);
    newCategory._updatedAt = dateNow;
    await updateDoc(docRef, newCategory);
    return newCategory;
  } else {
    const docRef = doc(categoriesColletion);
    newCategory.id = docRef.id;
    newCategory._updatedAt = dateNow;
    newCategory._createdAt = dateNow;
    newCategory._deleted = false;
    await setDoc(docRef, newCategory);
    return newCategory;
  }
}

export const deleteTopic = async (topicId) => {
  await updateDoc(doc(topicsColletion, topicId), { _deleted: true, _updatedAt: Date.now() });
}

export const deleteCategory = async (categoryId) => {
  const dateNow = Date.now();
  const batch = writeBatch(firestore);
  const q = query(vaultColletion, where("category", '==', categoryId));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const docQuery = querySnapshot.docs[i];
    batch.update(docQuery.ref, { category: deleteField(), _updatedAt: dateNow });
  }
  await batch.commit();
  await updateDoc(doc(categoriesColletion, categoryId), { _deleted: true, _updatedAt: dateNow });
}

export const deleteTag = async (tagId) => {
  const dateNow = Date.now();
  const batch = writeBatch(firestore);
  const q = query(vaultColletion, where("tags", 'array-contains', tagId));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const docQuery = querySnapshot.docs[i];
    batch.update(docQuery.ref, { tags: arrayRemove(tagId), _updatedAt: dateNow });
  }
  await batch.commit();
  await updateDoc(doc(tagsColletion, tagId), { _deleted: true, _updatedAt: dateNow });
}

export const saveTag = async (tag) => {
  const dateNow = Date.now();
  const newTag = removeUndefined(tag);
  if (newTag.id) {
    const docRef = doc(tagsColletion, newTag.id);
    newTag._updatedAt = dateNow;
    await updateDoc(docRef, newTag);
    return newTag;
  } else {
    const docRef = doc(tagsColletion);
    newTag.id = docRef.id;
    newTag._updatedAt = dateNow;
    newTag._createdAt = dateNow;
    newTag._deleted = false;
    await setDoc(docRef, newTag);
    return newTag;
  }
};

export const saveVideo = async (video) => {
  const dateNow = Date.now();
  const newVideo = removeUndefined(video);
  if (newVideo.id) {
    const docRef = doc(vaultColletion, newVideo.id);
    newVideo._updatedAt = dateNow;
    await updateDoc(docRef, newVideo);
    return newVideo;
  } else {
    const docRef = doc(vaultColletion);
    newVideo.id = docRef.id;
    newVideo._updatedAt = dateNow;
    newVideo._createdAt = dateNow;
    newVideo._deleted = false;
    await setDoc(docRef, newVideo);
    return newVideo;
  }
};