import React, { useMemo } from "react";
import DatabaseIcon from "../../assets/icons/databaseIcon.svg";
import VaultIcon from "../../assets/icons/vaultIcon.svg";
import AuditIcon from "../../assets/icons/reviewIcon.svg";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading2 from "../.heading2";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";

const ExpandingPanel = ({ title }) => {
  const history = useHistory();
  const Icon = useMemo(() => {
    if (title === "Database") return DatabaseIcon;
    if (title === "The Vault") return VaultIcon;
    if (title === "Film Review") return AuditIcon;
    return null;
  }, [title]);
  const Link = useMemo(() => {
    if (title === "Database") return BASE_ROUTES.database;
    if (title === "The Vault") return BASE_ROUTES.vault;
    if (title === "Film Review") return BASE_ROUTES.review;
    return null;
  }, [title]);

  return (
    <div onClick={() => history.push(Link)} className={styles.container}>
      <div className={styles.stationaryContainer}>
        <div className={styles.textContainer}>
          <Heading2>{title}</Heading2>
        </div>

        <img src={Icon} alt="icon" className={styles.hiddenIcon} />
        <img src={Icon} alt="icon" className={styles.icon} />


      </div>
    </div>
  );
};

export default React.memo(ExpandingPanel);
