import React, { useRef, useState } from "react";
import onClickOutside from "../../../../common/hooks/onClickOutside";
import PreviousWhiteIcon from "../../../assets/icons/previous-white.svg";
import PreviousIcon from "../../../assets/icons/previous.svg";
import { useFilter } from "../../../context/filter";
import {
  getClassName,
  getContainerClassName,
  getTextClassName,
} from "../../../utils/dark-mode";
import Popup from "../../popup";
import styles from "./styles.module.css";

const Previous = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { goToPreviousSchool, isDark } = useFilter();
  const ref = useRef(null);
  onClickOutside(ref, () => setIsOpen(false));

  const toggleOpen = () => setIsOpen((prev) => !prev);

  return (
    <div
      ref={ref}
      className={getContainerClassName(styles.innerContainer, isDark)}
      onClick={goToPreviousSchool}
    >
      <div className={styles.contentContainer}>
        <img
          src={isDark ? PreviousIcon : PreviousWhiteIcon}
          width={20}
          height={20}
          alt="Previous"
        />
        <div className={getTextClassName("", isDark)}>Back</div>
      </div>
    </div>
  );
};

export default React.memo(Previous);
