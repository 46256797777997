import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../common/services/firebase/users";

export const FilterContext = createContext({});

export const FilterProvider = ({ children }) => {
  const user = useSelector(state => state.user);
  const [myLocation, setMyLocation] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedDivision, setSelectedDivision] = useState("DI");
  const [selectedDistance, setSelectedDistance] = useState();
  const [selectedTemperature, setSelectedTemperature] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [savedSchools, setSavedSchools] = useState(user?.savedSchools || []);
  const [savedSelectedDivision, setSavedSelectedDivision] = useState();
  const [savedSelectedDistance, setSavedSelectedDistance] = useState();
  const [recentSchoolsIndex, setRecentSchoolsIndex] = useState(0);
  const [recentSchools, setRecentSchools] = useState([]);
  const [schoolDirection, setSchoolDirection] = useState();
  const [schoolRoute, setSchoolRoute] = useState();
  const [searchSchools, setSearchSchools] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [customIsSelected, setCustomIsSelected] = useState();
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    if (user?.id) {
      updateUser({ id: user.id, savedSchools });
    }
  }, [user?.id, savedSchools]);

  const saveSchool = (school) => {
    setSavedSchools((prev) => {
      const index = prev.findIndex((s) => s.name === school.name);
      if (index >= 0) return prev;
      prev.push(school);
      return [...prev];
    });
    toast.success("School Saved");
  };

  const removeSavedSchool = (schoolName) => {
    setSavedSchools((prev) => {
      const nextState = [...prev];
      const index = nextState.findIndex((s) => s.name === schoolName);
      if (index >= 0) nextState.splice(index, 1);
      return nextState;
    });
  };

  const addToPreviousSchool = (school) => {
    setRecentSchools((prev) => {
      const nextState = [...prev];
      const index = nextState.findIndex((s) => s.name === school.name);
      if (index >= 0) nextState.splice(index, 1);
      return [school, ...nextState];
    });
  };

  const goToPreviousSchool = () => {
    let index = recentSchoolsIndex;
    if (index >= recentSchools.length) {
      index = 0;
    }
    const school = recentSchools[index];
    if (school) {
      setSelectedSchool(school);
      setRecentSchoolsIndex(index + 1);
    }
  };

  const goToSelectedSchool = (school) => {
    setRecentSchools((prev) => {
      let nextState = [...prev];
      const index = nextState.findIndex((s) => s.name === school.name);
      if (index >= 0) nextState.splice(index, 1);
      setSelectedSchool(school);
      nextState = [school, ...nextState];
      return nextState;
    });
    setRecentSchoolsIndex(1);
  };

  const saveSchoolNotes = (school) => {
    setSavedSchools((prev) => {
      const index = prev.findIndex((s) => s.name === school.name);
      if (index >= 0) {
        prev[index] = school;
        return [...prev];
      }
      return prev;
    });
  };

  return (
    <FilterContext.Provider
      value={{
        myLocation,
        selectedSchool,
        selectedDivision,
        selectedDistance,
        selectedTemperature,
        selectedStatus,
        savedSchools,
        savedSelectedDivision,
        savedSelectedDistance,
        recentSchools,
        schoolDirection,
        showFilters,
        schoolRoute,
        searchSchools,
        isDark,
        customIsSelected,
        selectedState,
        setMyLocation,
        setSelectedSchool,
        setSelectedDivision,
        setSelectedDistance,
        setSelectedTemperature,
        setSelectedStatus,
        saveSchool,
        saveSchoolNotes,
        removeSavedSchool,
        setSavedSelectedDivision,
        setSavedSelectedDistance,
        addToPreviousSchool,
        goToPreviousSchool,
        goToSelectedSchool,
        setSchoolDirection,
        setShowFilters,
        setSchoolRoute,
        setSearchSchools,
        setIsDark,
        setCustomIsSelected,
        setSelectedState,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error("useFilter must be used within an FilterProvider");
  }

  return context;
};
