import React from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { ICONS_NAME } from "../../../components/icon";
import Text from "../../../components/text";
import { REVIEW_LABEL } from "../../../constants";
import WidgetBaseCard from "./base-card";
import styles from "./styles.module.css";

const FilmReviewsWidget = ({ reviewsStatus = {} }) => {
  const history = useHistory();
  const statusKeys = Object.keys(reviewsStatus);
  return (
    <WidgetBaseCard
      title="Film Reviews"
      content={!statusKeys.length ? (
        <div>
          <Text variant="description">0 Film Reviews Complete</Text>
          <div>
            <Text variant="description">0 Film Reviews Pending</Text>
          </div>
        </div>
      ) : (
        <div>
          {statusKeys.map(status => {
            const reviews = reviewsStatus[status];
            return (
              <div className={styles.filmReviewItemContainer} key={`status-${status}`}>
                <Text variant="description">{`${reviews.length} Film Review ${REVIEW_LABEL[status]}`}</Text>
              </div>
            )
          })}
        </div>
      )}
      buttonText="Start New Review"
      icon={ICONS_NAME.googleDocs}
      onClick={() => history.push({ pathname: DASHBOARD_ROUTES.review, search: "?new-review=true" })}
    />
  );
};

export default React.memo(FilmReviewsWidget);
