import cn from "classnames";
import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Button from "../button";
import Title from "../.title";

const Header = ({ id, link, button }) => {
  const Bg = useMemo(() => {
    if (id === "database") return styles.database;
    if (id === "review") return styles.review;
    if (id === "vault") return styles.vault;
  }, [id]);
  return (
    <div className={cn(styles.container, Bg)}>
      {button ? (
        <a href={link} target="_blank">
          <Button className={styles.buttonContainer}>{button}</Button>
        </a>
      ) : null}
    </div>
  );
};

export default React.memo(Header);
