import React from "react";
import { useFilter } from "../../../context/filter";
import {
  getSecondaryContainerClassName,
  getClassName,
  getContainerClassName,
  getTextClassName,
} from "../../../utils/dark-mode";
import styles from "./styles.module.css";
import classNames from "classnames";
import Copy from "../../../assets/icons/copy.svg";
import CopyWhite from "../../../assets/icons/copy-white.svg";
import { toast } from "react-toastify";

const ClearDirections = ({ isDemo }) => {
  const { schoolRoute, setSchoolRoute, setSchoolDirection, isDark } =
    useFilter();
  if (!schoolRoute) return null;
  const { address, duration, distance } = schoolRoute;

  const clearDirections = () => {
    setSchoolDirection(undefined);
    setSchoolRoute(undefined);
  };

  const copyAddress = () => {
    try {
      navigator.clipboard.writeText(address);
      toast.success("Address copied to clipboard");
    } catch (error) {
      toast.success("Error copying address");
      console.log("error copying address");
    }
  };

  return (
    <div
      // className={classNames(
      //   styles.container,
      //   isDark ? styles.containerDark : styles.containerLight
      // )}
      className={classNames(
        getContainerClassName(styles.container, isDark, "no-hover"),
        isDemo && styles.isDemo
      )}
    >
      <div className={styles.headerContainer} onClick={clearDirections}>
        <div className={getTextClassName(styles.title, isDark)}>Directions</div>
        <div className={getTextClassName(styles.close, isDark)}>X</div>
      </div>

      <div
        className={getSecondaryContainerClassName(styles.addressLine, isDark)}
      >
        {address ? (
          <div className={getTextClassName(styles.info, isDark)}>
            <div>{address}</div>
          </div>
        ) : null}
        <div
          className={getClassName(styles, "copyContainer", isDark)}
          onClick={copyAddress}
        >
          <img
            src={isDark ? Copy : CopyWhite}
            width={20}
            height={20}
            alt="Copy address"
          />
        </div>
      </div>

      <div className={styles.distanceDurationContainer}>
        {duration ? (
          <div className={getTextClassName(styles.infoLarge, isDark)}>
            <div>{duration.text}</div>
          </div>
        ) : null}
        {distance ? (
          <div className={getTextClassName(styles.infoLarge, isDark)}>
            <div>{distance.text}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ClearDirections);
