import React, { useMemo } from "react";
import CoachIcon from "../../assets/icons/coach.svg";
import MapIcon from "../../assets/icons/map.svg";
import CampIcon from "../../assets/icons/camp.svg";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading3 from "../.heading3"
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";

const IconCard = ({ title, iconName }) => {
  const history = useHistory();
  const Icon = useMemo(() => {
    if (iconName === "database") return MapIcon;
    if (iconName === "vault") return CampIcon;
    if (iconName === "review") return CoachIcon;
    return null;
  }, [iconName]);
  const Link = useMemo(() => {
    if (iconName === "database") return "database";
    if (iconName === "vault") return "vault";
    if (iconName === "review") return "review";
    return null;
  }, [iconName]);

  return (
    <a className={styles.container} onClick={() => history.push(Link)} target="_blank">
      <img src={Icon} alt="icon" className={styles.icon} />
      <div className={styles.content}>
        <Heading3>{title}</Heading3>
        <Text style={{ color: "var(--darkText)", fontSize: "15px", textDecoration: "underline" }}>Explore</Text>
      </div>
    </a>
  );
};

export default React.memo(IconCard);
