import React from "react";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";
import Text from "../.text";
import Button from "../button";
import styles from "./styles.module.css";

const Description = ({ id, txt, btn }) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <Text className={styles.textContainer}>{txt}</Text>
      {id === "database" ? (
        <Button onClick={() => history.push(BASE_ROUTES.plans)} className={styles.buttonContainer} variant={"outline"}>
          {btn}
        </Button>
      ) : null}
      {id === "vault" ? (
        <Button onClick={() => history.push(BASE_ROUTES.plans)} className={styles.buttonContainer} variant={"outline"}>
          {btn}
        </Button>
      ) : null}
      {id === "review" ? (
        <Button onClick={() => history.push(BASE_ROUTES.plans)} className={styles.buttonContainer} variant={"outline"}>
          {btn}
        </Button>
      ) : null}
    </div>
  );
};

export default React.memo(Description);
