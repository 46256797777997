import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FILM_REVIEW_PRODUCT,
  UNLIMITED_REVIEWS,
} from "../../../common/constants";
import { setRedirectData } from "../../../common/redux/reducers/app-redirect";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { useQuery } from "../../../common/utils/routes";
import Button from "../../components/button";
import { ICONS_NAME } from "../../components/icon";
import { loadReviews } from "../../components/loader/utils";
import ReviewCard from "../../components/review-card";
import ReviewsLeft from "../../components/reviews-left";
import Screen from "../../components/screen";
import Text from "../../components/text";
import NewReviewModal from "./new-review-modal";
import styles from "./styles.module.css";

const Review = () => {
  const query = useQuery();
  const openNewReviewModal = query.get("new-review") === "true";
  const [showNewReviewModal, setShowNewReviewModal] = useState(false);
  const user = useSelector((state) => state.user);
  const { reviews, totalReviewsLeft } = useSelector((state) => state.reviews);
  const loadingReviews = useSelector((state) => state.loaders.loadingReviews);
  const history = useHistory();
  const dispatch = useDispatch();
  const canAddNewReview =
    totalReviewsLeft === UNLIMITED_REVIEWS || totalReviewsLeft > 0;

  useEffect(() => {
    if (user?.id) {
      loadReviews(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (openNewReviewModal && canAddNewReview) {
      setShowNewReviewModal(true);
    }
  }, [openNewReviewModal, canAddNewReview]);

  const getMoreClick = () => {
    dispatch(
      setRedirectData({
        data: FILM_REVIEW_PRODUCT.key,
      })
    );
    history.push(DASHBOARD_ROUTES.plans);
  };

  return (
    <Screen loading={loadingReviews}>
      <Text variant="title">Film Reviews</Text>
      <div className={styles.buttonsContent}>
        {canAddNewReview ? (
          <Button
            variant="outlinePrimary"
            onClick={() => setShowNewReviewModal(true)}
            iconName={ICONS_NAME.plus}
            iconSize={20}
            className={styles.newAuditButton}
          >
            New Audit
          </Button>
        ) : null}
        <ReviewsLeft outline getMoreClick={getMoreClick} />
      </div>
      <div className={styles.reviewsContainer}>
        {reviews.map((r, index) => {
          return (
            <ReviewCard
              id={r.id}
              title={r.title}
              status={r.status}
              key={r.id}
            />
          );
        })}
      </div>

      <div className={styles.infoContainer}>
        <div>
          <Text variant="h2">How does this work?</Text>
        </div>
        <div className={styles.infoContentContainer}>
          <Text>
            To submit your highlight or technical film, you will copy the public
            link to your film from the site that you uploaded it to, and paste
            it into our form.{" "}
          </Text>
        </div>
        <div className={styles.infoContentContainer}>
          <Text>
            You’ll also submit your name, position, and some other info.
          </Text>
        </div>
        <div className={styles.infoContentContainer}>
          <Text>
            From there, a real person reviews your film with our
            coach-and-athlete-built Equation and send you a report within 1
            week.{" "}
          </Text>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div>
          <Text variant="h2">The Equation</Text>
        </div>
        <div className={styles.infoContentContainer}>
          <Text>
            The Equation is a set of benchmarks based on countless interviews
            with coaches and athletes that our reviewers use to grade your film.
            It is specific to each position, ensuring you get feedback that
            matters.
          </Text>
        </div>
      </div>
      <NewReviewModal
        visible={showNewReviewModal}
        onClose={() => setShowNewReviewModal(false)}
      />
    </Screen>
  );
};

export default React.memo(Review);
