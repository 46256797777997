import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Loading from '../../../Dashboard/components/loading';
import { startInitialLoading } from "../../../common/redux/reducers/loaders";
import { setUser } from "../../../common/redux/reducers/user";
import { ADMIN_ROUTES, BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import { login } from '../../../common/services/firebase/auth';
import { userHasAdminPanelPermission } from '../../../common/utils/permission';
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from "../../components/button";
import Input from "../../components/input";
import pageStyles from "../styles.module.css";

const Login = () => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const redirectRoute = useSelector(state => state.appRedirect.route);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getRouteToNavigate = (loggedUser) => {
    let route = redirectRoute;
    if (!route) {
      const isAdmin = userHasAdminPanelPermission(loggedUser);
      route = isAdmin ? ADMIN_ROUTES.base : DASHBOARD_ROUTES.base;
    }
    return route;
  };

  const onLogin = useCallback(async () => {
    if (!email || !password) return toast.error("Email and password required");
    try {
      setLoading(true);
      const loggedUser = await login(email, password);
      dispatch(setUser(loggedUser));
      dispatch(startInitialLoading());
      const route = getRouteToNavigate(loggedUser);
      history.push(route, { state: true });
    } catch (error) {
      console.log('error', error);
      toast.error('Incorrect email or password');
    }
    setLoading(false);
  }, [email, history, password]);

  if (user) {
    return <Redirect to={getRouteToNavigate(user)} />
  }

  return (
    <div className={pageStyles.formPage}>
      <div className={pageStyles.formPageContentContainer}>
        <div className={pageStyles.formPageTitle}>
          <Title>Log In</Title>
          <div className={pageStyles.flex10}>
            <Text>Don't have an account?</Text>
            <a onClick={() => history.push(BASE_ROUTES.signup)}>Sign Up</a>
          </div>
        </div>
        <div className={pageStyles.form}>
          <Input placeholder="Email" value={email} onChange={evt => setEmail(evt.target.value)} />
          <Input placeholder="Password" value={password} onChange={evt => setPassword(evt.target.value)} type="password" />
          <Button variant="form" onClick={onLogin}>
            Login
          </Button>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Login);
